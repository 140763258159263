import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { VividhVibhagPageAddComponent } from './vividh-vibhag-page-add/vividh-vibhag-page-add.component';
import { VividhVibhagPageListComponent } from './vividh-vibhag-page-list/vividh-vibhag-page-list.component';
import { VividhVibhagTitleAddComponent } from './vividh-vibhag-title-add/vividh-vibhag-title-add.component';
import { VividhVibhagTitleListComponent } from './vividh-vibhag-title-list/vividh-vibhag-title-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'vividh-vibhag-page-add', component: VividhVibhagPageAddComponent },
  { path: 'vividh-vibhag-page-edit/:news_id', component: VividhVibhagPageAddComponent },
  { path: 'vividh-vibhag-page-list', component: VividhVibhagPageListComponent },
  { path: 'vividh-vibhag-title-add', component: VividhVibhagTitleAddComponent },
  { path: 'vividh-vibhag-title-edit/:news_id', component: VividhVibhagTitleAddComponent },
  { path: 'vividh-vibhag-title-list', component: VividhVibhagTitleListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VividhVibhagRoutingModule { }
