import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { NoticeAddComponent } from './notice-add/notice-add.component';
import { NoticeListComponent } from './notice-list/notice-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'notice-add', component: NoticeAddComponent },
  { path: 'notice-edit/:news_id', component: NoticeAddComponent },
  { path: 'notice-list', component: NoticeListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NoticeRoutingModule { }
