import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { FatafatNewsAddComponent } from './fatafat-news-add/fatafat-news-add.component';
import { FatafatNewsListComponent } from './fatafat-news-list/fatafat-news-list.component';
import { ShortNewsListComponent } from './short-news-list/short-news-list.component';
import { ShortNewsAddComponent } from './short-news-add/short-news-add.component';
import { NriNewsAddComponent } from './nri-news-add/nri-news-add.component';
import { NriNewsListComponent } from './nri-news-list/nri-news-list.component';
import { MainNewsAddComponent } from './main-news-add/main-news-add.component';
import { MainNewsListComponent } from './main-news-list/main-news-list.component';
import { SportsNewsAddComponent } from './sports-news-add/sports-news-add.component';
import { SportsNewsListComponent } from './sports-news-list/sports-news-list.component';
import { BollywoodNewsAddComponent } from './bollywood-news-add/bollywood-news-add.component';
import { BollywoodNewsListComponent } from './bollywood-news-list/bollywood-news-list.component';
import { InternationalNewsAddComponent } from './international-news-add/international-news-add.component';
import { InternationalNewsListComponent } from './international-news-list/international-news-list.component';
import { GujaratNewsListComponent } from './gujarat-news-list/gujarat-news-list.component';
import { GujaratNewsAddComponent } from './gujarat-news-add/gujarat-news-add.component';
import { SaurashtraNewsAddComponent } from './saurashtra-news-add/saurashtra-news-add.component';
import { SaurashtraNewsListComponent } from './saurashtra-news-list/saurashtra-news-list.component';
import { RajkotNewsListComponent } from './rajkot-news-list/rajkot-news-list.component';
import { RajkotNewsAddComponent } from './rajkot-news-add/rajkot-news-add.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'fatafat-news-add', component: FatafatNewsAddComponent },
  { path: 'fatafat-news-list', component: FatafatNewsListComponent },
  { path: 'fatafat-news-edit/:news_id', component: FatafatNewsAddComponent },
  { path: 'nri-news-add', component: NriNewsAddComponent },
  { path: 'nri-news-list', component: NriNewsListComponent },
  { path: 'nri-news-edit/:news_id', component: NriNewsAddComponent },
  { path: 'short-news-list', component: ShortNewsListComponent },
  { path: 'short-news-add', component: ShortNewsAddComponent },
  { path: 'short-news-edit/:news_id', component: ShortNewsAddComponent },
  { path: 'main-news-add', component: MainNewsAddComponent },
  { path: 'main-news-list', component: MainNewsListComponent },
  { path: 'main-news-edit/:news_id', component: MainNewsAddComponent },
  { path: 'sports-news-add', component: SportsNewsAddComponent },
  { path: 'sports-news-list', component: SportsNewsListComponent },
  { path: 'sports-news-edit/:news_id', component: SportsNewsAddComponent },
  { path: 'bollywood-news-add', component: BollywoodNewsAddComponent },
  { path: 'bollywood-news-list', component: BollywoodNewsListComponent },
  { path: 'bollywood-news-edit/:news_id', component: BollywoodNewsAddComponent },
  { path: 'international-news-add', component: InternationalNewsAddComponent },
  { path: 'international-news-list', component: InternationalNewsListComponent },
  { path: 'international-news-edit/:news_id', component: InternationalNewsAddComponent },
  { path: 'gujarat-news-add', component: GujaratNewsAddComponent },
  { path: 'gujarat-news-list', component: GujaratNewsListComponent },
  { path: 'gujarat-news-edit/:news_id', component: GujaratNewsAddComponent },
  { path: 'saurashtra-news-add', component: SaurashtraNewsAddComponent },
  { path: 'saurashtra-news-list', component: SaurashtraNewsListComponent },
  { path: 'saurashtra-news-edit/:news_id', component: SaurashtraNewsAddComponent },
  { path: 'rajkot-news-add', component: RajkotNewsAddComponent },
  { path: 'rajkot-news-list', component: RajkotNewsListComponent },
  { path: 'rajkot-news-edit/:news_id', component: RajkotNewsAddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewsRoutingModule { }
