import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-opinion-poll-add',
  templateUrl: './opinion-poll-add.component.html',
  styleUrls: ['./opinion-poll-add.component.css']
})
export class OpinionPollAddComponent implements OnInit {
  news_date = localStorage.getItem('EntryDate');
  poll_number: any = '';
  NewsData: any = [];
  news_id: any = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.route.params.subscribe(params => this.news_id = params.news_id);
    var param = { news_id: this.news_id };
    if (this.news_id) {
      this.CommonService.getOpinionDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['update_data']
          this.poll_number = this.NewsData.poll_number;
          this.news_date = this.NewsData.date;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    } else {
      var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
      this.CommonService.GetPollLastDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          this.poll_number = data.poll_number;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  ngOnInit(): void {
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        //$('.btn-success').attr('disabled', true);
        var form = $("#opinion_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.OpinionBaseUrl + 'add_opinion',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/opinion-poll-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
