import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-paper-edit',
  templateUrl: './paper-edit.component.html',
  styleUrls: ['./paper-edit.component.css']
})
export class PaperEditComponent implements OnInit {
  EpaperCategory = [];
  EpaperPage_1 = [];
  EpaperPage_2 = [];
  EpaperPage_3 = [];
  EpaperPage_4 = [];
  EpaperPage_5 = [];
  PaperData: any = [];
  news_id: any = 0;
  pdfURL: string;
  NewsImageURL = environment.baseUrl;
  imageURL: string;
  news_date = localStorage.getItem('EntryDate');
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.route.params.subscribe(params => this.news_id = params.news_id);
    var param = { news_id: this.news_id };
    if (this.news_id) {
      this.DefaultComponent.loaderShow();
      this.CommonService.getPaperDetail(param).subscribe((data) => {
        this.DefaultComponent.loaderHide();
        if (data['status']) {
          this.PaperData = data['epaper_data']
          this.news_date = this.PaperData.epaper_date;
          if (this.PaperData.epaper_file != '') {
            this.pdfURL = this.NewsImageURL + '/epaper/pdf/' + this.PaperData.epaper_file;
          }
          if (this.PaperData.epaper_image != '') {
            this.imageURL = this.NewsImageURL + '/epaper/' + this.PaperData.epaper_image;
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }
  }
  ngOnInit(): void {
  }
  imageValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image_" + id).val('');
      this.toastr.error("Only image are supported.");
      return;
    }
  }
  pdfValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/pdf\/*/) == null) {
      $("#pdf_" + id).val('');
      this.toastr.error("Only pdf are supported.");
      return;
    }
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        //$('.btn-success').attr('disabled', true);
        var form = $("#epaper_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.EpaperBaseUrl + 'edit_epaper',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            console.log(res);
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/epaper-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
