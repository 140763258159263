import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ckeditor4-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VividhVibhagRoutingModule } from './vividh-vibhag-routing.module';
import { VividhVibhagTitleAddComponent } from './vividh-vibhag-title-add/vividh-vibhag-title-add.component';
import { VividhVibhagTitleListComponent } from './vividh-vibhag-title-list/vividh-vibhag-title-list.component';
import { VividhVibhagPageAddComponent } from './vividh-vibhag-page-add/vividh-vibhag-page-add.component';
import { VividhVibhagPageListComponent } from './vividh-vibhag-page-list/vividh-vibhag-page-list.component';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@NgModule({
  declarations: [VividhVibhagTitleAddComponent, VividhVibhagTitleListComponent, VividhVibhagPageAddComponent, VividhVibhagPageListComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    VividhVibhagRoutingModule
  ]
})
export class VividhVibhagModule { }
