<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/advertiser-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Advertiser</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<select class="form-control" id="search_type">
										<option value="firstname">First Name</option>
										<option value="lastname">Last Name</option>
										<option value="email">Email</option>
									</select>
								</div>
								<div class="col-md-3">
									<input type="text" id="search_title" placeholder="Enter Search Text"
										class="form-control">
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" (click)="get_filtered_news()"
										title="Update news entry date">Search</button>
								</div>
							</div>
							<table class="table">
								<thead>
									<tr>
										<th><button class="btn btn-outline-danger waves-effect waves-light"
												*ngIf="show_multiple_delete_btn" (click)="delete_multiple_news()"><i
													class="fa fa-trash"></i></button></th>
										<th>Name</th>
										<th>Email</th>
										<th>Active ?</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of AdvertiserList">
										<td><input type="checkbox" value={{mn._id}}
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td><a routerLink="/advertiser-edit/{{mn._id}}">{{mn.first_name}}
												{{mn.last_name}}</a></td>
										<td>{{mn.email}}</td>
										<td *ngIf="mn.status=='yes'"><input type="checkbox" checked name="adv_id"
												value={{mn._id}} (change)="toggleVisibility($event)"></td>
										<td *ngIf="mn.status=='no'"><input type="checkbox" name="adv_id"
												value={{mn._id}} (change)="toggleVisibility($event)"></td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>