import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewsPreviewComponent } from './news-preview/news-preview.component';
const routes: Routes = [{
  path: 'inner-news-preview/:section',
  component: NewsPreviewComponent,
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InnerNewsPreviewRoutingModule { }
