import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { TantriAddComponent } from './tantri-add/tantri-add.component';
import { TantriListComponent } from './tantri-list/tantri-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'tantri-add', component: TantriAddComponent },
  { path: 'tantri-list', component: TantriListComponent },
  { path: 'tantri-edit/:news_id', component: TantriAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TantriRoutingModule { }
