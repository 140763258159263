<section class="main-news-page" *ngIf="section=='bollywood'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/moive.png" />ફિલ્મ જગત</div>
                    </a>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div *ngFor="let fl of bollywood_new_list_left"
                            [ngClass]="{'news_right_box': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_box':fl.bollywood_image_position_home=='left','news_center_box':fl.bollywood_image_position_home=='center','news-center':fl.bollywood_use_br=='yes'}">
                            <div *ngIf="fl.bollywood_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.bollywood_image_position_home=='right','news_left_thumb':(fl.bollywood_image_position_home=='left' || fl.bollywood_image_position_home=='left_right'),'news_center_thumb':fl.bollywood_image_position_home=='center'}">
                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.bollywood_heading_image_2" class="news_right_thumb">
                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_content':fl.bollywood_image_position_home=='left','news_center_content':fl.bollywood_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.bollywood_gred_above">{{fl.bollywood_gred_above}}<br /></a>
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.bollywood_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.bollywood_gred_below"><strong> :
                                        </strong>{{fl.bollywood_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.bollywood_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.bollywood_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of bollywood_new_list_right"
                            [ngClass]="{'news_right_box': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_box':fl.bollywood_image_position_home=='left','news_center_box':fl.bollywood_image_position_home=='center','news-center':fl.bollywood_use_br=='yes'}">
                            <div *ngIf="fl.bollywood_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.bollywood_image_position_home=='right','news_left_thumb':(fl.bollywood_image_position_home=='left' || fl.bollywood_image_position_home=='left_right'),'news_center_thumb':fl.bollywood_image_position_home=='center'}">
                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.bollywood_heading_image_2" class="news_right_thumb">
                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_content':fl.bollywood_image_position_home=='left','news_center_content':fl.bollywood_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.bollywood_gred_above">{{fl.bollywood_gred_above}}<br /></a>
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.bollywood_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.bollywood_gred_below"><strong> :
                                        </strong>{{fl.bollywood_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.bollywood_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.bollywood_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of bollywood_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.bollywood_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.bollywood_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.bollywood_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of bollywood_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.bollywood_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.bollywood_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.bollywood_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='gujarat'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/gujrat.png" />સમાચાર ગુજરાત</div>
                    </a>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div *ngFor="let fl of gujarat_new_list_left"
                            [ngClass]="{'news_right_box': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_box':fl.gujarat_image_position_home=='left','news_center_box':fl.gujarat_image_position_home=='center','news-center':fl.gujarat_use_br=='yes'}">
                            <div *ngIf="fl.gujarat_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.gujarat_image_position_home=='right','news_left_thumb':(fl.gujarat_image_position_home=='left' || fl.gujarat_image_position_home=='left_right'),'news_center_thumb':fl.gujarat_image_position_home=='center'}">
                                <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.gujarat_heading_image_2" class="news_right_thumb">
                                <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_content':fl.gujarat_image_position_home=='left','news_center_content':fl.gujarat_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.gujarat_gred_above">{{fl.gujarat_gred_above}}<br /></a>
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.gujarat_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.gujarat_gred_below"><strong> :
                                        </strong>{{fl.gujarat_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.gujarat_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of gujarat_new_list_right"
                            [ngClass]="{'news_right_box': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_box':fl.gujarat_image_position_home=='left','news_center_box':fl.gujarat_image_position_home=='center','news-center':fl.gujarat_use_br=='yes'}">
                            <div *ngIf="fl.gujarat_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.gujarat_image_position_home=='right','news_left_thumb':(fl.gujarat_image_position_home=='left' || fl.gujarat_image_position_home=='left_right'),'news_center_thumb':fl.gujarat_image_position_home=='center'}">
                                <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.gujarat_heading_image_2" class="news_right_thumb">
                                <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_content':fl.gujarat_image_position_home=='left','news_center_content':fl.gujarat_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.gujarat_gred_above">{{fl.gujarat_gred_above}}<br /></a>
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.gujarat_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.gujarat_gred_below"><strong> :
                                        </strong>{{fl.gujarat_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.gujarat_update_time}}
                                        IST </span>
                                    <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of gujarat_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/gujarat-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.gujarat_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.gujarat_update_time}}
                                                        IST </span>
                                                    <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of gujarat_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/gujarat-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.gujarat_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.gujarat_update_time}}
                                                        IST </span>
                                                    <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='international'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/world.png" />દેશ-વિદેશ</div>
                    </a>
                </div>
                <div class="row m-0">
                    <div class="col-sm-6 pl-0">
                        <div *ngFor="let fl of international_new_list_left"
                            [ngClass]="{'news_right_box': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_box':fl.international_image_position_home=='left','news_center_box':fl.international_image_position_home=='center','news-center':fl.international_use_br=='yes'}">
                            <div *ngIf="fl.international_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.international_image_position_home=='right','news_left_thumb':(fl.international_image_position_home=='left' || fl.international_image_position_home=='left_right'),'news_center_thumb':fl.international_image_position_home=='center'}">
                                <a routerLink="/international-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.international_heading_image_2" class="news_right_thumb">
                                <a routerLink="/international-news-detail/{{fl._id}}">
                                    <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_content':fl.international_image_position_home=='left','news_center_content':fl.international_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.international_gred_above">{{fl.international_gred_above}}<br /></a>
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.international_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.international_gred_below"><strong> :
                                        </strong>{{fl.international_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.international_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.international_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of international_new_list_right"
                            [ngClass]="{'news_right_box': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_box':fl.international_image_position_home=='left','news_center_box':fl.international_image_position_home=='center','news-center':fl.international_use_br=='yes'}">
                            <div *ngIf="fl.international_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.international_image_position_home=='right','news_left_thumb':(fl.international_image_position_home=='left' || fl.international_image_position_home=='left_right'),'news_center_thumb':fl.international_image_position_home=='center'}">
                                <a routerLink="/international-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.international_heading_image_2" class="news_right_thumb">
                                <a routerLink="/international-news-detail/{{fl._id}}">
                                    <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_content':fl.international_image_position_home=='left','news_center_content':fl.international_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.international_gred_above">{{fl.international_gred_above}}<br /></a>
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.international_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/international-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.international_gred_below"><strong> :
                                        </strong>{{fl.international_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.international_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.international_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of international_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/international-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.international_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.international_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.international_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of international_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/international-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.international_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.international_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.international_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='main'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/news.png" />મુખ્ય સમાચાર</div>
                    </a>
                </div>
                <div class="row ml-0">
                    <div class="col-sm-6 pl-0">
                        <div *ngFor="let fl of main_new_list_left"
                            [ngClass]="{'news_right_box': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_box':fl.main_image_position_home=='left' ,'news_center_box':fl.main_image_position_home=='center','news-center':fl.main_use_br=='yes'}">
                            <div *ngIf="fl.main_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.main_image_position_home=='right','news_left_thumb':(fl.main_image_position_home=='left' || fl.main_image_position_home=='left_right'),'news_center_thumb':fl.main_image_position_home=='center'}">
                                <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.main_heading_image_2" class="news_right_thumb">
                                <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_content':fl.main_image_position_home=='left' ,'news_center_content':fl.main_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.main_gred_above">{{fl.main_gred_above}}<br /></a>
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.main_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.main_gred_below"><strong> : </strong>{{fl.main_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.main_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.main_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of main_new_list_right"
                            [ngClass]="{'news_right_box': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_box':fl.main_image_position_home=='left' ,'news_center_box':fl.main_image_position_home=='center','news-center':fl.main_use_br=='yes'}">
                            <div *ngIf="fl.main_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.main_image_position_home=='right','news_left_thumb':(fl.main_image_position_home=='left' || fl.main_image_position_home=='left_right'),'news_center_thumb':fl.main_image_position_home=='center'}">
                                <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.main_heading_image_2" class="news_right_thumb">
                                <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_content':fl.main_image_position_home=='left' ,'news_center_content':fl.main_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.main_gred_above">{{fl.main_gred_above}}<br /></a>
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.main_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.main_gred_below"><strong> : </strong>{{fl.main_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.main_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.main_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ml-0">
                    <div class="col-sm-6 pl-0">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of main_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/main-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.main_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.main_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.main_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of main_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/main-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.main_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.main_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.main_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='rajkot'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/rajkot.png" />સમાચાર રાજકોટ</div>
                    </a>
                </div>
                <div class="row m-0">
                    <div class="col-sm-6 pl-0">
                        <div *ngFor="let fl of rajkot_new_list_left"
                            [ngClass]="{'news_right_box': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_box':fl.rajkot_image_position_home=='left','news_center_box':fl.rajkot_image_position_home=='center','news-center':fl.rajkot_use_br=='yes'}">
                            <div *ngIf="fl.rajkot_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.rajkot_image_position_home=='right','news_left_thumb':(fl.rajkot_image_position_home=='left' || fl.rajkot_image_position_home=='left_right'),'news_center_thumb':fl.rajkot_image_position_home=='center'}">
                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.rajkot_heading_image_2" class="news_right_thumb">
                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_content':fl.rajkot_image_position_home=='left','news_center_content':fl.rajkot_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.rajkot_gred_above">{{fl.rajkot_gred_above}}<br /></a>
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.rajkot_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.rajkot_gred_below"><strong> : </strong>{{fl.rajkot_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.rajkot_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of rajkot_new_list_right"
                            [ngClass]="{'news_right_box': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_box':fl.rajkot_image_position_home=='left','news_center_box':fl.rajkot_image_position_home=='center','news-center':fl.rajkot_use_br=='yes'}">
                            <div *ngIf="fl.rajkot_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.rajkot_image_position_home=='right','news_left_thumb':(fl.rajkot_image_position_home=='left' || fl.rajkot_image_position_home=='left_right'),'news_center_thumb':fl.rajkot_image_position_home=='center'}">
                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.rajkot_heading_image_2" class="news_right_thumb">
                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_content':fl.rajkot_image_position_home=='left','news_center_content':fl.rajkot_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.rajkot_gred_above">{{fl.rajkot_gred_above}}<br /></a>
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.rajkot_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.rajkot_gred_below"><strong> : </strong>{{fl.rajkot_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.rajkot_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of rajkot_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.rajkot_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.rajkot_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of rajkot_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.rajkot_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.rajkot_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='saurashtra'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/kutch.png" />સૌરાષ્ટ્ર કચ્છ</div>
                    </a>
                </div>
                <div class="row m-0">
                    <div class="col-sm-6 pl-0">
                        <div *ngFor="let fl of saurashtra_new_list_left"
                            [ngClass]="{'news_right_box': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_box':fl.saurashtra_image_position_home=='left','news_center_box':fl.saurashtra_image_position_home=='center','news-center':fl.saurashtra_use_br=='yes'}">
                            <div *ngIf="fl.saurashtra_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.saurashtra_image_position_home=='right','news_left_thumb':(fl.saurashtra_image_position_home=='left' || fl.saurashtra_image_position_home=='left_right'),'news_center_thumb':fl.saurashtra_image_position_home=='center'}">
                                <a routerLink="/saurashtra-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.saurashtra_heading_image_2" class="news_right_thumb">
                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                    <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_content':fl.saurashtra_image_position_home=='left','news_center_content':fl.saurashtra_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.saurashtra_gred_above">{{fl.saurashtra_gred_above}}<br /></a>
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.saurashtra_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.saurashtra_gred_below"><strong> :
                                        </strong>{{fl.saurashtra_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.saurashtra_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.saurashtra_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of saurashtra_new_list_right"
                            [ngClass]="{'news_right_box': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_box':fl.saurashtra_image_position_home=='left','news_center_box':fl.saurashtra_image_position_home=='center','news-center':fl.saurashtra_use_br=='yes'}">
                            <div *ngIf="fl.saurashtra_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.saurashtra_image_position_home=='right','news_left_thumb':(fl.saurashtra_image_position_home=='left' || fl.saurashtra_image_position_home=='left_right'),'news_center_thumb':fl.saurashtra_image_position_home=='center'}">
                                <a routerLink="/saurashtra-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.saurashtra_heading_image_2" class="news_right_thumb">
                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                    <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_content':fl.saurashtra_image_position_home=='left','news_center_content':fl.saurashtra_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.saurashtra_gred_above">{{fl.saurashtra_gred_above}}<br /></a>
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.saurashtra_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.saurashtra_gred_below"><strong> :
                                        </strong>{{fl.saurashtra_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.saurashtra_update_time}}
                                        IST</span>
                                    <span class="order-info">&nbsp;- {{fl.saurashtra_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of saurashtra_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.saurashtra_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.main_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.saurashtra_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of saurashtra_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.saurashtra_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.saurashtra_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.saurashtra_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="main-news-page" *ngIf="section=='sports'">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="main-title-outer">
                    <a href="#">
                        <div class="main-title"><img src="assets/webPreview/images/game.png" />ખેલ-જગત</div>
                    </a>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div *ngFor="let fl of sports_new_list_left"
                            [ngClass]="{'news_right_box': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_box':fl.sports_image_position_home=='left','news_center_box':fl.sports_image_position_home=='center','news-center':fl.sports_use_br=='yes'}">
                            <div *ngIf="fl.sports_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.sports_image_position_home=='right','news_left_thumb':(fl.sports_image_position_home=='left' || fl.sports_image_position_home=='left_right'),'news_center_thumb':fl.sports_image_position_home=='center'}">
                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.sports_heading_image_2" class="news_right_thumb">
                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_content':fl.sports_image_position_home=='left','news_center_content':fl.sports_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.sports_gred_above">{{fl.sports_gred_above}}<br /></a>
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.sports_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.sports_gred_below"><strong> : </strong>{{fl.sports_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.sports_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div *ngFor="let fl of sports_new_list_right"
                            [ngClass]="{'news_right_box': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_box':fl.sports_image_position_home=='left','news_center_box':fl.sports_image_position_home=='center','news-center':fl.sports_use_br=='yes'}">
                            <div *ngIf="fl.sports_heading_image_1"
                                [ngClass]="{'news_right_thumb': fl.sports_image_position_home=='right','news_left_thumb':(fl.sports_image_position_home=='left' || fl.sports_image_position_home=='left_right'),'news_center_thumb':fl.sports_image_position_home=='center'}">
                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_1}}" /></a>
                            </div>
                            <div *ngIf="fl.sports_heading_image_2" class="news_right_thumb">
                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_2}}" /></a>
                            </div>
                            <div class="news_left_content"
                                [ngClass]="{'news_right_content': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_content':fl.sports_image_position_home=='left','news_center_content':fl.sports_image_position_home=='center'}">
                                <p align="left">
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.sports_gred_above">{{fl.sports_gred_above}}<br /></a>
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="title"><strong
                                            [innerHTML]="fl.sports_guj_title | safeHtml"></strong></a>
                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                        *ngIf="fl.sports_gred_below"><strong> : </strong>{{fl.sports_gred_below}}</a>
                                    <span class="date-info"><i
                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.sports_update_time}} IST</span>
                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of sports_new_list_left_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/sports-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.sports_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.sports_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="main_news_page_left">
                            <div class="inner_news_page_left_wrap">
                                <div class="main_news_page_left_list">
                                    <ul>
                                        <li *ngFor="let fl of sports_new_list_right_2">
                                            <div class="news_left_content">
                                                <p align="left">
                                                    <a routerLink="/sports-news-detail/{{fl._id}}">
                                                        <strong [innerHTML]="fl.sports_guj_title | safeHtml">
                                                        </strong>
                                                    </a>
                                                    <span class="date-info"><i class="fa fa-clock-o mr-1 ml-1"></i>
                                                        {{fl.sports_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>