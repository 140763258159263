import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpinionPollRoutingModule } from './opinion-poll-routing.module';
import { OpinionPollAddComponent } from './opinion-poll-add/opinion-poll-add.component';
import { OpinionPollListComponent } from './opinion-poll-list/opinion-poll-list.component';
@NgModule({
  declarations: [OpinionPollAddComponent, OpinionPollListComponent],
  imports: [
    CommonModule,
    OpinionPollRoutingModule
  ]
})
export class OpinionPollModule { }
