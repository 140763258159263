import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-rashifal-list',
  templateUrl: './rashifal-list.component.html',
  styleUrls: ['./rashifal-list.component.css']
})
export class RashifalListComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
