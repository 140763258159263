import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeNewsPreviewRoutingModule } from './home-news-preview-routing.module';
import { PreviewComponent } from './preview/preview.component';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@NgModule({
  declarations: [PreviewComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    HomeNewsPreviewRoutingModule
  ]
})
export class HomeNewsPreviewModule { }
