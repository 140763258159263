import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { PanchangAddComponent } from './panchang-add/panchang-add.component';
import { PanchangListComponent } from './panchang-list/panchang-list.component';
import { PanchangDayComponent } from './panchang-day/panchang-day.component';
import { PanchangEditComponent } from './panchang-edit/panchang-edit.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'panchang-add', component: PanchangAddComponent },
  { path: 'panchang-list', component: PanchangListComponent },
  { path: 'panchang-day/:id', component: PanchangDayComponent },
  { path: 'panchang-edit/:id', component: PanchangEditComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PanchangRoutingModule { }
