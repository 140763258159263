import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ckeditor4-angular';
import { BirthdayRoutingModule } from './birthday-routing.module';
import { BirthdayAddComponent } from './birthday-add/birthday-add.component';
import { BirthdayListComponent } from './birthday-list/birthday-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [BirthdayAddComponent, BirthdayListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    BirthdayRoutingModule
  ]
})
export class BirthdayModule { }
