import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  CategoryList = [];
  show_multiple_delete_btn = false;
  NewsDeleteCheckboxList = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent) {
    this.DefaultComponent.loaderShow();
    this.get_categoy_list();
  }
  get_categoy_list() {
    this.CommonService.GetCategoryList().subscribe((data) => {
      if (data.status) {
        this.CategoryList = data.category_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    if (this.NewsDeleteCheckboxList.includes(id)) {
      var index = this.NewsDeleteCheckboxList.indexOf(id);
      this.NewsDeleteCheckboxList.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.NewsDeleteCheckboxList.push(id);
    }
    if (this.NewsDeleteCheckboxList.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  update_display_orders(e, id, ori_ord) {
    if (parseInt(ori_ord) != parseInt(e.target.value)) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'display_order': e.target.value, 'id': id, 'ori_display_order': ori_ord, 'table_name': 'tbl_epaper_category' };
      this.CommonService.UpdateEpaperCategoryDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_categoy_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  delete_multiple_news() {
    var th = this;
    var delete_checkbox = this.NewsDeleteCheckboxList;
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    $.ajax({
      url: environment.EpaperBaseUrl + 'delete_category',
      method: 'POST',
      data: { delete_checkbox: delete_checkbox },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.NewsDeleteCheckboxList = [];
          th.toastr.success(res.message)
          th.get_categoy_list();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  toggleVisibility(e) {
    var th = this;
    if (e.target.checked == true) {
      var cat_id = e.target.value;
      var status = 'yes';
    } else {
      var cat_id = e.target.value;
      var status = 'no';
    }
    this.DefaultComponent.loaderShow();
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    $.ajax({
      url: environment.EpaperBaseUrl + 'category_status_change',
      method: 'POST',
      data: { cat_id: cat_id, status: status },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.toastr.success(res.message)
          th.get_categoy_list();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  ngOnInit(): void {
  }
}
