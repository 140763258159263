import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { RashifalAddComponent } from './rashifal-add/rashifal-add.component';
import { RashifalListComponent } from './rashifal-list/rashifal-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'rashifal-add', component: RashifalAddComponent },
  { path: 'rashifal-list', component: RashifalListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RashifalRoutingModule { }
