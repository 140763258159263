import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { AddComponent } from './add/add.component';
import { ListComponent } from './list/list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'admin-group-add', component: AddComponent },
  { path: 'admin-group-list', component: ListComponent },
  { path: 'admin-group-edit/:news_id', component: AddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminGroupRoutingModule { }
