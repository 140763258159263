<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="notification_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Notification</h4>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Title: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="title" value="" id="title">
										</div>
									</div>
								</div>
								<div class="form-group">
									<div>
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>