import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewsRoutingModule } from './news-routing.module';
import { FatafatNewsAddComponent } from './fatafat-news-add/fatafat-news-add.component';
import { FatafatNewsListComponent } from './fatafat-news-list/fatafat-news-list.component';
// import { CKEditorModule } from 'ng2-ckeditor';
import { MainNewsAddComponent } from './main-news-add/main-news-add.component';
import { MainNewsListComponent } from './main-news-list/main-news-list.component';
import { ShortNewsAddComponent } from './short-news-add/short-news-add.component';
import { ShortNewsListComponent } from './short-news-list/short-news-list.component';
import { NriNewsAddComponent } from './nri-news-add/nri-news-add.component';
import { NriNewsListComponent } from './nri-news-list/nri-news-list.component';
import { SportsNewsAddComponent } from './sports-news-add/sports-news-add.component';
import { SportsNewsListComponent } from './sports-news-list/sports-news-list.component';
import { BollywoodNewsAddComponent } from './bollywood-news-add/bollywood-news-add.component';
import { BollywoodNewsListComponent } from './bollywood-news-list/bollywood-news-list.component';
import { InternationalNewsAddComponent } from './international-news-add/international-news-add.component';
import { InternationalNewsListComponent } from './international-news-list/international-news-list.component';
import { GujaratNewsListComponent } from './gujarat-news-list/gujarat-news-list.component';
import { GujaratNewsAddComponent } from './gujarat-news-add/gujarat-news-add.component';
import { SaurashtraNewsAddComponent } from './saurashtra-news-add/saurashtra-news-add.component';
import { SaurashtraNewsListComponent } from './saurashtra-news-list/saurashtra-news-list.component';
import { RajkotNewsListComponent } from './rajkot-news-list/rajkot-news-list.component';
import { RajkotNewsAddComponent } from './rajkot-news-add/rajkot-news-add.component';
import { DomSanitizer } from '@angular/platform-browser'
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from 'ckeditor4-angular';
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@NgModule({
  declarations: [FatafatNewsAddComponent, FatafatNewsListComponent, MainNewsAddComponent, MainNewsListComponent, ShortNewsAddComponent, ShortNewsListComponent, NriNewsAddComponent, NriNewsListComponent, SportsNewsAddComponent, SportsNewsListComponent, BollywoodNewsAddComponent, BollywoodNewsListComponent, InternationalNewsAddComponent, InternationalNewsListComponent, GujaratNewsListComponent, GujaratNewsAddComponent, SaurashtraNewsAddComponent, SaurashtraNewsListComponent, RajkotNewsListComponent, RajkotNewsAddComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    NewsRoutingModule
  ]
})
export class NewsModule { }
