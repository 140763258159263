import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
@Component({
  selector: 'app-vividh-vibhag-title-list',
  templateUrl: './vividh-vibhag-title-list.component.html',
  styleUrls: ['./vividh-vibhag-title-list.component.css']
})
export class VividhVibhagTitleListComponent implements OnInit {
  TitleList = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_title_list();
  }
  ngOnInit(): void {
  }
  get_title_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetVividhTitleList(parameter).subscribe((data) => {
      if (data.status) {
        this.TitleList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    // if (this.news_delete_checkbox.includes(id)) {
    //   var index = this.news_delete_checkbox.indexOf(id);
    //   this.news_delete_checkbox.splice(index, 1);
    // }
    // if (e.target.checked == true) {
    //   this.news_delete_checkbox.push(id);
    // }
    // if (this.news_delete_checkbox.length > 0) {
    //   this.show_multiple_delete_btn = true;
    // } else {
    //   this.show_multiple_delete_btn = false;
    // }
  }
  delete_multiple_news() {
    // var th = this;
    // var routers = this.router;
    // this.DefaultComponent.loaderShow();
    // var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_fatafat_news', "prefix": "fatafat" };
    // this.CommonService.DeleteFatafatNews(parameter).subscribe((data) => {
    //   if (data.status) {
    //     th.toastr.success(data.message);
    //     this.news_delete_checkbox = [];
    //     th.get_title_list();
    //   } else {
    //     if (data.token_expire) {
    //       localStorage.removeItem('login_token');
    //       routers.navigate(['/']);
    //     } else {
    //       th.toastr.error(data.message);
    //     }
    //   }
    //   this.DefaultComponent.loaderHide();
    // });
  }
  update_news_display_home(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'display_home': status, 'id': id, 'table_name': 'tbl_fatafat_news', 'prefix': 'fatafat' };
    this.CommonService.UpdateNewsDisplayHome(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_dropdown(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_vividh_vibhag_title', 'prefix': 'vividh_page_dd' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_vividh_vibhag_title', 'prefix': 'vividh_page' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_display_orders(e, id, ori_ord) {
    if (parseInt(ori_ord) != parseInt(e.target.value)) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'display_order': e.target.value, 'id': id, 'ori_display_order': ori_ord, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_fatafat_news', 'prefix': 'fatafat' };
      this.CommonService.UpdateVividhVibhagTitleDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_title_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
}
