<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/short-news-add">
								<!--<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>-->
							</a>
						</ol>
					</div>
					<h4 class="page-title">CMS</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<table class="table">
								<thead>
									<tr>
										<th>Page Title</th>
										<th>Active?</th>
									</tr>
								</thead>
								<tr *ngFor="let mn of cmsList">
									<td>
										<a routerLink="/cms-edit/{{mn._id}}" [innerHTML]="mn.title"></a>
									</td>
									<td>
										<input type="checkbox" [checked]="mn.status == 'yes'"
											(change)="update_news_status($event,mn._id)" value="no">
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>