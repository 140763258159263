import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-panchang-add',
  templateUrl: './panchang-add.component.html',
  styleUrls: ['./panchang-add.component.css']
})
export class PanchangAddComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) { }
  ngOnInit(): void {
    $('#datepicker').datepicker({
      format: "MM yyyy",
      minViewMode: 1,
      maxViewMode: 0,
      autoclose: true,
      orientation: "top",
      todayHighlight: true,
    }).on('hide', function (ev) {
      var today = ev.date;
      var mon = '';
      var month = (today.getMonth() + 1);
      var year = today.getFullYear();
      var day = 0;
      if (month == '1') { day = 31; mon = "01"; }
      if (month == '2') {
        mon = "02";
        if (year % 4 == 0) {
          day = 29;
        }
        else {
          day = 28;
        }
      }
      if (month == '3') { day = 31; mon = "03"; }
      if (month == '4') { day = 30; mon = "04"; }
      if (month == '5') { day = 31; mon = "05"; }
      if (month == '6') { day = 30; mon = "06"; }
      if (month == '7') { day = 31; mon = "07"; }
      if (month == '8') { day = 31; mon = "08"; }
      if (month == '9') { day = 30; mon = "09"; }
      if (month == '10') { day = 31; mon = "10"; }
      if (month == '11') { day = 30; mon = "11"; }
      if (month == '12') { day = 31; mon = "12"; }
      var date = '';
      for (var i = 0; i < day; i++) {
        var panchang_date = i + 1;
        if (panchang_date < 10) {
          var Fpanchang_date = '0' + panchang_date;
        } else {
          var Fpanchang_date = '' + panchang_date;
        }
        date += '<div class="col-md-6 row"><label class="col-sm-4 col-form-label">' + Fpanchang_date + '-' + mon + '-' + year + '</label> <div class="col-sm-8"><input type="hidden" name="ori_date" value="' + Fpanchang_date + '-' + mon + '-' + year + '"><textarea class="form-control" type="text" name="sdate" id="datepicker" >-</textarea></div></div>'
      }
      $("#date1").html(date);
    });
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#panchang_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.GeneralBaseUrl + 'save_panchang',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/panchang-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
