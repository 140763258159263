import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FestivalPhotosRoutingModule } from './festival-photos-routing.module';
import { FestivalPhotosAddComponent } from './festival-photos-add/festival-photos-add.component';
import { FestivalPhotosListComponent } from './festival-photos-list/festival-photos-list.component';
@NgModule({
  declarations: [FestivalPhotosAddComponent, FestivalPhotosListComponent],
  imports: [
    CommonModule,
    FestivalPhotosRoutingModule
  ]
})
export class FestivalPhotosModule { }
