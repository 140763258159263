import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { EncryptionService } from '../../encryption.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  public today_date = localStorage.getItem('EntryDate')
  public main_new_list = [];
  public fatafat_new_list_left = [];
  public fatafat_new_list_right = [];
  public gujarat_new_list_left = [];
  public gujarat_new_list_right = [];
  public rajkot_new_list_left = [];
  public rajkot_new_list_right = [];
  public bollywood_new_list_left = [];
  public bollywood_new_list_right = [];
  public sports_new_list_left = [];
  public sports_new_list_right = [];
  public saurashtra_new_list_left = [];
  public saurashtra_new_list_right = [];
  public international_new_list_left = [];
  public international_new_list_right = [];
  public birthday_list_left = [];
  public birthday_list_right = [];
  public marriage_list_left = [];
  public marriage_list_right = [];
  public mainNewsAds = [];
  public fatafatAds = [];
  public gujaratAds = [];
  public afterGujaratAds = [];
  public rajkotAds = [];
  public saurashtraAds = [];
  public internationalAds = [];
  public sportsAds = [];
  public bollywoodAds = [];
  public festivalPhotos = [];
  public news_image_url = environment.baseUrl;
  public festivalPhotosPath = '';
  section = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private CommonService: CommonService,
    private EncryptionService: EncryptionService,
  ) {
    this.section = this.route.snapshot.paramMap.get('section')
    this.getAdvertisement();
    if (this.section == 'main') {
      this.getMainNews();
      this.getFestivalPhotos();
    } else if (this.section == 'marriage') {
      this.getMarriageNews();
    } else if (this.section == 'birthday') {
      this.getBirthDayNews();
    } else if (this.section == 'fatafat') {
      this.getFatafatNews();
    } else if (this.section == 'gujarat') {
      this.getGujaratNews();
    } else if (this.section == 'rajkot') {
      this.getRajkotNews();
    } else if (this.section == 'saurashtra') {
      this.getSaurashtraNews();
    } else if (this.section == 'international') {
      this.getInternationalNews();
    } else if (this.section == 'sports') {
      this.getSportsNews();
    } else if (this.section == 'bollywood') {
      this.getBollywoodNews();
    }
  }
  getMainNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getMainNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          this.main_new_list = data.news_list;
          for (var i = 0; i < this.main_new_list.length; i++) {
            var video_data = this.main_new_list[i]['video_detail'];
            if (video_data && video_data.length > 0) {
              var tempVideoData = video_data[0]
              if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                this.main_new_list[i]['video_available'] = true;
                delete this.main_new_list[i]['video_detail'];
                continue;
              } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                this.main_new_list[i]['video_available'] = true;
                delete this.main_new_list[i]['video_detail'];
                continue;
              } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                this.main_new_list[i]['video_available'] = true;
                delete this.main_new_list[i]['video_detail'];
                continue;
              } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                this.main_new_list[i]['video_available'] = true;
                delete this.main_new_list[i]['video_detail'];
                continue;
              } else {
                this.main_new_list[i]['video_available'] = false;
                delete this.main_new_list[i]['video_detail'];
                continue;
              }
            }
          }
          console.log(this.main_new_list);
        }
      } else {
        this.main_new_list = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getMarriageNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getMarriageNews(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].marriage_news_position == 'right') {
              this.marriage_list_right.push(news_data[i]);
            } else {
              this.marriage_list_left.push(news_data[i]);
            }
          }
        }
      } else {
        this.marriage_list_right = [];
        this.marriage_list_left = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getAdvertisement() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getAdvertisement(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var tempData = data.ads_data;
          for (var i = 0; i < tempData.length; i++) {
            if (tempData[i].location == 'bottom') {
              this.mainNewsAds.push(tempData[i]);
            } else if (tempData[i].location == 'fatafat') {
              this.fatafatAds.push(tempData[i]);
            } else if (tempData[i].location == 'Gujarat News Banner') {
              this.gujaratAds.push(tempData[i]);
            } else if (tempData[i].location == 'home-middle-right') {
              this.afterGujaratAds.push(tempData[i]);
            } else if (tempData[i].location == 'Rajkot News Banner') {
              this.rajkotAds.push(tempData[i]);
            } else if (tempData[i].location == 'Saurashtra-Kutch News Banner') {
              this.saurashtraAds.push(tempData[i]);
            } else if (tempData[i].location == 'International-News-Banner') {
              this.internationalAds.push(tempData[i]);
            } else if (tempData[i].location == 'Sports News Banner') {
              this.sportsAds.push(tempData[i]);
            } else if (tempData[i].location == 'Entertainment News Banner') {
              this.bollywoodAds.push(tempData[i]);
            } else if (tempData[i].location == 'Entertainment News Banner') {
              this.bollywoodAds.push(tempData[i]);
            }
          }
          setTimeout(() => {
            for (var i = 0; i < this.mainNewsAds.length; i++) {
              if (this.mainNewsAds[i].type == 'source') {
                var ads = $(".main_news_ads").eq(i)
                if (ads) {
                  ads.html(this.mainNewsAds[i].advertise_link);
                }
              } else {
                var ads = $(".main_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.mainNewsAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.mainNewsAds[i].advertise_link + '" target="_blank">' + this.mainNewsAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.mainNewsAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.mainNewsAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.mainNewsAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.mainNewsAds[i].advertise_link + '" target="_blank">' + this.mainNewsAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.fatafatAds.length; i++) {
              if (this.fatafatAds[i].type == 'source') {
                var ads = $(".fatafat_news_ads").eq(i)
                if (ads) {
                  ads.html(this.fatafatAds[i].advertise_link);
                }
              } else {
                var ads = $(".fatafat_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.fatafatAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.fatafatAds[i].advertise_link + '" target="_blank">' + this.fatafatAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.fatafatAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.fatafatAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.fatafatAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.fatafatAds[i].advertise_link + '" target="_blank">' + this.fatafatAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.gujaratAds.length; i++) {
              if (this.gujaratAds[i].type == 'source') {
                var ads = $(".gujarat_news_ads").eq(i)
                if (ads) {
                  ads.html(this.gujaratAds[i].advertise_link);
                }
              } else {
                var ads = $(".gujarat_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.gujaratAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.gujaratAds[i].advertise_link + '" target="_blank">' + this.gujaratAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.gujaratAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.gujaratAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.gujaratAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.gujaratAds[i].advertise_link + '" target="_blank">' + this.gujaratAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.afterGujaratAds.length; i++) {
              if (this.afterGujaratAds[i].type == 'source') {
                var ads = $(".after_gujarat_ads").eq(i)
                if (ads) {
                  ads.html(this.afterGujaratAds[i].advertise_link);
                }
              } else {
                var ads = $(".after_gujarat_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.afterGujaratAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.afterGujaratAds[i].advertise_link + '" target="_blank">' + this.afterGujaratAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.afterGujaratAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.afterGujaratAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.afterGujaratAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.afterGujaratAds[i].advertise_link + '" target="_blank">' + this.afterGujaratAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.rajkotAds.length; i++) {
              if (this.rajkotAds[i].type == 'source') {
                var ads = $(".rajkot_news_ads").eq(i)
                if (ads) {
                  ads.html(this.rajkotAds[i].advertise_link);
                }
              } else {
                var ads = $(".rajkot_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.rajkotAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.rajkotAds[i].advertise_link + '" target="_blank">' + this.rajkotAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.rajkotAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.rajkotAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.rajkotAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.rajkotAds[i].advertise_link + '" target="_blank">' + this.rajkotAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.saurashtraAds.length; i++) {
              if (this.saurashtraAds[i].type == 'source') {
                var ads = $(".saurashtra_news_ads").eq(i)
                if (ads) {
                  ads.html(this.saurashtraAds[i].advertise_link);
                }
              } else {
                var ads = $(".saurashtra_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.saurashtraAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.saurashtraAds[i].advertise_link + '" target="_blank">' + this.saurashtraAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.saurashtraAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.saurashtraAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.saurashtraAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.saurashtraAds[i].advertise_link + '" target="_blank">' + this.saurashtraAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.internationalAds.length; i++) {
              if (this.internationalAds[i].type == 'source') {
                var ads = $(".international_news_ads").eq(i)
                if (ads) {
                  ads.html(this.internationalAds[i].advertise_link);
                }
              } else {
                var ads = $(".international_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.internationalAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.internationalAds[i].advertise_link + '" target="_blank">' + this.internationalAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.internationalAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.internationalAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.internationalAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.internationalAds[i].advertise_link + '" target="_blank">' + this.internationalAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.sportsAds.length; i++) {
              if (this.sportsAds[i].type == 'source') {
                var ads = $(".sports_news_ads").eq(i)
                if (ads) {
                  ads.html(this.sportsAds[i].advertise_link);
                }
              } else {
                var ads = $(".sports_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.sportsAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.sportsAds[i].advertise_link + '" target="_blank">' + this.sportsAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.sportsAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.sportsAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.sportsAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.sportsAds[i].advertise_link + '" target="_blank">' + this.sportsAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            for (var i = 0; i < this.bollywoodAds.length; i++) {
              if (this.bollywoodAds[i].type == 'source') {
                var ads = $(".bollywood_news_ads").eq(i)
                if (ads) {
                  ads.html(this.bollywoodAds[i].advertise_link);
                }
              } else {
                var ads = $(".bollywood_news_ads").eq(i)
                if (ads) {
                  var html = '';
                  if (this.bollywoodAds[i].advertise_text_above) {
                    html += '<div class="text-center"><a href="' + this.bollywoodAds[i].advertise_link + '" target="_blank">' + this.bollywoodAds[i].advertise_text_above + '</a></div>';
                  }
                  html += '<a href="' + this.bollywoodAds[i].advertise_link + '" target="_blank"><img src="' + this.news_image_url + 'advertise/' + this.bollywoodAds[i].advertise_image + '" class="img-fluid" alt="Responsive image"></a>';
                  if (this.bollywoodAds[i].advertise_text_below) {
                    html += '<div class="text-center"><a href="' + this.bollywoodAds[i].advertise_link + '" target="_blank">' + this.bollywoodAds[i].advertise_text_below + '</a></div>';
                  }
                  ads.html(html);
                }
              }
            }
            setTimeout(() => {
              (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
            }, 2000);
          }, 1000);
          // var ads = document.getElementById('push_ads');
          // if (ads) {
          //   ads.innerHTML = data.ads;
          //   (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
          // }
        }
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getFestivalPhotos() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getFestivalPhotos(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var photos_data = data.photos_data;
          this.festivalPhotosPath = data.image_path;
          if (photos_data.length > 0) {
            this.festivalPhotos = photos_data;
          }
        }
      } else {
        this.festivalPhotos = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getBirthDayNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getBirthDayNews(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].birthday_news_position == 'right') {
              this.birthday_list_right.push(news_data[i]);
            } else {
              this.birthday_list_left.push(news_data[i]);
            }
          }
        }
      } else {
        this.birthday_list_right = [];
        this.birthday_list_left = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getFatafatNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getFatafatNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].fatafat_news_position == 'right') {
              this.fatafat_new_list_right.push(news_data[i]);
            } else {
              this.fatafat_new_list_left.push(news_data[i]);
            }
          }
        }
      } else {
        this.fatafat_new_list_left = [];
        this.fatafat_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getGujaratNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getGujaratNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].gujarat_news_position == 'right') {
              this.gujarat_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.gujarat_new_list_right.length; i++) {
                var video_data = this.gujarat_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.gujarat_new_list_right[i]['video_available'] = true;
                    delete this.gujarat_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.gujarat_new_list_right[i]['video_available'] = true;
                    delete this.gujarat_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.gujarat_new_list_right[i]['video_available'] = true;
                    delete this.gujarat_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.gujarat_new_list_right[i]['video_available'] = true;
                    delete this.gujarat_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.gujarat_new_list_right[i]['video_available'] = false;
                    delete this.gujarat_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.gujarat_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.gujarat_new_list_left.length; i++) {
                var video_data = this.gujarat_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.gujarat_new_list_left[i]['video_available'] = true;
                    delete this.gujarat_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.gujarat_new_list_left[i]['video_available'] = true;
                    delete this.gujarat_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.gujarat_new_list_left[i]['video_available'] = true;
                    delete this.gujarat_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.gujarat_new_list_left[i]['video_available'] = true;
                    delete this.gujarat_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.gujarat_new_list_left[i]['video_available'] = false;
                    delete this.gujarat_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.gujarat_new_list_left = [];
        this.gujarat_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getRajkotNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getRajkotNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].rajkot_news_position == 'right') {
              this.rajkot_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.rajkot_new_list_right.length; i++) {
                var video_data = this.rajkot_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.rajkot_new_list_right[i]['video_available'] = true;
                    delete this.rajkot_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.rajkot_new_list_right[i]['video_available'] = true;
                    delete this.rajkot_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.rajkot_new_list_right[i]['video_available'] = true;
                    delete this.rajkot_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.rajkot_new_list_right[i]['video_available'] = true;
                    delete this.rajkot_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.rajkot_new_list_right[i]['video_available'] = false;
                    delete this.rajkot_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.rajkot_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.rajkot_new_list_left.length; i++) {
                var video_data = this.rajkot_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.rajkot_new_list_left[i]['video_available'] = true;
                    delete this.rajkot_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.rajkot_new_list_left[i]['video_available'] = true;
                    delete this.rajkot_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.rajkot_new_list_left[i]['video_available'] = true;
                    delete this.rajkot_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.rajkot_new_list_left[i]['video_available'] = true;
                    delete this.rajkot_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.rajkot_new_list_left[i]['video_available'] = false;
                    delete this.rajkot_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.rajkot_new_list_left = [];
        this.rajkot_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getSaurashtraNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getSaurashtraNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].saurashtra_news_position == 'right') {
              this.saurashtra_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.saurashtra_new_list_right.length; i++) {
                var video_data = this.saurashtra_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.saurashtra_new_list_right[i]['video_available'] = true;
                    delete this.saurashtra_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.saurashtra_new_list_right[i]['video_available'] = true;
                    delete this.saurashtra_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.saurashtra_new_list_right[i]['video_available'] = true;
                    delete this.saurashtra_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.saurashtra_new_list_right[i]['video_available'] = true;
                    delete this.saurashtra_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.saurashtra_new_list_right[i]['video_available'] = false;
                    delete this.saurashtra_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.saurashtra_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.saurashtra_new_list_left.length; i++) {
                var video_data = this.saurashtra_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.saurashtra_new_list_left[i]['video_available'] = true;
                    delete this.saurashtra_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.saurashtra_new_list_left[i]['video_available'] = true;
                    delete this.saurashtra_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.saurashtra_new_list_left[i]['video_available'] = true;
                    delete this.saurashtra_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.saurashtra_new_list_left[i]['video_available'] = true;
                    delete this.saurashtra_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.saurashtra_new_list_left[i]['video_available'] = false;
                    delete this.saurashtra_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.saurashtra_new_list_left = [];
        this.saurashtra_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getSportsNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getSportsNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].sports_news_position == 'right') {
              this.sports_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.sports_new_list_right.length; i++) {
                var video_data = this.sports_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.sports_new_list_right[i]['video_available'] = true;
                    delete this.sports_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.sports_new_list_right[i]['video_available'] = true;
                    delete this.sports_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.sports_new_list_right[i]['video_available'] = true;
                    delete this.sports_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.sports_new_list_right[i]['video_available'] = true;
                    delete this.sports_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.sports_new_list_right[i]['video_available'] = false;
                    delete this.sports_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.sports_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.sports_new_list_left.length; i++) {
                var video_data = this.sports_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.sports_new_list_left[i]['video_available'] = true;
                    delete this.sports_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.sports_new_list_left[i]['video_available'] = true;
                    delete this.sports_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.sports_new_list_left[i]['video_available'] = true;
                    delete this.sports_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.sports_new_list_left[i]['video_available'] = true;
                    delete this.sports_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.sports_new_list_left[i]['video_available'] = false;
                    delete this.sports_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.sports_new_list_left = [];
        this.sports_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getBollywoodNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getBollywoodNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].bollywood_news_position == 'right') {
              this.bollywood_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.bollywood_new_list_right.length; i++) {
                var video_data = this.bollywood_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.bollywood_new_list_right[i]['video_available'] = true;
                    delete this.bollywood_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.bollywood_new_list_right[i]['video_available'] = true;
                    delete this.bollywood_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.bollywood_new_list_right[i]['video_available'] = true;
                    delete this.bollywood_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.bollywood_new_list_right[i]['video_available'] = true;
                    delete this.bollywood_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.bollywood_new_list_right[i]['video_available'] = false;
                    delete this.bollywood_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.bollywood_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.bollywood_new_list_left.length; i++) {
                var video_data = this.bollywood_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.bollywood_new_list_left[i]['video_available'] = true;
                    delete this.bollywood_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.bollywood_new_list_left[i]['video_available'] = true;
                    delete this.bollywood_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.bollywood_new_list_left[i]['video_available'] = true;
                    delete this.bollywood_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.bollywood_new_list_left[i]['video_available'] = true;
                    delete this.bollywood_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.bollywood_new_list_left[i]['video_available'] = false;
                    delete this.bollywood_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.bollywood_new_list_left = [];
        this.bollywood_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getInternationalNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getInternationalNewsHome(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var j = 0; j < news_data.length; j++) {
            if (news_data[j].international_news_position == 'right') {
              this.international_new_list_right.push(news_data[j]);
              for (var i = 0; i < this.international_new_list_right.length; i++) {
                var video_data = this.international_new_list_right[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.international_new_list_right[i]['video_available'] = true;
                    delete this.international_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.international_new_list_right[i]['video_available'] = true;
                    delete this.international_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.international_new_list_right[i]['video_available'] = true;
                    delete this.international_new_list_right[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.international_new_list_right[i]['video_available'] = true;
                    delete this.international_new_list_right[i]['video_detail'];
                    continue;
                  } else {
                    this.international_new_list_right[i]['video_available'] = false;
                    delete this.international_new_list_right[i]['video_detail'];
                    continue;
                  }
                }
              }
            } else {
              this.international_new_list_left.push(news_data[j]);
              for (var i = 0; i < this.international_new_list_left.length; i++) {
                var video_data = this.international_new_list_left[i]['video_detail'];
                if (video_data && video_data.length > 0) {
                  var tempVideoData = video_data[0]
                  if (tempVideoData.video_section_1 && tempVideoData.video_section_1.length > 0 && tempVideoData.video_section_1[0]['values'] != '') {
                    this.international_new_list_left[i]['video_available'] = true;
                    delete this.international_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_2 && tempVideoData.video_section_2.length > 0 && tempVideoData.video_section_2[0]['values'] != '') {
                    this.international_new_list_left[i]['video_available'] = true;
                    delete this.international_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_3 && tempVideoData.video_section_3.length > 0 && tempVideoData.video_section_3[0]['values'] != '') {
                    this.international_new_list_left[i]['video_available'] = true;
                    delete this.international_new_list_left[i]['video_detail'];
                    continue;
                  } else if (tempVideoData.video_section_4 && tempVideoData.video_section_4.length > 0 && tempVideoData.video_section_4[0]['values'] != '') {
                    this.international_new_list_left[i]['video_available'] = true;
                    delete this.international_new_list_left[i]['video_detail'];
                    continue;
                  } else {
                    this.international_new_list_left[i]['video_available'] = false;
                    delete this.international_new_list_left[i]['video_detail'];
                    continue;
                  }
                }
              }
            }
          }
        }
      } else {
        this.international_new_list_left = [];
        this.international_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  ngOnInit(): void {
  }
}
