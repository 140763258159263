import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ckeditor4-angular';
import { ShubhLagnostavRoutingModule } from './shubh-lagnostav-routing.module';
import { ShubhLagnostavAddComponent } from './shubh-lagnostav-add/shubh-lagnostav-add.component';
import { ShubhLagnostavListComponent } from './shubh-lagnostav-list/shubh-lagnostav-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [ShubhLagnostavAddComponent, ShubhLagnostavListComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    ShubhLagnostavRoutingModule
  ]
})
export class ShubhLagnostavModule { }
