<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="short_news_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Short News Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																News</button>
															<a href="javascript:void(0);" routerLink="/short-news-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-6 col-form-label">Select Type: </label>
										<div class="col-sm-6">
											<select class="form-control" name="video_types1[]"
												(change)="setVideoInput($event)">
												<option [selected]="newsType == 'Image'" value="Image">Image</option>
												<option [selected]="newsType == 'Video'" value="Video">Video</option>
											</select>
										</div>
									</div>
								</div>
								<input type="hidden" value="{{NewsData?.short_image}}" name="heading_image">
								<div class="form-group row" *ngIf="displayImage">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-6 col-form-label">Image: </label>
										<div class="col-sm-6">

											<input id="image1" type="file" class="form-control" name="file_upload1"
												type="file" accept='image/*' (change)="showImagePreview($event,1)">
											<img [src]="imageURL1" *ngIf="imageURL1"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<div class="form-group row" *ngIf="displayVideo">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-6 col-form-label">Video: </label>
										<div class="col-sm-6">
											<input type="hidden" value="{{NewsData?.short_video}}" name="heading_video">
											<input type="file" id="video_news" class="form-control" name="video_news"
												(change)="chechVideoExtension($event,i,1)">
											<video style="display:none;max-width:300px;margin-top:15px" controls
												id="video_player">
											</video>
											<img id="thumb" style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>

								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="push_notification" class="col-sm-6 col-form-label">Push Notification
											Message:
										</label>
										<div class="col-sm-6">
											<input class="form-control" type="text" value="" name="push_notification"
												id="push_notification">
										</div>
									</div>
								</div>
								<!--<div class="form-group row" *ngIf="result_guj_title1">
									<label class="col-form-label"> Gujarati Title:   </label>
									<div class="">
											<ng-ckeditor id="test" name="guj_title1" name="guj_title1" ng-config="config"></ng-ckeditor>
									</div>
								</div>-->
								<div class="form-group">
									<div>
										<input type="hidden" name="newsID" value="{{NewsData?._id}}">
										<input class="form-control-auto" type="hidden" name="news_date"
											value="{{news_date}}">
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save News</button>
										<a href="javascript:void(0);" routerLink="/short-news-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>