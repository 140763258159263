import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-cms-add',
  templateUrl: './cms-add.component.html',
  styleUrls: ['./cms-add.component.css']
})
export class CmsAddComponent implements OnInit {
  news_date = localStorage.getItem('EntryDate');
  NewsData: any = [];
  news_id: any = 0;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  content1: string;
  log: string = '';
  @ViewChild("myckeditor") ckeditor: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.route.params.subscribe(params => this.news_id = params.news_id);
    var param = { news_id: this.news_id };
    if (this.news_id) {
      this.CommonService.getCMSDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['update_data']
          this.content1 = this.NewsData.content;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }
  }
  onChange($event: any): void {
    console.log("onChange");
    //this.log += new Date() + "<br />";
  }
  onPaste($event: any): void {
    console.log("onPaste");
    //this.log += new Date() + "<br />";
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        //$('.btn-success').attr('disabled', true);
        var form = $("#cms_form");
        var fd = new FormData(form[0]);
        fd.append('content', th.content1);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.CmsBaseUrl + 'update_cms',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/cms-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  ngOnInit(): void {
    this.ckeConfig = {
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'basicstyles', groups: ['basicstyles'] },
        {
          name: 'paragraph',
          groups: ['list', 'align', 'paragraph']
        },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
      ],
      extraPlugins:
        'basicstyles,bidi,blockquote,clipboard,' +
        'button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,' +
        'contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,' +
        'filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,' +
        'font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,' +
        'indentblock,indentlist,justify,link,list,liststyle,magicline,' +
        'maximize,pagebreak,pastefromword,pastetext,' +
        'resize,menubutton,scayt,selectall,showblocks,' +
        'showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,' +
        'tabletools,templates,toolbar,undo,wysiwygarea',
    };
  }
}
