<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/tithi-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Tithi</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<!-- <div class="col-md-12 row m-b-10" >
								<div class="col-md-3">
									<select class="form-control" >
										<option value="gtitle">Gujarati Title</option>
										<option value="etitle">English Title</option>
										<option value="gline1">Gred Line-1</option>
										<option value="gline2">Gred Line-2</option>
										<option value="content1">Content-1</option>
										<option value="content2">Content-2</option>
										<option value="meta_title">Meta Title</option>
										<option value="meta_description">Meta Description</option>
										<option value="meta_keyword">Meta Keyword</option>
										<option value="display_order">Display Order</option>
										<option value="date">Date (dd-mm-yyyy)</option>
									</select>
								</div>
								<div class="col-md-3" >
									<input type="text" ng-model="FilterTitle" placeholder="Enter Title" class="form-control">
								</div>


								<div class="col-md-1">
									<button class="btn btn-primary"  title="Update news entry date">Search</button>
                                </div>

                                <div class="col-md-1">
									<button class="btn btn-primary"  title="Update news entry date">Reset</button>
								</div>
							</div> -->

							<table class="table">
								<thead>
									<tr>
										<!-- <th><button *ngIf="show_multiple_delete_btn" class="btn btn-outline-danger waves-effect waves-light" (click)="delete_multiple_news()"><i class="fa fa-trash"></i></button></th> -->
										<th>Date</th>
										<th>Tithi</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of TithiList">
										<!-- <td ><input type="checkbox"  (change)="add_delete_checkbox($event,mn._id)"></td> -->
										<td><a routerLink="/tithi-edit/{{mn._id}}">{{mn.date}}</a></td>
										<td><a routerLink="/tithi-edit/{{mn._id}}">{{mn.tithi}}</a></td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>