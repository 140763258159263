import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdvertisementRoutingModule } from './advertisement-routing.module';
import { AdvertiserAddComponent } from './advertiser-add/advertiser-add.component';
import { AdvertiserListComponent } from './advertiser-list/advertiser-list.component';
import { AdvertiseAddComponent } from './advertise-add/advertise-add.component';
import { AdvertiseListComponent } from './advertise-list/advertise-list.component';
@NgModule({
  declarations: [AdvertiserAddComponent, AdvertiserListComponent, AdvertiseAddComponent, AdvertiseListComponent],
  imports: [
    CommonModule,
    AdvertisementRoutingModule
  ]
})
export class AdvertisementModule { }
