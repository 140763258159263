<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="cms_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Manage Admin Group</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button (click)="submit_form()"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                            </button>
                                                            <a href="javascript:void(0);" routerLink="/admin-group-list"
                                                                type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Group Name: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="title" value="{{NewsData?.title}}" id="title">
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="newsID" value="{{NewsData?._id}}">
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Modules: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" multiple name="modules" [(ngModel)]="modules">
                                                <option value="Cms Page">Cms Page</option>
                                                <option value="Tithi Calender">Tithi Calender</option>
                                                <option value="Fatafat News">Fatafat News</option>
                                                <option value="Bollywood News">Bollywood News</option>
                                                <option value="Gujarat News">Gujarat News</option>
                                                <option value="International News">International News</option>
                                                <option value="Main News">Main News</option>
                                                <option value="NRI News">NRI News</option>
                                                <option value="Rajkot News">Rajkot News</option>
                                                <option value="Saurashtra News">Saurashtra News</option>
                                                <option value="Sports News">Sports News</option>
                                                <option value="Advertiser">Advertiser</option>
                                                <option value="Advertise">Advertise</option>
                                                <option value="Short News">Short News</option>
                                                <option value="E-Paper Category">E-Paper Category</option>
                                                <option value="E-Paper">E-Paper</option>
                                                <option value="Update Edition">Update Edition</option>
                                                <option value="Vividh Vibhag Title">Vividh Vibhag Title</option>
                                                <option value="Vividh Vibhag">Vividh Vibhag</option>
                                                <option value="Dynamic Section">Dynamic Section</option>
                                                <option value="General Notice">General Notice</option>
                                                <option value="General Notification">General Notification</option>
                                                <option value="Kasumbo">Kasumbo</option>
                                                <option value="Aaj na subh divse">Aaj na subh divse</option>
                                                <option value="Birthday">Birthday</option>
                                                <option value="Shubh Lagnotsav">Shubh Lagnotsav</option>
                                                <option value="Festival photos">Festival photos</option>
                                                <option value="Images">Images</option>
                                                <option value="Obituary">Obituary</option>
                                                <option value="Opinion Poll">Opinion Poll</option>
                                                <option value="Panchang">Panchang</option>
                                                <option value="Photo Gallery">Photo Gallery</option>
                                                <option value="Rashifal">Rashifal</option>
                                                <option value="Tantri sthanethi">Tantri sthanethi</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save Group</button>
                                        <a href="javascript:void(0);" routerLink="/admin-group-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>