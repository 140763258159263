<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#ffffff" type="ball-clip-rotate" [fullScreen]="true">
</ngx-spinner>
<div>
	<header id="topnav">
		<div class="topbar-main">
			<div class="container">
				<div class="logo text-center">
					<a href="#/home" class="logo">
						<img src="assets/img/logo.png" alt="" class="logo-small">
						<img src="assets/img/logo.png" alt="" class="logo-large" style="width:95px;">
					</a>
				</div>
				<div class="clearfix"></div>
			</div>
		</div>
		<div class="container navcontainer">
			<h1 class="bg_title">Akila News <span><a href="http://www.akilanews.com/">
						http://www.akilanews.com/</a></span> <span style="float:right;"><a href="javascript:void(0);"
						(click)="logout()">Logout</a></span></h1>
			<div class="navbar-custom">
				<div id="navigation">
					<!-- Navigation Menu-->
					<ul class="navigation-menu">
						<li *ngIf="(checkPermissionModule('Dashboard'))" class="has-submenu"><a
								href="index.html">Site</a>
							<ul class="submenu">
								<li><a routerLink="/dashboard">Dashboard</a></li>
								<li><a routerLink="/admin-group-list">Admin Group</a></li>
								<li><a routerLink="/admin-user-list">Admin User</a></li>

								<li><a routerLink="/change-password">Change Password</a></li>
							</ul>
						</li>
						<li *ngIf="(checkPermissionModule('Cms Page'))" class="has-submenu"><a
								href="javascript:void(0)">CMS Contents</a>
							<ul class="submenu">
								<li><a routerLink="/cms-list">List</a></li>
							</ul>
						</li>
						<li *ngIf="(checkPermissionModule('Tithi Calender'))" class="has-submenu"><a
								href="javascript:void(0)">Tithi Calender</a>
							<ul class="submenu">
								<li><a routerLink="/tithi-list">List</a></li>
								<li><a routerLink="/tithi-add">Add</a></li>
							</ul>
						</li>
						<li *ngIf="(checkPermissionModule('Fatafat News') || checkPermissionModule('Bollywood News') || checkPermissionModule('Gujarat News') || checkPermissionModule('International News') || checkPermissionModule('Main News') || checkPermissionModule('NRI News') || checkPermissionModule('Rajkot News') || checkPermissionModule('Saurashtra News') || checkPermissionModule('Sports News'))"
							class="has-submenu"><a href="javascript:void(0)">News</a>
							<ul class="submenu">
								<li *ngIf="(checkPermissionModule('Fatafat News'))"><a
										routerLink="/fatafat-news-list">Fatafat</a></li>
								<li *ngIf="(checkPermissionModule('Bollywood News'))"><a
										routerLink="/bollywood-news-list">Bollywood</a></li>
								<li *ngIf="(checkPermissionModule('Gujarat News'))"><a
										routerLink="/gujarat-news-list">Gujarat</a></li>
								<li *ngIf="(checkPermissionModule('International News'))"><a
										routerLink="/international-news-list">International</a></li>
								<li *ngIf="(checkPermissionModule('Main News'))"><a
										routerLink="/main-news-list">Main</a></li>
								<li *ngIf="(checkPermissionModule('NRI News'))"><a routerLink="/nri-news-list">NRI</a>
								</li>
								<li *ngIf="(checkPermissionModule('Rajkot News'))"><a
										routerLink="/rajkot-news-list">Rajkot</a></li>
								<li *ngIf="(checkPermissionModule('Saurashtra News'))"><a
										routerLink="/saurashtra-news-list">Saurashtra</a></li>
								<li *ngIf="(checkPermissionModule('Sports News'))"><a
										routerLink="/sports-news-list">Sports</a></li>
								<!-- <li><a href="">Audio</a></li>
								<li><a href="">Video</a></li> -->
							</ul>
						</li>
						<li *ngIf="(checkPermissionModule('Advertiser') || checkPermissionModule('Advertise'))"
							class="has-submenu"><a href="javascript:void(0)">Advertising</a>
							<ul class="submenu">
								<li *ngIf="(checkPermissionModule('Advertiser'))"><a
										routerLink="/advertiser-list">Advertiser</a></li>
								<li *ngIf="(checkPermissionModule('Advertise'))"><a
										routerLink="/advertise-list">Advertise</a></li>
							</ul>
						</li>
						<!-- <li *ngIf="(checkPermissionModule('Advertise'))"><a href="#">News Letter</a></li> -->
						<li *ngIf="(checkPermissionModule('Short News'))" class="has-submenu"><a
								href="javascript:void(0)">Short News</a>
							<ul class="submenu">
								<li><a routerLink="/short-news-list">News Management</a></li>
							</ul>
						</li>
						<li *ngIf="(checkPermissionModule('E-Paper Category') || checkPermissionModule('E-Paper'))"
							class="has-submenu"><a href="javascript:void(0)">E-paper</a>
							<ul class="submenu">
								<li *ngIf="(checkPermissionModule('E-Paper Category'))"><a
										routerLink="/category-list">Category</a></li>
								<!-- <li><a routerLink="/page-list">E-Paper Page</a></li> -->
								<li *ngIf="(checkPermissionModule('E-Paper'))"><a routerLink="/epaper-list">E-Paper</a>
								</li>
								<!-- <li><a routerLink="/epaper-merge">E-Paper Merge</a></li> -->
							</ul>
						</li>
						<!-- <li class="has-submenu"><a href="#">Citizen Journalism</a>
							<ul class="submenu">
								<li><a href="">Manage Journalism News</a></li>
							</ul>
						</li> -->
						<li class="has-submenu"><a href="javascript:void(0)">Miscellaneous</a>
							<ul class="submenu">
								<li *ngIf="(checkPermissionModule('Update Edition'))"><a
										routerLink="/update-edition">Update Edition</a></li>
								<li *ngIf="(checkPermissionModule('Vividh Vibhag Title') || checkPermissionModule('Vividh Vibhag'))"
									class="has-submenu"><a href="javascript:void(0)">Vividh Vibhag</a>
									<ul class="submenu">
										<li><a routerLink="/vividh-vibhag-title-list">Vividh Vibhag Title</a></li>
										<li><a routerLink="/vividh-vibhag-page-list">Vividh Vibhag Page</a></li>
									</ul>
								</li>
								<li *ngIf="(checkPermissionModule('Dynamic Section'))" class="has-submenu"><a
										href="javascript:void(0)">Dynamic Section</a>
									<ul class="submenu">
										<li><a routerLink="/dynamic-section-list">Dynamic Section Title</a></li>
										<!-- <li><a routerLink="/vividh-vibhag-page-list">Dynamic Section Page</a></li> -->
									</ul>
								</li>
								<!-- <li *ngIf="(checkPermissionModule('E-Paper'))" class="has-submenu"><a href="#">Score Section</a>
									<ul class="submenu">
										<li><a href="">Manage Section</a></li>
										<li><a href="">Manage Party</a></li>
										<li><a href="">Manage Result</a></li>
									</ul>
								</li> -->
								<li *ngIf="(checkPermissionModule('General Notice'))"><a
										routerLink="/notice-list">General
										Notice</a></li>
								<li *ngIf="(checkPermissionModule('General Notification'))"><a
										routerLink="/notification-add">General
										Notification</a></li>
								<li *ngIf="(checkPermissionModule('Kasumbo'))"><a routerLink="/kasumbo-list">Kasumbo</a>
								</li>
								<li *ngIf="(checkPermissionModule('Aaj na subh divse'))"><a
										routerLink="/shubh-divas-list">Aaj Na
										Subh Divse</a></li>
								<li *ngIf="(checkPermissionModule('Birthday'))"><a
										routerLink="/birthday-list">Birthday</a></li>
								<li *ngIf="(checkPermissionModule('Shubh Lagnotsav'))"><a
										routerLink="/shubh-lagnostav-list">Shubh Lagnotsav</a></li>
								<li *ngIf="(checkPermissionModule('Festival photos'))"><a
										routerLink="/festival-photo-list">Festival photos</a></li>
								<li *ngIf="(checkPermissionModule('Images'))"><a routerLink="/images-list">Images</a>
								</li>
								<li *ngIf="(checkPermissionModule('Obituary'))"><a
										routerLink="/obituary-list">Obituary</a></li>
								<li *ngIf="(checkPermissionModule('Opinion Poll'))"><a
										routerLink="/opinion-poll-list">Opinion Poll</a></li>
								<li *ngIf="(checkPermissionModule('Panchang'))"><a
										routerLink="/panchang-list">Panchang</a></li>
								<li *ngIf="(checkPermissionModule('Photo Gallery'))"><a
										routerLink="/photo-gallery-list">Photo
										Gallery</a></li>
								<li *ngIf="(checkPermissionModule('Rashifal'))"><a
										routerLink="/rashifal-list">Rashifal</a></li>
								<li *ngIf="(checkPermissionModule('Tantri sthanethi'))"><a
										routerLink="/tantri-list">Tantri
										sthanethi</a></li>
								<!-- <li class="has-submenu"><a href="#">Weather</a>
									<ul class="submenu">
										<li><a routerLink="/country-list">Country</a></li>
										<li><a routerLink="/weather-list">Weather</a></li>
									</ul>
								</li> -->
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
	<router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
<footer class="footer">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<p>
					Copyright © 2017-18 akilanews.com, All rights reserved.
					<br> Developed by:
					<a href="http://gteches.com/" target="_blank">Gtech</a>
				</p>
			</div>
		</div>
	</div>
</footer>