<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form name="form" [formGroup]="editionForm">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box">
												
												<h4 class="page-title">Today Edition</h4>
											</div>
										</div>
									</div>
								</div>
								
								<div class="form-group row">
									<label for="news_position" class="col-md-3 col-form-label">Edition: <span>*</span></label>
									<div class="col-md-6">
										<div class="form-check-inline my-1">
											<div class="custom-control custom-radio">
												<input value="yes" type="radio"  id="customRadio444" formControlName="edition_type" name="customRadio2" class="custom-control-input">
												<label class="custom-control-label" for="customRadio444">Enable</label>
											</div>
										</div>
										<div class="form-check-inline my-1">
											<div class="custom-control custom-radio">
												<input value="no" type="radio" id="customRadio555" formControlName="edition_type" name="customRadio2" class="custom-control-input">
												<label class="custom-control-label" for="customRadio555">Disable</label>
											</div>
										</div>
									</div>
								</div>
								
								
								<div class="form-group">
									<div>
									  <button type="submit" (click)="update_edition_form()" class="submit_btn btn btn-success waves-effect waves-light">Update</button>
									  
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>