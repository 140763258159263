import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanchangRoutingModule } from './panchang-routing.module';
import { PanchangAddComponent } from './panchang-add/panchang-add.component';
import { PanchangDayComponent } from './panchang-day/panchang-day.component';
import { PanchangEditComponent } from './panchang-edit/panchang-edit.component';
import { PanchangListComponent } from './panchang-list/panchang-list.component';
@NgModule({
  declarations: [PanchangAddComponent, PanchangDayComponent, PanchangEditComponent, PanchangListComponent],
  imports: [
    CommonModule,
    PanchangRoutingModule
  ]
})
export class PanchangModule { }
