<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/rashifal-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Rashifal</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<select class="form-control">
										<option value="gtitle">Gujarati Title</option>
									</select>
								</div>
								<div class="col-md-3">
									<input type="text" ng-model="FilterTitle" placeholder="Enter Title"
										class="form-control">
								</div>


								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Search</button>
								</div>

								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Reset</button>
								</div>
							</div>

							<table class="table">
								<thead>
									<tr>
										<th>Delete</th>
										<th>Month & Year</th>
										<th>Active ?</th>
									</tr>
								</thead>
								<tbody>
									<tr ng-repeat="mn in fatafatNewsList">
										<td><input type="checkbox" ng-model="fatafat_news_id"
												ng-change="add_delete_checkbox(fatafat_news_id,'1')"></td>
										<td>October 2020</td>

										<td><input type="checkbox" ng-model="fatafat_news_id"
												ng-change="add_delete_checkbox(fatafat_news_id,'1')"></td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>