import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DefaultComponent } from '../../layout/default/default.component';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { Category } from './category';
declare var $: any;
@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit {
  news_id: any = 0;
  CategoryData: Category;
  constructor(private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent) {
    this.route.params.subscribe(params => this.news_id = params.news_id);
    if (this.news_id) {
      var param = { news_id: this.news_id };
      this.CommonService.getCategoryDetil(param).subscribe((data) => {
        if (data['status']) {
          this.CategoryData = data['category_data']
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }
    else {
      this.CommonService.GetEcategoryLastDisplayOrder().subscribe((data) => {
        if (data.status) {
          $("#display_order").val(data.display_order);
          $("#oriDisplayOrder").val(0);
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  ngOnInit(): void {
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#category_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.EpaperBaseUrl + 'add_epaper_category',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/category-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
