<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/page-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Epaper Page</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<table class="table">
								<thead>
									<tr>
										<th><button class="btn btn-outline-danger waves-effect waves-light"
												*ngIf="show_multiple_delete_btn" (click)="delete_multiple_news()"><i
													class="fa fa-trash"></i></button></th>
										<th>Gujarati Title</th>
										<th>Active ?</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of PageList">
										<td><input type="checkbox" value={{mn._id}}
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td><a routerLink="/page-edit/{{mn._id}}" [innerHTML]="mn.gujarati_title"></a>
										</td>

										<td *ngIf="mn.status=='yes'"><input type="checkbox" checked name="cat_id"
												value={{mn._id}} (change)="toggleVisibility($event)"></td>
										<td *ngIf="mn.status=='no'"><input type="checkbox" name="cat_id"
												value={{mn._id}} (change)="toggleVisibility($event)"></td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>