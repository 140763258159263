<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/kasumbo-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Kasumbo</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<!-- <div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<input type="text" ng-model="FilterTitle" placeholder="Select Date"
										class="form-control">
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Search</button>
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Reset</button>
								</div>
							</div> -->
							<table class="table">
								<thead>
									<tr>
										<th><button *ngIf="show_multiple_delete_btn"
												class="btn btn-outline-danger waves-effect waves-light"
												(click)="delete_multiple_news()"><i class="fa fa-trash"></i></button>
										</th>
										<th>Gujarati Title</th>
										<th>Image</th>
										<th>Date</th>
										<th>Display Order</th>
										<th>Active?</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of kasumboList">
										<td class="text-center"><input type="checkbox"
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td>
											<a routerLink="/kasumbo-edit/{{mn._id}}" [innerHTML]="mn.title"></a>
										</td>
										<td><img src="{{image_path}}{{mn.image}}" style="width: 100px;"></td>
										<td><a routerLink="/kasumbo-edit/{{mn._id}}">{{mn.date}}</a></td>
										<td>{{mn.display_order}}</td>
										<td class="text-center">
											<input type="checkbox" [checked]="mn.status == 'yes'"
												(change)="update_news_status($event,mn._id)" value="no">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>