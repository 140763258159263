<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="orbituary_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Obituary Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Obituary</button>
															<a href="javascript:void(0);" routerLink="/obituary-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Display Order: </label>
										<div class="col-sm-8">
											<input type="hidden" name="newsID" value="{{NewsData?._id}}">
											<input type="hidden" name="oriDisplayOrder" id="oriDisplayOrder"
												value="{{NewsData?.display_order}}">
											<input class="form-control" data-validation="required number" type="text"
												value="{{NewsData?.display_order}}" name="display_order"
												id="display_order">
											<strong>Obituary with greater order will be displayed first. </strong>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Is Special?: </label>
										<div class="col-sm-8">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" type="radio" id="customRadio444"
														[checked]="NewsData?.is_special == 'yes'" name="is_special"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio444">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="no" type="radio" id="customRadio555" checked
														name="is_special" class="custom-control-input">
													<label class="custom-control-label" for="customRadio555">No</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Name: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="title" value="{{NewsData?.title}}" id="title">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-4 col-form-label">Image: </label>
										<div class="col-sm-8">
											<input type="hidden" name="ori_image_1" value="{{NewsData?.image}}">
											<input (change)="imageValidation($event,1)" id="image1" type="file"
												class="form-control" name="image_1" #file type="file" accept='image/*'>
											<img [src]="imageURL" *ngIf="imageURL"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-12 row">
										<label for="guj_title" class="col-sm-2 col-form-label">Content 1: </label>
										<div class="col-sm-10">
											<ckeditor name="content1" [(ngModel)]="content1" required
												[config]="ckeConfig" debounce="500" (paste)="onPaste($event)"
												(change)="onChange($event)">
											</ckeditor>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">News Date: </label>
										<div class="col-sm-8">
											<input class="form-control" readonly data-validation="required" type="text"
												name="news_date" value="{{news_date}}" id="news_date">
										</div>
									</div>
								</div>
								<div class="form-group">
									<div>
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save
											Obituary</button>
										<a href="javascript:void(0);" routerLink="/obituary-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>