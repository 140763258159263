<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="festival_photo_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Festival Photo Add</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button (click)="submit_form()"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                                Photo</button>
                                                            <a href="javascript:void(0);"
                                                                routerLink="/festival-photo-list" type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Title: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="title" value="{{NewsData?.title}}" id="title">
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="newsID" value="{{NewsData?._id}}">
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Start Date: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" autocomplete="off" data-validation="required"
                                                type="text" name="datepicker" value="{{NewsData?.start_date}}"
                                                id="datepicker">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">End Date: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" autocomplete="off" data-validation="required"
                                                type="text" name="datepicker1" value="{{NewsData?.end_date}}"
                                                id="datepicker1">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label for="image1" class="col-sm-4 col-form-label">Photo: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_1" value="{{NewsData?.image}}">
                                            <input (change)="imageValidation($event,1)" id="image1" type="file"
                                                class="form-control" name="image_1" #file type="file" accept='image/*'>
                                            <img [src]="imageURL" *ngIf="imageURL"
                                                style="max-width:300px;margin-top:15px">
                                            <p>Recommended Dimension: Width: 440px</p>
                                        </div>
                                    </div>
                                </div>
                                <input class="form-control-auto" type="hidden" name="news_date" value="{{news_date}}">
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save Photo</button>
                                        <a href="javascript:void(0);" routerLink="/festival-photo-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>