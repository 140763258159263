import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { ImagesAddComponent } from './images-add/images-add.component';
import { ImagesListComponent } from './images-list/images-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'images-add', component: ImagesAddComponent },
  { path: 'images-list', component: ImagesListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImagesRoutingModule { }
