import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { CategoryAddComponent } from './category-add/category-add.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { PageAddComponent } from './page-add/page-add.component';
import { PageListComponent } from './page-list/page-list.component';
import { PaperAddComponent } from './paper-add/paper-add.component';
import { PaperListComponent } from './paper-list/paper-list.component';
import { PaperEditComponent } from './paper-edit/paper-edit.component';
import { EpaperMergeComponent } from './epaper-merge/epaper-merge.component';
import { EpaperAddComponent } from './epaper-add/epaper-add.component';
import { EpaperListComponent } from './epaper-list/epaper-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'category-add', component: CategoryAddComponent },
  { path: 'category-list', component: CategoryListComponent },
  { path: 'category-edit/:news_id', component: CategoryAddComponent },
  { path: 'page-add', component: PageAddComponent },
  { path: 'page-list', component: PageListComponent },
  { path: 'page-edit/:news_id', component: PageAddComponent },
  { path: 'paper-add', component: PaperAddComponent },
  { path: 'paper-list', component: PaperListComponent },
  { path: 'paper-edit/:news_id', component: PaperEditComponent },
  { path: 'epaper-merge', component: EpaperMergeComponent },
  { path: 'epaper-add', component: EpaperAddComponent },
  { path: 'epaper-list', component: EpaperListComponent },
  { path: 'epaper-edit/:news_id', component: EpaperAddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EpaperRoutingModule { }
