import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DefaultComponent} from './../layout/default/default.component';
import {UpdateEditionComponent} from './update-edition/update-edition.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{path: 'update-edition', component: UpdateEditionComponent}
            ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdateEditionRoutingModule { }
