import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TithiRoutingModule } from './tithi-routing.module';
import { TithiAddComponent } from './tithi-add/tithi-add.component';
import { TithiListComponent } from './tithi-list/tithi-list.component';
import { TithiDayComponent } from './tithi-day/tithi-day.component';
import { TithiEditComponent } from './tithi-edit/tithi-edit.component';
@NgModule({
  declarations: [TithiAddComponent, TithiListComponent, TithiDayComponent, TithiEditComponent],
  imports: [
    CommonModule,
    TithiRoutingModule
  ]
})
export class TithiModule { }
