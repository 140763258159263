import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { EncryptionService } from '../../encryption.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.css']
})
export class NewsPreviewComponent implements OnInit {
  section = '';
  public today_date = localStorage.getItem('EntryDate')
  public sports_new_list_left = [];
  public sports_new_list_right = [];
  public sports_new_list_left_2 = [];
  public sports_new_list_right_2 = [];
  public news_image_url = environment.baseUrl;
  public saurashtra_new_list_left = [];
  public saurashtra_new_list_right = [];
  public saurashtra_new_list_left_2 = [];
  public saurashtra_new_list_right_2 = [];
  public rajkot_new_list_left = [];
  public rajkot_new_list_right = [];
  public rajkot_new_list_left_2 = [];
  public rajkot_new_list_right_2 = [];
  public main_new_list_left = [];
  public main_new_list_right = [];
  public main_new_list_left_2 = [];
  public main_new_list_right_2 = [];
  public international_new_list_left = [];
  public international_new_list_right = [];
  public international_new_list_left_2 = [];
  public international_new_list_right_2 = [];
  public gujarat_new_list_left = [];
  public gujarat_new_list_right = [];
  public gujarat_new_list_left_2 = [];
  public gujarat_new_list_right_2 = [];
  public bollywood_new_list_left = [];
  public bollywood_new_list_right = [];
  public bollywood_new_list_left_2 = [];
  public bollywood_new_list_right_2 = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private CommonService: CommonService,
    private EncryptionService: EncryptionService,
  ) {
    this.section = this.route.snapshot.paramMap.get('section')
    //this.today_date = this.route.snapshot.paramMap.get('date')
    if (this.section == 'main') {
      this.getMainNews();
    } else if (this.section == 'gujarat') {
      this.getgujaratNews();
    } else if (this.section == 'rajkot') {
      this.getrajkotNews();
    } else if (this.section == 'saurashtra') {
      this.getsaurashtraNews();
    } else if (this.section == 'international') {
      this.getinternationalNews();
    } else if (this.section == 'sports') {
      this.getsportsNews();
    } else if (this.section == 'bollywood') {
      this.getbollywoodNews();
    }
  }
  getrajkotNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getRajkotNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].rajkot_news_position == 'right') {
              this.rajkot_new_list_right.push(news_data[i]);
            } else {
              this.rajkot_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].rajkot_news_position == 'right') {
              this.rajkot_new_list_right_2.push(news_data2[i]);
            } else {
              this.rajkot_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.rajkot_new_list_left = [];
        this.rajkot_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getMainNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getMainNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].main_news_position == 'right') {
              this.main_new_list_right.push(news_data[i]);
            } else {
              this.main_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].main_news_position == 'right') {
              this.main_new_list_right_2.push(news_data2[i]);
            } else {
              this.main_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.main_new_list_left = [];
        this.main_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getinternationalNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getInternationalNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].international_news_position == 'right') {
              this.international_new_list_right.push(news_data[i]);
            } else {
              this.international_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].international_news_position == 'right') {
              this.international_new_list_right_2.push(news_data2[i]);
            } else {
              this.international_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.international_new_list_left = [];
        this.international_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getgujaratNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getGujaratNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].gujarat_news_position == 'right') {
              this.gujarat_new_list_right.push(news_data[i]);
            } else {
              this.gujarat_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].gujarat_news_position == 'right') {
              this.gujarat_new_list_right_2.push(news_data2[i]);
            } else {
              this.gujarat_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.gujarat_new_list_left = [];
        this.gujarat_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getbollywoodNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getBollywoodNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].bollywood_news_position == 'right') {
              this.bollywood_new_list_right.push(news_data[i]);
            } else {
              this.bollywood_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].bollywood_news_position == 'right') {
              this.bollywood_new_list_right_2.push(news_data2[i]);
            } else {
              this.bollywood_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.bollywood_new_list_left = [];
        this.bollywood_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getsaurashtraNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getSaurashtraNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].saurashtra_news_position == 'right') {
              this.saurashtra_new_list_right.push(news_data[i]);
            } else {
              this.saurashtra_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].saurashtra_news_position == 'right') {
              this.saurashtra_new_list_right_2.push(news_data2[i]);
            } else {
              this.saurashtra_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.saurashtra_new_list_left = [];
        this.saurashtra_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  getsportsNews() {
    var parameter = { "news_date": this.today_date, "platform": 'admin' }
    this.CommonService.getSportsNewsList(parameter).subscribe((res: any) => {
      var dec_data = this.EncryptionService.decrypt_data(res.data)
      if (dec_data) {
        var data = JSON.parse(dec_data)
        if (data.status) {
          var news_data = data.news_list;
          for (var i = 0; i < news_data.length; i++) {
            if (news_data[i].sports_news_position == 'right') {
              this.sports_new_list_right.push(news_data[i]);
            } else {
              this.sports_new_list_left.push(news_data[i]);
            }
          }
          var news_data2 = data.news_list_home;
          for (var i = 0; i < news_data2.length; i++) {
            if (news_data2[i].sports_news_position == 'right') {
              this.sports_new_list_right_2.push(news_data2[i]);
            } else {
              this.sports_new_list_left_2.push(news_data2[i]);
            }
          }
        }
      } else {
        this.sports_new_list_left = [];
        this.sports_new_list_right = [];
      }
    }, (err: any) => {
      console.error('Error:-', err);
    });
  }
  ngOnInit(): void {
  }
}
