<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="images_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Images Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button type="submit"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Images</button>
															<a href="javascript:void(0);" routerLink="/images-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-4 col-form-label">Image: </label>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image_1">
											<input id="image1" type="file" class="form-control" name="file_upload1"
												#file type="file" accept='image/*'>
											<img style="width:200px;margin-top:15px">

										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Link: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="link" value="" id="link">
										</div>
									</div>

								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-4 col-form-label">PDF: </label>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image_1">
											<input id="image1" type="file" class="form-control" name="file_upload1"
												#file type="file" accept='image/*'>
											<img style="width:200px;margin-top:15px">

										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Display Order: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="link" value="" id="link">
										</div>
									</div>

								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-6 col-form-label">Active?: </label>
										<div class="col-sm-6">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" type="radio" id="customRadio444" name="active"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio444">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="no" type="radio" id="customRadio555" checked
														name="active" class="custom-control-input">
													<label class="custom-control-label" for="customRadio555">No</label>
												</div>
											</div>
										</div>
									</div>
								</div>


								<input class="form-control-auto" type="hidden" name="news_date" value="">
								<div class="form-group">
									<div>
										<button type="submit"
											class="btn btn-success waves-effect waves-light m-r-5">Save Images</button>
										<a href="javascript:void(0);" routerLink="/images-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>