import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { KasumboAddComponent } from './kasumbo-add/kasumbo-add.component';
import { KasumboListComponent } from './kasumbo-list/kasumbo-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'kasumbo-add', component: KasumboAddComponent },
  { path: 'kasumbo-list', component: KasumboListComponent },
  { path: 'kasumbo-edit/:news_id', component: KasumboAddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KasumboRoutingModule { }
