import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RashifalRoutingModule } from './rashifal-routing.module';
import { RashifalAddComponent } from './rashifal-add/rashifal-add.component';
import { RashifalListComponent } from './rashifal-list/rashifal-list.component';
@NgModule({
  declarations: [RashifalAddComponent, RashifalListComponent],
  imports: [
    CommonModule,
    RashifalRoutingModule
  ]
})
export class RashifalModule { }
