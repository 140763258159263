import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { CountryAddComponent } from './country-add/country-add.component';
import { CountryListComponent } from './country-list/country-list.component';
import { WeatherAddComponent } from './weather-add/weather-add.component';
import { WeatherListComponent } from './weather-list/weather-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'country-add', component: CountryAddComponent },
  { path: 'country-list', component: CountryListComponent },
  { path: 'weather-list', component: WeatherListComponent },
  { path: 'weather-add', component: WeatherAddComponent }]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WeatherRoutingModule { }
