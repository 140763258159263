import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [
    { path: 'change-password', component: ChangePasswordComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChangePasswordRoutingModule { }
