import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {
  loginIsAdmin: any = localStorage.getItem('login_is_admin');
  permissionModuleArray: any = localStorage.getItem('login_module_array');
  constructor(private router: Router,
    public CommonService: CommonService,
    private spinner: NgxSpinnerService) {
  }
  ngOnInit(): void {
  }
  onActivate(event) {
    if (!localStorage.getItem('login_token')) {
      this.router.navigate(['/']);
    }
    this.loaderShow();
    this.CommonService.GetNewsEntryDate().subscribe((data) => {
      if (data.status) {
        localStorage.setItem('EntryDate', data.date);
      } else {
        localStorage.removeItem('login_token');
        this.router.navigate(['/']);
      }
      this.loaderHide();
    });
    $("html").animate({ scrollTop: 0 }, "slow");
  }
  checkPermissionModule(data) {
    if (this.loginIsAdmin != 'y') {
      if (this.permissionModuleArray.indexOf(data) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  loaderShow() {
    this.spinner.show();
  }
  loaderHide() {
    this.spinner.hide();
  }
  logout() {
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_name');
    localStorage.removeItem('login_id');
    localStorage.removeItem('login_is_admin');
    localStorage.removeItem('login_module_array');
    localStorage.removeItem('EntryDate');
    this.router.navigate(['/']);
  }
}
