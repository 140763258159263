import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-images-add',
  templateUrl: './images-add.component.html',
  styleUrls: ['./images-add.component.css']
})
export class ImagesAddComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
