import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FullComponent } from './layout/full/full.component';
import { DefaultComponent } from './layout/default/default.component';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { NewsModule } from './news/news.module';
import { EpaperModule } from './epaper/epaper.module';
import { TithiModule } from './tithi/tithi.module';
import { CmsModule } from './cms/cms.module';
import { UpdateEditionModule } from './update-edition/update-edition.module';
import { VividhVibhagModule } from './vividh-vibhag/vividh-vibhag.module';
import { KasumboModule } from './kasumbo/kasumbo.module';
import { ShubhDivasModule } from './shubh-divas/shubh-divas.module';
import { AdvertisementModule } from './advertisement/advertisement.module';
import { TantriModule } from './tantri/tantri.module';
import { RashifalModule } from './rashifal/rashifal.module';
import { PhotoGalleryModule } from './photo-gallery/photo-gallery.module';
import { PanchangModule } from './panchang/panchang.module';
import { OpinionPollModule } from './opinion-poll/opinion-poll.module';
import { FestivalPhotosModule } from './festival-photos/festival-photos.module';
import { ImagesModule } from './images/images.module';
import { ObituaryModule } from './obituary/obituary.module';
import { WeatherModule } from './weather/weather.module';
import { BirthdayModule } from './birthday/birthday.module';
import { ShubhLagnostavModule } from './shubh-lagnostav/shubh-lagnostav.module';
import { NoticeModule } from './notice/notice.module';
import { DynamicSectionModule } from './dynamic-section/dynamic-section.module';
import { NotificationModule } from './notification/notification.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { AdminGroupModule } from './admin-group/admin-group.module';
import { AdminUserModule } from './admin-user/admin-user.module';
import { HomeNewsPreviewModule } from './home-news-preview/home-news-preview.module';
import { InnerNewsPreviewModule } from './inner-news-preview/inner-news-preview.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    DefaultComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LoginModule,
    DashboardModule,
    NewsModule,
    CmsModule,
    EpaperModule,
    TithiModule,
    KasumboModule,
    ShubhDivasModule,
    PhotoGalleryModule,
    PanchangModule,
    OpinionPollModule,
    AdvertisementModule,
    TantriModule,
    RashifalModule,
    DynamicSectionModule,
    FestivalPhotosModule,
    ImagesModule,
    ObituaryModule,
    WeatherModule,
    BirthdayModule,
    ShubhLagnostavModule,
    NoticeModule,
    NotificationModule,
    UpdateEditionModule,
    VividhVibhagModule,
    ChangePasswordModule,
    AdminGroupModule,
    AdminUserModule,
    HomeNewsPreviewModule,
    InnerNewsPreviewModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: 'toast-top-right',
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      tapToDismiss: true,
      progressAnimation: 'increasing',
      maxOpened: 1,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
