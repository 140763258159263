import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ckeditor4-angular';
import { PhotoGalleryRoutingModule } from './photo-gallery-routing.module';
import { PhotoGalleryAddComponent } from './photo-gallery-add/photo-gallery-add.component';
import { PhotoGalleryListComponent } from './photo-gallery-list/photo-gallery-list.component';
@NgModule({
  declarations: [PhotoGalleryAddComponent, PhotoGalleryListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    PhotoGalleryRoutingModule
  ]
})
export class PhotoGalleryModule { }
