<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="epaper_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Epaper</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button (click)="submit_form()"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                                Page</button>
                                                            <a href="javascript:void(0);" routerLink="/paper-list"
                                                                type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Category: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" id="epaper_category" name="epaper_category"
                                                (change)="get_page_status(0)">
                                                <option *ngFor="let ec of EpaperCategory" value="{{ec._id}}">
                                                    {{ec.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Date: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" value="{{news_date}}" data-validation="required"
                                                type="text" readonly name="sdate" value="" id="sdate">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Page: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control page_1" page="page" name="page_1" id="page_1"
                                                (change)="get_page_status(1)">
                                                <option value="">Please Select</option>
                                                <option *ngFor="let ep of EpaperPage_1" value="{{ep._id}}">
                                                    {{ep.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="checkbox my-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input page_1"
                                                    (change)="enable_disable_page($event,1)" name="group_1"
                                                    id="customCheck1">
                                                <label class="custom-control-label" for="customCheck1"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_1" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_1">
                                            <input type="file" id="image_1" class="form-control page_1" name="image_1"
                                                accept="image/*" (change)="imageValidation($event,1)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small><br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_1" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_1">
                                            <input type="file" id="pdf_1" class="form-control page_1" name="pdf_1"
                                                accept="application/pdf" (change)="pdfValidation($event,1)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Page: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control page_2" page="page" name="page_2" id="page_2"
                                                (change)="get_page_status(2)">
                                                <option value="">Please Select</option>
                                                <option *ngFor="let ep of EpaperPage_2" value="{{ep._id}}">
                                                    {{ep.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="checkbox my-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input page_2"
                                                    (change)="enable_disable_page($event,2)" name="group_2"
                                                    id="customCheck2">
                                                <label class="custom-control-label" for="customCheck2"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_2" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_2">
                                            <input type="file" id="image_2" class="form-control page_2" name="image_2"
                                                accept="image/*" (change)="imageValidation($event,2)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small><br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_2" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_2">
                                            <input type="file" id="pdf_2" class="form-control page_2" name="pdf_2"
                                                accept="application/pdf" (change)="pdfValidation($event,2)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Page: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control page_3" page="page" name="page_3" id="page_3"
                                                (change)="get_page_status(3)">
                                                <option value="">Please Select</option>
                                                <option *ngFor="let ep of EpaperPage_3" value="{{ep._id}}">
                                                    {{ep.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="checkbox my-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input page_3"
                                                    (change)="enable_disable_page($event,3)" name="group_3"
                                                    id="customCheck3">
                                                <label class="custom-control-label" for="customCheck3"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_3" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_3">
                                            <input type="file" id="image_3" class="form-control page_3" name="image_3"
                                                accept="image/*" (change)="imageValidation($event,3)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small> <br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_3" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_3">
                                            <input type="file" id="pdf_3" class="form-control page_3" name="pdf_3"
                                                accept="application/pdf" (change)="pdfValidation($event,3)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Page: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control page_4" page="page" name="page_4" id="page_4"
                                                (change)="get_page_status(4)">
                                                <option value="">Please Select</option>
                                                <option *ngFor="let ep of EpaperPage_4" value="{{ep._id}}">
                                                    {{ep.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="checkbox my-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input page_4"
                                                    (change)="enable_disable_page($event,4)" name="group_4"
                                                    id="customCheck4">
                                                <label class="custom-control-label" for="customCheck4"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_4" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_4">
                                            <input type="file" id="image_4" class="form-control page_4" name="image_4"
                                                accept="image/*" (change)="imageValidation($event,4)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small> <br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_4" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_4">
                                            <input type="file" id="pdf_4" class="form-control page_4" name="pdf_4"
                                                accept="application/pdf" (change)="pdfValidation($event,4)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Page: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control page_5" page="page" name="page_5" id="page_5"
                                                (change)="get_page_status(5)">
                                                <option value="">Please Select</option>
                                                <option *ngFor="let ep of EpaperPage_5" value="{{ep._id}}">
                                                    {{ep.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6 row">
                                        <div class="checkbox my-2">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input page_5"
                                                    name="title_setting" id="customCheck5">
                                                <label class="custom-control-label" for="customCheck5"></label>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_5" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_5">
                                            <input type="file" id="image_5" class="form-control page_5" name="image_5"
                                                accept="image/*" (change)="imageValidation($event,5)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small> <br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_5" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_5">
                                            <input type="file" id="pdf_5" class="form-control page_5" name="pdf_5"
                                                accept="application/pdf" (change)="pdfValidation($event,5)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save Page</button>
                                        <a href="javascript:void(0);" routerLink="/paper-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>