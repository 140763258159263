import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { AdvertiserAddComponent } from './advertiser-add/advertiser-add.component';
import { AdvertiserListComponent } from './advertiser-list/advertiser-list.component';
import { AdvertiseAddComponent } from './advertise-add/advertise-add.component';
import { AdvertiseListComponent } from './advertise-list/advertise-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'advertiser-add', component: AdvertiserAddComponent },
  { path: 'advertiser-list', component: AdvertiserListComponent },
  { path: 'advertiser-edit/:news_id', component: AdvertiserAddComponent },
  { path: 'advertise-list', component: AdvertiseListComponent },
  { path: 'advertise-add', component: AdvertiseAddComponent },
  { path: 'advertise-edit/:news_id', component: AdvertiseAddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvertisementRoutingModule { }
