import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InnerNewsPreviewRoutingModule } from './inner-news-preview-routing.module';
import { NewsPreviewComponent } from './news-preview/news-preview.component';
import { DomSanitizer } from '@angular/platform-browser'
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
@NgModule({
  declarations: [NewsPreviewComponent, SafeHtmlPipe],
  imports: [
    CommonModule,
    InnerNewsPreviewRoutingModule
  ]
})
export class InnerNewsPreviewModule { }
