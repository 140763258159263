<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="cms_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Admin User</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Admin Group: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" name="group_id">
                                                <option selected value="">Select Admin Group</option>
                                                <option *ngFor="let vvtl of groupList"
                                                    [selected]="NewsData?.group_id == vvtl._id" value="{{vvtl._id}}">
                                                    {{vvtl.title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Username: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="username" value="{{NewsData?.user_name}}" id="username">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Password: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="password" value="{{NewsData?.user_password}}" id="password">
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="newsID" value="{{NewsData?._id}}">

                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save User</button>
                                        <a href="javascript:void(0);" routerLink="/admin-user-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>