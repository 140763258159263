import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WeatherRoutingModule } from './weather-routing.module';
import { CountryAddComponent } from './country-add/country-add.component';
import { CountryListComponent } from './country-list/country-list.component';
import { WeatherListComponent } from './weather-list/weather-list.component';
import { WeatherAddComponent } from './weather-add/weather-add.component';
@NgModule({
  declarations: [CountryAddComponent, CountryListComponent, WeatherListComponent, WeatherAddComponent],
  imports: [
    CommonModule,
    WeatherRoutingModule
  ]
})
export class WeatherModule { }
