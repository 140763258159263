import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
@Component({
  selector: 'app-advertise-list',
  templateUrl: './advertise-list.component.html',
  styleUrls: ['./advertise-list.component.css']
})
export class AdvertiseListComponent implements OnInit {
  AdvertiseList = [];
  news_delete_checkbox = [];
  show_multiple_delete_btn = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_advertise_list();
  }
  ngOnInit(): void {
  }
  get_advertise_list() {
    this.DefaultComponent.loaderShow();
    this.CommonService.GetAdvertise().subscribe((data) => {
      if (data.status) {
        var tempData = data.list;
        for (var i = 0; i < tempData.length; i++) {
          var adLocation = tempData[i].location;
          if (adLocation == 'ear-left') {
            tempData[i].location = 'Ear Panel Banner Left';
          } else if (adLocation == 'ear-right') {
            tempData[i].location = 'Ear Panel Banner Right';
          } else if (adLocation == 'top') {
            tempData[i].location = 'TopUpper Panel Banner';
          } else if (adLocation == 'bottom') {
            tempData[i].location = 'MainNews Panel Banner';
          } else if (adLocation == 'fatafat') {
            tempData[i].location = 'Fatafat News Panel Banner';
          } else if (adLocation == 'right') {
            tempData[i].location = 'Inside Page Rectangle';
          } else if (adLocation == 'home-middle-right') {
            tempData[i].location = 'Bottom Tall Banner';
          } else if (adLocation == 'Gujarat News Banner') {
            tempData[i].location = 'Gujarat News Banner';
          } else if (adLocation == 'Rajkot News Banner') {
            tempData[i].location = 'Rajkot News Banner';
          } else if (adLocation == 'Saurashtra-Kutch News Banner') {
            tempData[i].location = 'Saurashtra-Kutch News Banner';
          } else if (adLocation == 'International-News-Banner') {
            tempData[i].location = 'International News Banner';
          } else if (adLocation == 'Sports News Banner') {
            tempData[i].location = 'Sports News Banner';
          } else if (adLocation == 'Entertainment News Banner') {
            tempData[i].location = 'Entertainment News Banner';
          } else if (adLocation == 'main-news-inner-page') {
            tempData[i].location = 'Inside Page News';
          } else if (adLocation == 'gutter_ads') {
            tempData[i].location = 'Gutter Ads';
          }
        }
        this.AdvertiseList = data.list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_advertise', 'prefix': 'advertise' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_advertise', "prefix": "advertise" };
    this.CommonService.DeleteAdvertise(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message);
        this.news_delete_checkbox = [];
        th.get_advertise_list();
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_display_orders(e, id, ori_ord) {
    if (parseInt(ori_ord) != parseInt(e.target.value)) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'display_order': e.target.value, 'id': id, 'ori_display_order': ori_ord, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_advertise', 'prefix': 'advertise' };
      this.CommonService.UpdateAdvertiseDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_advertise_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
}
