<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="page_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Epaper Page</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button type="submit" (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Page</button>
															<a href="javascript:void(0);" routerLink="/page-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Gujarati Title: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="gujarati_title" value="{{PageData?.gujarati_title}}"
												id="gujarati_title">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Display Order: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="display_order" value="{{PageData?.display_order}}"
												id="display_order">
										</div>
									</div>
								</div>
								<div class="form-group">
									<div>

										<input type="hidden" name="CategoryID" value="{{PageData?._id}}">
										<button type="submit" (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save Page</button>
										<a href="javascript:void(0);" routerLink="/page-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>