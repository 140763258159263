import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KasumboRoutingModule } from './kasumbo-routing.module';
import { KasumboAddComponent } from './kasumbo-add/kasumbo-add.component';
import { KasumboListComponent } from './kasumbo-list/kasumbo-list.component';
import { CKEditorModule } from 'ckeditor4-angular';
@NgModule({
  declarations: [KasumboAddComponent, KasumboListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    KasumboRoutingModule
  ]
})
export class KasumboModule { }
