import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { Advertise } from './advertise';
declare var $: any;
@Component({
  selector: 'app-advertise-add',
  templateUrl: './advertise-add.component.html',
  styleUrls: ['./advertise-add.component.css']
})
export class AdvertiseAddComponent implements OnInit {
  AdvertiserList: [];
  AdvertiseData: Advertise;
  news_id: any = 0;
  pdfURL: string;
  NewsImageURL: string;
  imageURL: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.NewsImageURL = environment.baseUrl;
    this.DefaultComponent.loaderShow();
    this.CommonService.GetActiveAdvertiserList().subscribe((data) => {
      if (data.status) {
        this.AdvertiserList = data.advertiser_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
    this.route.params.subscribe(params => this.news_id = params.news_id);
    var param = { news_id: this.news_id };
    if (this.news_id) {
      this.CommonService.getAdvertiseDetail(param).subscribe((data) => {
        if (data['status']) {
          this.AdvertiseData = data['advertise_data']
          if (this.AdvertiseData.advertise_pdf != '') {
            this.pdfURL = this.NewsImageURL + '/advertise/pdf/' + this.AdvertiseData.advertise_pdf;
          }
          if (this.AdvertiseData.advertise_image != '') {
            this.imageURL = this.NewsImageURL + '/advertise/' + this.AdvertiseData.advertise_image;
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }
    setTimeout(function () {
      $('#datepicker').datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        orientation: "top",
        todayHighlight: true,
        startDate: new Date(),
      }).on('changeDate', function (selected) {
        var minDate = new Date(selected.date.valueOf());
        $('#datepicker1').datepicker('setStartDate', minDate);
      });
      //set start date based on #datepicker
      $('#datepicker1').datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        orientation: "top",
        todayHighlight: true,
      }).on('changeDate', function (selected) {
        var maxDate = new Date(selected.date.valueOf());
        $('#datepicker').datepicker('setEndDate', maxDate);
      });
    }, 500);
  }
  ngOnInit(): void {
  }
  pdfValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/pdf\/*/) == null) {
      $("#pdf_file").val('');
      this.toastr.error("Only pdf are supported.");
      return;
    }
  }
  showImagePreview(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      if (id == 1) {
        this.imageURL = '';
      }
      this.toastr.error("Only images are supported.");
      return;
    }
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      if (id == 1) {
        this.imageURL = reader.result as string;
      }
    }
    reader.readAsDataURL(file)
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#advertise_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.AdvertiseBaseUrl + 'add_advertise',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/advertise-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
