import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { News } from './news';
declare var $: any;
@Component({
  selector: 'app-vividh-vibhag-page-add',
  templateUrl: './vividh-vibhag-page-add.component.html',
  styleUrls: ['./vividh-vibhag-page-add.component.css']
})
export class VividhVibhagPageAddComponent implements OnInit {
  news_id: any = 0;
  NewsData: News;
  NewsImageURL: string;
  pdfURL: string;
  imageURL1: string;
  imageURL2: string;
  imageURL3: string;
  imageURL4: string;
  imageURL5: string;
  imageURL6: string;
  video_div_1_count = [{ values: '', types: '' }];
  video_div_2_count = [{ values: '', types: '' }];
  video_div_3_count = [{ values: '', types: '' }];
  video_div_4_count = [{ values: '', types: '' }];
  show_vividh_title = false;
  result_guj_title = false;
  nri_news = false;
  vividh_vibhag = false;
  display_in_home = true;
  title_setting = true;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  guj_title1: string;
  news_date: string;
  content1: string;
  content2: string;
  log: string = '';
  VividhVibhagTitleList: [];
  @ViewChild("myckeditor") ckeditor: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.NewsImageURL = environment.baseUrl;
    this.DefaultComponent.loaderShow();
    this.route.params.subscribe(params => this.news_id = params.news_id);
    if (this.news_id) {
      var param = { news_id: this.news_id };
      this.CommonService.getVividhNewsDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['news_data'];
          if (data['video_data'].length > 0) {
            this.video_div_1_count = JSON.parse(data['video_data'][0].video_section_1);
            this.video_div_2_count = JSON.parse(data['video_data'][0].video_section_2);
            this.video_div_3_count = JSON.parse(data['video_data'][0].video_section_3);
            this.video_div_4_count = JSON.parse(data['video_data'][0].video_section_4);
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        this.DefaultComponent.loaderHide();
        this.guj_title1 = this.NewsData.vividh_guj_title;
        this.content1 = this.NewsData.vividh_content;
        this.content2 = this.NewsData.vividh_content_2;
        this.news_date = this.NewsData.vividh_news_date;
        if (this.NewsData.vividh_pdf_files != '') {
          this.pdfURL = this.NewsImageURL + this.NewsData.vividh_image_source + '/pdf/' + this.NewsData.vividh_pdf_files;
        }
        if (this.NewsData.vividh_heading_image_1 != '') {
          this.imageURL1 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_heading_image_1;
        }
        if (this.NewsData.vividh_matter_image_1_1 != '') {
          this.imageURL2 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_matter_image_1_1;
        }
        if (this.NewsData.vividh_heading_image_2 != '') {
          this.imageURL3 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_heading_image_2;
        }
        if (this.NewsData.vividh_matter_image_1_2 != '') {
          this.imageURL4 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_matter_image_1_2;
        }
        if (this.NewsData.vividh_matter_image_2_1 != '') {
          this.imageURL5 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_matter_image_2_1;
        }
        if (this.NewsData.vividh_matter_image_2_2 != '') {
          this.imageURL6 = this.NewsImageURL + this.NewsData.vividh_image_source + '/' + this.NewsData.vividh_matter_image_2_2;
        }
        if (this.NewsData.vividh_title_change) {
          this.result_guj_title = true;
        }
        if (this.NewsData.vividh_vividh_vibhag == 'yes') {
          this.show_vividh_title = true;
        }
        if (this.NewsData.vividh_nri_news == 'yes') {
          this.nri_news = true;
        }
        if (this.NewsData.vividh_vividh_vibhag == 'yes') {
          this.vividh_vibhag = true;
        }
        if (this.NewsData.vividh_display_home == 'yes') {
          this.display_in_home = true;
        }
      });
    } else {
      this.DefaultComponent.loaderShow();
      var parameter = { 'entry_date': localStorage.getItem('EntryDate'), 'news_type': 'tbl_vividh_news' };
      this.CommonService.GetVividhLastDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          $("#display_order").val(data.display_order);
          $("#oriDisplayOrder").val(0);
          var display_order = parseInt(data.display_order);
          var mod = display_order % 2;
          if (mod == 0) {
            $('#news_position').val('right');
          } else {
            $('#news_position').val('left');
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
      this.guj_title1 = '';
      this.content1 = '';
      this.content2 = '';
      this.nri_news = false;
      this.vividh_vibhag = false;
      this.display_in_home = true;
      this.news_date = localStorage.getItem('EntryDate');
    }
    this.DefaultComponent.loaderShow();
    this.CommonService.GetVividhVibhagTitleList().subscribe((data) => {
      if (data.status) {
        this.VividhVibhagTitleList = data.list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
    if (!localStorage.getItem('login_token')) {
      this.router.navigate(['/']);
    }
  }
  pdfValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/pdf\/*/) == null) {
      $("#pdf_file").val('');
      this.toastr.error("Only pdf are supported.");
      return;
    }
  }
  showImagePreview(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      if (id == 1) {
        this.imageURL1 = '';
      } else if (id == 2) {
        this.imageURL2 = '';
      } else if (id == 3) {
        this.imageURL3 = '';
      } else if (id == 4) {
        this.imageURL4 = '';
      } else if (id == 5) {
        this.imageURL5 = '';
      } else if (id == 6) {
        this.imageURL6 = '';
      }
      this.toastr.error("Only images are supported.");
      return;
    }
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      if (id == 1) {
        this.imageURL1 = reader.result as string;
      } else if (id == 2) {
        this.imageURL2 = reader.result as string;
      } else if (id == 3) {
        this.imageURL3 = reader.result as string;
      } else if (id == 4) {
        this.imageURL4 = reader.result as string;
      } else if (id == 5) {
        this.imageURL5 = reader.result as string;
      } else if (id == 6) {
        this.imageURL6 = reader.result as string;
      }
    }
    reader.readAsDataURL(file)
  }
  show_vividh_title_div(is_show) {
    this.show_vividh_title = is_show;
  }
  toggleVisibility(e) {
    var th = this;
    if (e.target.checked == true) {
      th.result_guj_title = true;
    } else {
      th.result_guj_title = false;
    }
  }
  add_video(cnt) {
    if (cnt == 1) {
      this.video_div_1_count.push({ values: '', types: '' });
    } else if (cnt == 2) {
      this.video_div_2_count.push({ values: '', types: '' });
    } else if (cnt == 3) {
      this.video_div_3_count.push({ values: '', types: '' });
    } else if (cnt == 4) {
      this.video_div_4_count.push({ values: '', types: '' });
    }
  }
  remove_video(i, cnt) {
    if (cnt == 1) {
      this.video_div_1_count.splice(i, 1);
    } else if (cnt == 2) {
      this.video_div_2_count.splice(i, 1);
    } else if (cnt == 3) {
      this.video_div_3_count.splice(i, 1);
    } else if (cnt == 4) {
      this.video_div_4_count.splice(i, 1);
    }
  }
  ngOnInit(): void {
    this.ckeConfig = {
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'basicstyles', groups: ['basicstyles'] },
        {
          name: 'paragraph',
          groups: ['list', 'align', 'paragraph']
        },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
      ],
      extraPlugins:
        'basicstyles,bidi,blockquote,clipboard,' +
        'button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,' +
        'contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,' +
        'filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,' +
        'font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,' +
        'indentblock,indentlist,justify,link,list,liststyle,magicline,' +
        'maximize,pagebreak,pastefromword,pastetext,' +
        'resize,menubutton,scayt,selectall,showblocks,' +
        'showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,' +
        'tabletools,templates,toolbar,undo,wysiwygarea',
    };
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#vividh_form");
        var fd = new FormData(form[0]);
        var video_1 = [];
        var video_2 = [];
        var video_3 = [];
        var video_4 = [];
        var video_1_value = $('textarea[name="video_values1[]"]');
        var video_1_type = $('select[name="video_types1[]"]');
        var video_2_value = $('textarea[name="video_values2[]"]');
        var video_2_type = $('select[name="video_types2[]"]');
        var video_3_value = $('textarea[name="video_values3[]"]');
        var video_3_type = $('select[name="video_types3[]"]');
        var video_4_value = $('textarea[name="video_values4[]"]');
        var video_4_type = $('select[name="video_types4[]"]');
        for (var i = 0; i < video_1_value.length; i++) {
          video_1.push({ values: video_1_value[i].value, types: video_1_type[i].value });
        }
        for (var i = 0; i < video_2_value.length; i++) {
          video_2.push({ values: video_2_value[i].value, types: video_2_type[i].value });
        }
        for (var i = 0; i < video_3_value.length; i++) {
          video_3.push({ values: video_3_value[i].value, types: video_3_type[i].value });
        }
        for (var i = 0; i < video_4_value.length; i++) {
          video_4.push({ values: video_4_value[i].value, types: video_4_type[i].value });
        }
        fd.append('guj_title1', th.guj_title1);
        fd.append('content1', th.content1);
        fd.append('content2', th.content2);
        fd.append('video_1', JSON.stringify(video_1));
        fd.append('video_2', JSON.stringify(video_2));
        fd.append('video_3', JSON.stringify(video_3));
        fd.append('video_4', JSON.stringify(video_4));
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.VividhBaseUrl + 'add_vividh_news',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/vividh-vibhag-page-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  onChange($event: any): void {
    console.log("onChange");
  }
  onPaste($event: any): void {
    console.log("onPaste");
  }
}
