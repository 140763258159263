import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-paper-add',
  templateUrl: './paper-add.component.html',
  styleUrls: ['./paper-add.component.css']
})
export class PaperAddComponent implements OnInit {
  EpaperCategory = [];
  EpaperPage_1 = [];
  EpaperPage_2 = [];
  EpaperPage_3 = [];
  EpaperPage_4 = [];
  EpaperPage_5 = [];
  news_date = localStorage.getItem('EntryDate');
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.DefaultComponent.loaderShow();
    this.CommonService.GetEpaperCategoryList().subscribe((data) => {
      if (data.status) {
        this.EpaperCategory = data.category_list;
        var th = this;
        setTimeout(() => {
          th.get_page_status(0);
        }, 1000);
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  ngOnInit(): void {
  }
  imageValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image_" + id).val('');
      this.toastr.error("Only image are supported.");
      return;
    }
  }
  pdfValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/pdf\/*/) == null) {
      $("#pdf_" + id).val('');
      this.toastr.error("Only pdf are supported.");
      return;
    }
  }
  enable_disable_page(event, page) {
    var th = this;
    if (event.target.checked) {
      var next_page = page + 1;
      $(".page_" + next_page).prop('readonly', true);
      $(".page_" + next_page).css('pointer-events', 'none');
      $(".page_" + next_page).css('background-color', '#e9ecef');
      $("#page_" + next_page + " option:eq(1)").attr('selected', 'selected');
      th.get_page_status(next_page);
    } else {
      var next_page = page + 1;
      $(".page_" + next_page).prop('readonly', false);
      $(".page_" + next_page).css('pointer-events', '');
      $(".page_" + next_page).css('background-color', '');
      $("#page_" + next_page + " option:eq(1)").attr('selected', '');
      th.get_page_status(next_page);
    }
  }
  get_page_status(page) {
    var routers = this.router;
    var th = this;
    var token = localStorage.getItem('login_token');
    var page_array = $('select[page="page"]');
    var pages = [];
    for (var i = 0; i < page_array.length; i++) {
      if (page_array[i].value) {
        pages.push(page_array[i].value);
      }
    }
    th.DefaultComponent.loaderShow();
    $.ajax({
      url: environment.EpaperBaseUrl + 'get_page_status',
      method: 'POST',
      data: { date: localStorage.getItem('EntryDate'), category: $("#epaper_category").val(), pages: pages },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        if (page == 0) {
          th.EpaperPage_1 = res.page_data;
        } else if (page == 1) {
          th.EpaperPage_2 = res.page_data;
        } else if (page == 2) {
          th.EpaperPage_3 = res.page_data;
        } else if (page == 3) {
          th.EpaperPage_4 = res.page_data;
        } else if (page == 4) {
          th.EpaperPage_5 = res.page_data;
        }
        th.DefaultComponent.loaderHide();
      }
    });
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        //$('.btn-success').attr('disabled', true);
        var form = $("#epaper_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.EpaperBaseUrl + 'add_epaper',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            console.log(res);
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/paper-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
}
