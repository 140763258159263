import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-panchang-day',
  templateUrl: './panchang-day.component.html',
  styleUrls: ['./panchang-day.component.css']
})
export class PanchangDayComponent implements OnInit {
  news_id = '';
  panchangList = [];
  show_multiple_delete_btn = false;
  news_delete_checkbox = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.route.params.subscribe(params => this.news_id = params.id);
    this.get_panchang_list(this.news_id);
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'del_ids': th.news_delete_checkbox };
    this.CommonService.DeletePanchangDay(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message);
        this.news_delete_checkbox = [];
        th.get_panchang_list(this.news_id);
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  get_panchang_list(news_id) {
    this.DefaultComponent.loaderShow();
    var parameter = { id: news_id };
    this.CommonService.GetPanchangDayList(parameter).subscribe((data) => {
      if (data.status) {
        this.panchangList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  ngOnInit(): void {
  }
}
