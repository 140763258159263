import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { News } from './news';
declare var $: any;
@Component({
  selector: 'app-shubh-lagnostav-add',
  templateUrl: './shubh-lagnostav-add.component.html',
  styleUrls: ['./shubh-lagnostav-add.component.css']
})
export class ShubhLagnostavAddComponent implements OnInit {
  news_id: any = 0;
  NewsData: News;
  NewsImageURL: string;
  pdfURL: string;
  imageURL1: string;
  imageURL2: string;
  imageURL3: string;
  imageURL4: string;
  imageURL5: string;
  imageURL6: string;
  video_div_1_count = [{ values: '', types: '' }];
  video_div_2_count = [{ values: '', types: '' }];
  video_div_3_count = [{ values: '', types: '' }];
  video_div_4_count = [{ values: '', types: '' }];
  show_vividh_title = false;
  result_guj_title = false;
  nri_news = false;
  vividh_vibhag = false;
  display_in_home = true;
  title_setting = true;
  name = 'ng2-ckeditor';
  ckeConfig: any;
  guj_title1: string;
  news_date: string;
  content1: string;
  content2: string;
  log: string = '';
  VividhVibhagTitleList: [];
  @ViewChild("myckeditor") ckeditor: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.NewsImageURL = environment.baseUrl;
    this.DefaultComponent.loaderShow();
    this.route.params.subscribe(params => this.news_id = params.news_id);
    if (this.news_id) {
      var param = { news_id: this.news_id };
      this.CommonService.getMarriageNewsDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['news_data'];
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        this.DefaultComponent.loaderHide();
        this.guj_title1 = this.NewsData.marriage_guj_title;
        this.content1 = this.NewsData.marriage_content;
        this.content2 = this.NewsData.marriage_content_2;
        this.news_date = this.NewsData.marriage_news_date;
        if (this.NewsData.marriage_heading_image_1 != '') {
          this.imageURL1 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_heading_image_1;
        }
        if (this.NewsData.marriage_matter_image_1_1 != '') {
          this.imageURL2 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_matter_image_1_1;
        }
        if (this.NewsData.marriage_heading_image_2 != '') {
          this.imageURL3 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_heading_image_2;
        }
        if (this.NewsData.marriage_matter_image_1_2 != '') {
          this.imageURL4 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_matter_image_1_2;
        }
        if (this.NewsData.marriage_matter_image_2_1 != '') {
          this.imageURL5 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_matter_image_2_1;
        }
        if (this.NewsData.marriage_matter_image_2_2 != '') {
          this.imageURL6 = this.NewsImageURL + this.NewsData.marriage_image_source + '/' + this.NewsData.marriage_matter_image_2_2;
        }
        if (this.NewsData.marriage_title_change) {
          this.result_guj_title = true;
        }
        if (this.NewsData.marriage_display_home == 'yes') {
          this.display_in_home = true;
        }
      });
    } else {
      this.DefaultComponent.loaderShow();
      var parameter = { 'entry_date': localStorage.getItem('EntryDate'), 'news_type': 'tbl_marriage_news' };
      this.CommonService.GetMarriageLastDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          $("#display_order").val(data.display_order);
          $("#oriDisplayOrder").val(0);
          var display_order = parseInt(data.display_order);
          var mod = display_order % 2;
          if (mod == 0) {
            $('#news_position').val('right');
          } else {
            $('#news_position').val('left');
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
      this.guj_title1 = '';
      this.content1 = '';
      this.content2 = '';
      this.nri_news = false;
      this.vividh_vibhag = false;
      this.display_in_home = true;
      this.news_date = localStorage.getItem('EntryDate');
    }
    this.DefaultComponent.loaderShow();
    this.CommonService.GetVividhVibhagTitleList().subscribe((data) => {
      if (data.status) {
        this.VividhVibhagTitleList = data.list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
    if (!localStorage.getItem('login_token')) {
      this.router.navigate(['/']);
    }
  }
  pdfValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/pdf\/*/) == null) {
      $("#pdf_file").val('');
      this.toastr.error("Only pdf are supported.");
      return;
    }
  }
  showImagePreview(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      if (id == 1) {
        this.imageURL1 = '';
      } else if (id == 2) {
        this.imageURL2 = '';
      } else if (id == 3) {
        this.imageURL3 = '';
      } else if (id == 4) {
        this.imageURL4 = '';
      } else if (id == 5) {
        this.imageURL5 = '';
      } else if (id == 6) {
        this.imageURL6 = '';
      }
      this.toastr.error("Only images are supported.");
      return;
    }
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      if (id == 1) {
        this.imageURL1 = reader.result as string;
      } else if (id == 2) {
        this.imageURL2 = reader.result as string;
      } else if (id == 3) {
        this.imageURL3 = reader.result as string;
      } else if (id == 4) {
        this.imageURL4 = reader.result as string;
      } else if (id == 5) {
        this.imageURL5 = reader.result as string;
      } else if (id == 6) {
        this.imageURL6 = reader.result as string;
      }
    }
    reader.readAsDataURL(file)
  }
  show_vividh_title_div(is_show) {
    this.show_vividh_title = is_show;
  }
  toggleVisibility(e) {
    var th = this;
    if (e.target.checked == true) {
      th.result_guj_title = true;
    } else {
      th.result_guj_title = false;
    }
  }
  ngOnInit(): void {
    this.ckeConfig = {
      toolbarGroups: [
        { name: 'document', groups: ['mode'] },
        { name: 'basicstyles', groups: ['basicstyles'] },
        {
          name: 'paragraph',
          groups: ['list', 'align', 'paragraph']
        },
        { name: 'styles', groups: ['styles'] },
        { name: 'colors', groups: ['colors'] },
      ],
      extraPlugins:
        'basicstyles,bidi,blockquote,clipboard,' +
        'button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu,' +
        'contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup,' +
        'filebrowser,find,fakeobjects,floatingspace,listblock,richcombo,' +
        'font,format,forms,horizontalrule,htmlwriter,iframe,image,indent,' +
        'indentblock,indentlist,justify,link,list,liststyle,magicline,' +
        'maximize,pagebreak,pastefromword,pastetext,' +
        'resize,menubutton,scayt,selectall,showblocks,' +
        'showborders,smiley,sourcearea,specialchar,stylescombo,tab,table,' +
        'tabletools,templates,toolbar,undo,wysiwygarea',
    };
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#marriage_form");
        var fd = new FormData(form[0]);
        var video_1 = [];
        var video_2 = [];
        var video_3 = [];
        var video_4 = [];
        fd.append('guj_title1', th.guj_title1);
        fd.append('content1', th.content1);
        fd.append('content2', th.content2);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.MarriageBaseUrl + 'add_marriage_news',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/shubh-lagnostav-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  onChange($event: any): void {
    console.log("onChange");
  }
  onPaste($event: any): void {
    console.log("onPaste");
  }
}
