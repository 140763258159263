<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="epaper_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Epaper</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button (click)="submit_form()"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                                Page</button>
                                                            <a href="javascript:void(0);" routerLink="/epaper-list"
                                                                type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Category: </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" id="epaper_category" name="epaper_category">
                                                <option *ngFor="let ec of EpaperCategory" value="{{ec._id}}">
                                                    {{ec.gujarati_title}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Date: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" value="{{news_date}}" data-validation="required"
                                                type="text" readonly name="sdate" value="" id="sdate">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="image_1" class="col-sm-4 col-form-label">Image File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_image_1">
                                            <input type="file" id="image_1" class="form-control page_1" name="image_1"
                                                accept="image/*" (change)="imageValidation($event,1)">
                                            <small>Recommended Dimension:! <strong> 150px X 215px</strong></small><br>
                                            <small>You can upload .jpg,.gif,.png image only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <br /><label for="pdf_1" class="col-sm-4 col-form-label">PDF File: </label>
                                        <div class="col-sm-8">
                                            <input type="hidden" name="ori_pdf_1">
                                            <input type="file" id="pdf_1" class="form-control page_1" name="pdf_1"
                                                accept="application/pdf" (change)="pdfValidation($event,1)">
                                            <small>You can upload .pdf file only! </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save Page</button>
                                        <a href="javascript:void(0);" routerLink="/epaper-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>