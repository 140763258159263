import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreviewComponent } from './preview/preview.component';
const routes: Routes = [{
  path: 'home-news-preview/:section',
  component: PreviewComponent,
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeNewsPreviewRoutingModule { }
