import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-cms-list',
  templateUrl: './cms-list.component.html',
  styleUrls: ['./cms-list.component.css']
})
export class CmsListComponent implements OnInit {
  cmsList = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_cms_list();
  }
  get_cms_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetCMSList(parameter).subscribe((data) => {
      if (data.status) {
        this.cmsList = data.cms_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  ngOnInit(): void {
  }
}
