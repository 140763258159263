<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/vividh-vibhag-title-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Vividh Vibhag Title</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<table class="table">
								<thead>
									<tr>
										<th><button class="btn btn-outline-danger waves-effect waves-light"
												ng-click="delete_multiple_news()"><i class="fa fa-trash"></i></button>
										</th>
										<th>Gujarati Title</th>
										<th>Date</th>
										<th>Display in<br>DD?</th>
										<th>Display</th>
										<th>Active</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of TitleList">
										<td class="text-center"><input type="checkbox"
												ng-change="add_delete_checkbox(news_id,'{{mn._id}}')"></td>
										<td>
											<a
												routerLink="/vividh-vibhag-title-edit/{{mn._id}}">{{mn.vividh_page_guj_title}}</a>
										</td>
										<td>{{mn.vividh_page_update_date}}</td>
										<td class="text-center">
											<input type="checkbox" [checked]="mn.vividh_page_display_in_dd == 'yes'"
												(change)="update_dropdown($event,mn._id)" value="yes">
										</td>
										<td>
											<input
												(blur)="update_display_orders($event,mn._id,mn.vividh_page_display_order)"
												(keyup.enter)="update_display_orders($event,mn._id,mn.vividh_page_display_order)"
												class="form-control" type="text"
												value="{{mn.vividh_page_display_order}}">
										</td>
										<td class="text-center">
											<input type="checkbox" [checked]="mn.vividh_page_status == 'yes'"
												(change)="update_news_status($event,mn._id)" value="yes">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>