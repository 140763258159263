import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {
  cmsList = [];
  groupList: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.getAdminGroup();
    this.getAdminUser();
  }
  getAdminGroup() {
    this.DefaultComponent.loaderShow();
    this.CommonService.getAdminGroup().subscribe((data) => {
      if (data.status) {
        this.groupList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  getAdminUser() {
    this.DefaultComponent.loaderShow();
    this.CommonService.getAdminUser().subscribe((data) => {
      if (data.status) {
        var resultData = data.news_list;
        for (var i = 0; i < resultData.length; i++) {
          var groupData = this.groupList.find(x => x._id == resultData[i]['group_id']);
          if (groupData != undefined) {
            resultData[i]['group_name'] = groupData.title;
          } else {
            resultData[i]['group_name'] = '';
          }

        }
        this.cmsList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  delete_news(id) {
    this.DefaultComponent.loaderShow();
    var param = { news_id: id };
    this.CommonService.deleteAdminUser(param).subscribe((data) => {
      this.DefaultComponent.loaderHide();
      if (data['status']) {
        this.toastr.success(data.message)
        this.getAdminUser();
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        } else {
          this.toastr.error(data.message)
        }
      }
    });
  }
  ngOnInit(): void {
  }
}
