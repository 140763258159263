<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="advertise_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group">
														<h4 class="page-title">Advertise Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Advertise</button>
															<a href="javascript:void(0);" routerLink="/advertise-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Advertiser: </label>
										<div class="col-sm-8">
											<input type="hidden" name="newsID" value="{{AdvertiseData?._id}}">
											<select class="form-control" data-validation="required" name="advertiser">
												<option selected value="">Select Advertiser</option>
												<option [selected]="AdvertiseData?.advertiser == al._id"
													*ngFor="let al of AdvertiserList" value="{{al._id}}">
													{{al.first_name}} {{al.last_name}}</option>
											</select>
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Type: </label>
										<div class="col-sm-8">
											<select class="form-control" name="type" data-validation="required">
												<option value="">Please Select</option>
												<option [selected]="AdvertiseData?.type == 'image'" value="image">Image
												</option>
												<option [selected]="AdvertiseData?.type == 'source'" value="source">
													Source</option>
												<option [selected]="AdvertiseData?.type == 'swf'" value="swf">Swf
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Link/Source: </label>
										<div class="col-sm-8">
											<textarea name="txtadvertise_link" id="txtadvertise_link"
												class="form-control  input-background">{{AdvertiseData?.advertise_link}}</textarea><br />
											<strong>e.g.: http://www.akilanews.com/</strong>
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label for="image3" class="col-sm-4 col-form-label">Image/Swf: </label>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image_1"
												value="{{AdvertiseData?.advertise_image}}">
											<input id="image1" type="file" class="form-control" name="file_upload1"
												type="file" accept='image/*' (change)="showImagePreview($event,1)">
											<img [src]="imageURL" *ngIf="imageURL"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<br /><label for="image3" class="col-sm-4 col-form-label">Pdf: </label>
										<div class="col-sm-8">
											<input type="hidden" name="pdf_files"
												value="{{AdvertiseData?.advertise_pdf}}">
											<input class="form-control" name="pdf_file" type="file"
												(change)="pdfValidation($event,1)" accept="application/pdf"
												id="pdf_file" />
											<a target="_blank" [href]="pdfURL" *ngIf="pdfURL"
												style="max-width:300px;margin-top:15px">View</a>
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Advertise Text (Above): </label>
										<div class="col-sm-8">
											<textarea name="txtadvertise_text_above" id="txtadvertise_text_above"
												class="form-control  input-background validate[optional] textarea"
												spellcheck="false">{{AdvertiseData?.advertise_text_above}}</textarea>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Location: </label>
										<div class="col-sm-8">
											<select class="form-control" name="location" data-validation="required">
												<option value="">Please Select</option>
												<option [selected]="AdvertiseData?.location == 'ear-left'"
													value="ear-left">Ear Panel Banner Left</option>
												<option [selected]="AdvertiseData?.location == 'ear-right'"
													value="ear-right">Ear Panel Banner Right</option>
												<option [selected]="AdvertiseData?.location == 'top'" value="top">Top
													Upper Panel Banner</option>
												<option [selected]="AdvertiseData?.location == 'bottom'" value="bottom">
													MainNews Panel Banner</option>
												<option [selected]="AdvertiseData?.location == 'fatafat'"
													value="fatafat">Fatafat News Panel Banner</option>
												<option [selected]="AdvertiseData?.location == 'right'" value="right">
													Inside Page Rectangle</option>
												<option [selected]="AdvertiseData?.location == 'home-middle-right'"
													value="home-middle-right">Bottom Tall Banner</option>
												<option [selected]="AdvertiseData?.location == 'Gujarat News Banner'"
													value="Gujarat News Banner">Gujarat News Banner</option>
												<option [selected]="AdvertiseData?.location == 'Rajkot News Banner'"
													value="Rajkot News Banner">Rajkot News Banner</option>
												<option
													[selected]="AdvertiseData?.location == 'Saurashtra-Kutch News Banner'"
													value="Saurashtra-Kutch News Banner">Saurashtra-Kutch News Banner
												</option>
												<option
													[selected]="AdvertiseData?.location == 'International-News-Banner'"
													value="International-News-Banner">International News Banner</option>
												<option [selected]="AdvertiseData?.location == 'Sports News Banner'"
													value="Sports News Banner">Sports News Banner</option>
												<option
													[selected]="AdvertiseData?.location == 'Entertainment News Banner'"
													value="Entertainment News Banner">Entertainment News Banner</option>
												<option [selected]="AdvertiseData?.location == 'main-news-inner-page'"
													value="main-news-inner-page">Inside Page News</option>
												<option [selected]="AdvertiseData?.location == 'gutter_ads'"
													value="gutter_ads">Gutter Ads</option>
											</select>
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Advertise Text (Below): </label>
										<div class="col-sm-8">
											<textarea name="txtadvertise_text_below" id="txtadvertise_text_below"
												class="form-control input-background validate[optional] textarea"
												spellcheck="false">{{AdvertiseData?.advertise_text_below}}</textarea>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Start Date: </label>
										<div class="col-sm-8">
											<input class="form-control" autocomplete="off" id="datepicker"
												data-validation="required" type="text" name="sdate"
												value="{{AdvertiseData?.start_date}}">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">End Date: </label>
										<div class="col-sm-8">
											<input class="form-control" autocomplete="off" id="datepicker1"
												data-validation="required" type="text" name="edate"
												value="{{AdvertiseData?.end_date}}">
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Country: </label>
										<div class="col-sm-8">
											<select class="form-control" data-validation="required" name="country">
												<option value="">Please Select</option>
												<option [selected]="AdvertiseData?.country == 'all'" value="all">All
												</option>
												<option [selected]="AdvertiseData?.country == 'india'" value="india">
													India</option>
											</select>
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Display Order: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required number" type="text"
												name="display_order" value="{{AdvertiseData?.display_order}}"
												id="display_order">
										</div>
									</div>
								</div>
								<div class="form-group">
									<div>
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save
											Advertise</button>
										<a href="javascript:void(0);" routerLink="/advertise-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>