import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EpaperRoutingModule } from './epaper-routing.module';
import { CategoryAddComponent } from './category-add/category-add.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { PageAddComponent } from './page-add/page-add.component';
import { PageListComponent } from './page-list/page-list.component';
import { PaperListComponent } from './paper-list/paper-list.component';
import { PaperAddComponent } from './paper-add/paper-add.component';
import { EpaperMergeComponent } from './epaper-merge/epaper-merge.component';
import { PaperEditComponent } from './paper-edit/paper-edit.component';
import { EpaperAddComponent } from './epaper-add/epaper-add.component';
import { EpaperListComponent } from './epaper-list/epaper-list.component';
@NgModule({
  declarations: [CategoryAddComponent, CategoryListComponent, PageAddComponent, PageListComponent, PaperListComponent, PaperAddComponent, EpaperMergeComponent, PaperEditComponent, EpaperAddComponent, EpaperListComponent],
  imports: [
    CommonModule,
    EpaperRoutingModule
  ]
})
export class EpaperModule { }
