<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <h4 class="page-title">Epaper Merge</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Merge</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let mn of CategoryList">
                                        <td>{{mn.category_title}}</td>
                                        <td *ngIf="mn.is_pdf_merged">
                                            <button class="btn btn-outline-success waves-effect waves-light"
                                                (click)="merge_pdf(mn._id)">Merge Again</button>
                                        </td>
                                        <td *ngIf="!mn.is_pdf_merged">
                                            <button class="btn btn-outline-danger waves-effect waves-light"
                                                (click)="merge_pdf(mn._id)">Merge</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>