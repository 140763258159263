import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImagesRoutingModule } from './images-routing.module';
import { ImagesAddComponent } from './images-add/images-add.component';
import { ImagesListComponent } from './images-list/images-list.component';
@NgModule({
  declarations: [ImagesAddComponent, ImagesListComponent],
  imports: [
    CommonModule,
    ImagesRoutingModule
  ]
})
export class ImagesModule { }
