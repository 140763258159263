<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="advertiser_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Advertiser Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Advertiser</button>
															<a href="javascript:void(0);" routerLink="/advertiser-list"
																type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">First Name: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="first_name" value="{{AdvertiserData?.first_name}}"
												id="first_name">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Last Name: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="last_name" value="{{AdvertiserData?.last_name}}" id="last_name">
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Email: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required email" type="text"
												name="email" value="{{AdvertiserData?.email}}" id="email">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Password: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="password" value="{{AdvertiserData?.password}}" id="password">
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">City: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="city" value="{{AdvertiserData?.city}}" id="city">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">State: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="state" value="{{AdvertiserData?.state}}" id="state">
										</div>
									</div>
								</div>
								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Zipcode: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="zipcode" value="{{AdvertiserData?.zipcode}}" id="zipcode">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Mobile: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required number" type="text"
												name="mobile" value="{{AdvertiserData?.mobile}}" id="mobile">
										</div>
									</div>
								</div>
								<div class="form-group">
									<div>
										<input type="hidden" name="AdvertiserID" value="{{AdvertiserData?._id}}">
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save
											Advertiser</button>
										<a href="javascript:void(0);" routerLink="/advertiser-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>