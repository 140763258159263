import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { TithiAddComponent } from './tithi-add/tithi-add.component';
import { TithiListComponent } from './tithi-list/tithi-list.component';
import { TithiDayComponent } from './tithi-day/tithi-day.component';
import { TithiEditComponent } from './tithi-edit/tithi-edit.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'tithi-add', component: TithiAddComponent },
  { path: 'tithi-list', component: TithiListComponent },
  { path: 'tithi-day/:id', component: TithiDayComponent },
  { path: 'tithi-edit/:id', component: TithiEditComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TithiRoutingModule { }
