import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { ShubhDivasAddComponent } from './shubh-divas-add/shubh-divas-add.component';
import { ShubhDivasListComponent } from './shubh-divas-list/shubh-divas-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'shubh-divas-add', component: ShubhDivasAddComponent },
  { path: 'shubh-divas-edit/:news_id', component: ShubhDivasAddComponent },
  { path: 'shubh-divas-list', component: ShubhDivasListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShubhDivasRoutingModule { }
