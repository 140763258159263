<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <a href="javascript:void(0)" routerLink="/panchang-add">
                                <li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
                            </a>
                        </ol>
                    </div>
                    <h4 class="page-title">Panchang</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <!-- <th><button *ngIf="show_multiple_delete_btn" class="btn btn-outline-danger waves-effect waves-light" (click)="delete_multiple_news()"><i class="fa fa-trash"></i></button></th> -->
                                        <th>Date</th>
                                        <th>panchang</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let mn of panchangList">
                                        <!-- <td ><input type="checkbox"  (change)="add_delete_checkbox($event,mn._id)"></td> -->
                                        <td><a routerLink="/panchang-edit/{{mn._id}}">{{mn.date}}</a></td>
                                        <td><a routerLink="/panchang-edit/{{mn._id}}">{{mn.panchang}}</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>