import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { BirthdayAddComponent } from './birthday-add/birthday-add.component';
import { BirthdayListComponent } from './birthday-list/birthday-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'birthday-add', component: BirthdayAddComponent },
  { path: 'birthday-list', component: BirthdayListComponent },
  { path: 'birthday-edit/:news_id', component: BirthdayAddComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BirthdayRoutingModule { }
