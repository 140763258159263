import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicSectionRoutingModule } from './dynamic-section-routing.module';
import { DynamicSectionAddComponent } from './dynamic-section-add/dynamic-section-add.component';
import { DynamicSectionListComponent } from './dynamic-section-list/dynamic-section-list.component';
@NgModule({
  declarations: [DynamicSectionAddComponent, DynamicSectionListComponent],
  imports: [
    CommonModule,
    DynamicSectionRoutingModule
  ]
})
export class DynamicSectionModule { }
