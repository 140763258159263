import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-kasumbo-list',
  templateUrl: './kasumbo-list.component.html',
  styleUrls: ['./kasumbo-list.component.css']
})
export class KasumboListComponent implements OnInit {
  filter_div = false;
  show_filter_text = true;
  show_multiple_delete_btn = false;
  show_filter_date = false;
  kasumboList = [];
  display_order_currrent = [];
  display_order_val = [];
  display_order_ids = [];
  news_delete_checkbox = [];
  image_path: any = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_kasumbo_list();
  }
  get_kasumbo_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetKasumboList(parameter).subscribe((data) => {
      if (data.status) {
        this.kasumboList = data.kasumbo_list;
        this.image_path = data.image_path;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate') };
    this.CommonService.DeleteKasumboNews(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message);
        this.news_delete_checkbox = [];
        th.get_kasumbo_list();
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_kasumbo', 'prefix': 'kasumbo' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  ngOnInit(): void {
  }
}
