import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { FestivalPhotosAddComponent } from './festival-photos-add/festival-photos-add.component';
import { FestivalPhotosListComponent } from './festival-photos-list/festival-photos-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'festival-photo-add', component: FestivalPhotosAddComponent },
  { path: 'festival-photo-list', component: FestivalPhotosListComponent },
  { path: 'festival-photo-edit/:news_id', component: FestivalPhotosAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FestivalPhotosRoutingModule { }
