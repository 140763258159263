<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="panchang_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Panchang</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Date: </label>
                                        <div class="col-sm-8">
                                            <input autocomplete="off" class="form-control date"
                                                data-validation="required" type="text" name="month" value=""
                                                id="datepicker">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" id="date1">
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()" type="submit"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save</button>
                                        <a href="javascript:void(0);" routerLink="/panchang-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>