import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { DynamicSectionAddComponent } from './dynamic-section-add/dynamic-section-add.component';
import { DynamicSectionListComponent } from './dynamic-section-list/dynamic-section-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'dynamic-section-add', component: DynamicSectionAddComponent },
  { path: 'dynamic-section-list', component: DynamicSectionListComponent },
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DynamicSectionRoutingModule { }
