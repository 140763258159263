<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="cms_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">CMS Edit</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button (click)="submit_form()"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                                CMS</button>
                                                            <a href="javascript:void(0);" routerLink="/cms-list"
                                                                type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Title: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="title" value="{{NewsData?.title}}" id="title">
                                        </div>
                                    </div>
                                </div>
                                <input type="hidden" name="newsID" value="{{NewsData?._id}}">
                                <div class="form-group row">
                                    <div class="col-md-12 row">
                                        <label for="guj_title" class="col-sm-2 col-form-label">Content 1: </label>
                                        <div class="col-sm-10">
                                            <ckeditor name="content1" [(ngModel)]="content1" required
                                                [config]="ckeConfig" debounce="500" (paste)="onPaste($event)"
                                                (change)="onChange($event)">
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Save CMS</button>
                                        <a href="javascript:void(0);" routerLink="/cms-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>