import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-advertiser-list',
  templateUrl: './advertiser-list.component.html',
  styleUrls: ['./advertiser-list.component.css']
})
export class AdvertiserListComponent implements OnInit {
  AdvertiserList = [];
  show_multiple_delete_btn = false;
  NewsDeleteCheckboxList = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent) {
    this.DefaultComponent.loaderShow();
    this.get_advertiser_list();
  }
  get_advertiser_list() {
    this.CommonService.GetAdvertiserList().subscribe((data) => {
      if (data.status) {
        this.AdvertiserList = data.advertiser_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  get_filtered_news() {
    var search_type = $("#search_type").val();
    var search_title = $("#search_title").val();
    if (search_title) {
      this.DefaultComponent.loaderShow();
      var parameter = { 'search_type': search_type, 'search_title': search_title, 'table_name': 'tbl_advertiser', "prefix": "advertiser" };
      this.CommonService.GetFilteredNewsList(parameter).subscribe((data) => {
        if (data.status) {
          this.AdvertiserList = data.news_list;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    } else {
      this.get_advertiser_list();
    }
  }
  add_delete_checkbox(e, id) {
    if (this.NewsDeleteCheckboxList.includes(id)) {
      var index = this.NewsDeleteCheckboxList.indexOf(id);
      this.NewsDeleteCheckboxList.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.NewsDeleteCheckboxList.push(id);
    }
    if (this.NewsDeleteCheckboxList.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    var th = this;
    var delete_checkbox = this.NewsDeleteCheckboxList;
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    $.ajax({
      url: environment.AdvertiseBaseUrl + 'delete_advertiser',
      method: 'POST',
      data: { delete_checkbox: delete_checkbox },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.NewsDeleteCheckboxList = [];
          th.toastr.success(res.message)
          th.get_advertiser_list();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  toggleVisibility(e) {
    var th = this;
    if (e.target.checked == true) {
      var adv_id = e.target.value;
      var status = 'yes';
    } else {
      var adv_id = e.target.value;
      var status = 'no';
    }
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    $.ajax({
      url: environment.AdvertiseBaseUrl + 'advertiser_status_change',
      method: 'POST',
      data: { adv_id: adv_id, status: status },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.toastr.success(res.message)
          routers.navigate(['/advertiser-list']);
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  ngOnInit(): void {
  }
}
