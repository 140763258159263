import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { OpinionPollAddComponent } from './opinion-poll-add/opinion-poll-add.component';
import { OpinionPollListComponent } from './opinion-poll-list/opinion-poll-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'opinion-poll-add', component: OpinionPollAddComponent },
  { path: 'opinion-poll-list', component: OpinionPollListComponent },
  { path: 'opinion-poll-edit/:news_id', component: OpinionPollAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpinionPollRoutingModule { }
