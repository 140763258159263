import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { News } from './news';
declare var $: any;
@Component({
  selector: 'app-vividh-vibhag-title-add',
  templateUrl: './vividh-vibhag-title-add.component.html',
  styleUrls: ['./vividh-vibhag-title-add.component.css']
})
export class VividhVibhagTitleAddComponent implements OnInit {
  NewsData: News;
  imageURL1: string;
  result_guj_title1 = false;
  result_guj_video = false;
  result_guj_image = false;
  name = 'ng2-ckeditor';
  NewsImageURL: string;
  ckeConfig: any;
  log: string = '';
  VividhVibhagTitleList: [];
  news_date: string;
  news_id: any = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.DefaultComponent.loaderShow();
    this.route.params.subscribe(params => this.news_id = params.news_id);
    this.NewsImageURL = environment.baseUrl;
    if (this.news_id) {
      var param = { news_id: this.news_id };
      this.CommonService.getVividhTitleDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['news_data'];
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        this.DefaultComponent.loaderHide();
        if (this.NewsData.vividh_page_image != '') {
          this.imageURL1 = this.NewsImageURL + '/vividh_title/' + this.NewsData.vividh_page_image;
        }
      });
    } else {
      this.news_date = localStorage.getItem('EntryDate');
    }
  }
  showImagePreview(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      if (id == 1) {
        this.imageURL1 = '';
      }
      alert("Only images are supported.");
      return;
    }
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      if (id == 1) {
        this.imageURL1 = reader.result as string;
      }
    }
    reader.readAsDataURL(file)
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#vividh_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.VividhBaseUrl + 'add_vividh_title',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/vividh-vibhag-title-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  ngOnInit(): void {
  }
}
