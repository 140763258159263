import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
declare var $: any;
@Component({
  selector: 'app-international-news-list',
  templateUrl: './international-news-list.component.html',
  styleUrls: ['./international-news-list.component.css']
})
export class InternationalNewsListComponent implements OnInit {
  filter_div = false;
  show_filter_text = true;
  show_multiple_delete_btn = false;
  show_filter_date = false;
  internationalList = [];
  display_order_currrent = [];
  display_order_val = [];
  display_order_ids = [];
  news_delete_checkbox = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_international_news_list();
  }
  shareOnWhatsApp(id, title, gred_above) {
    var newsTitle = title.replace(/<[^>]*>/g, '');
    if (newsTitle.split(" ").length > 20) {
      var finalTitle = newsTitle.split(" ").splice(0, 20).join(" ") + '...\n'
    } else {
      var finalTitle = newsTitle + '\n'
    }
    var finalGred = '';
    if (gred_above != '') {
      var newsGred = gred_above.replace(/<[^>]*>/g, '');
      if (newsGred.split(" ").length > 15) {
        var finalGred = '\n' + newsGred.split(" ").splice(0, 15).join(" ") + '...\n'
      } else {
        var finalGred = '\n' + newsGred + '\n'
      }
    }
    var url = environment.FrontWebUrl + 'international-news-detail/' + id;
    var moreNews = '\nવધુ સમાચાર વાંચો : \n' + url + '\n';
    var appLink = '\nઅકિલા મોબાઈલ એપ: ' + environment.AppDownloadLink;
    var whatsAppChannel = '\n\nઅકિલા વોટ્સએપ ચેનલ: ' + environment.whatsAppChannel;
    var message = encodeURIComponent(finalTitle) + encodeURIComponent(finalGred) + encodeURIComponent(moreNews) + encodeURIComponent(appLink) + encodeURIComponent(whatsAppChannel);
    var whatsapp_url = "whatsapp://send?text=" + message;
    window.location.href = whatsapp_url;
  }
  activeDeactiveAll(e) {
    var news_ids = [];
    $(".news_ids").each(function (a) {
      news_ids.push($(this).val())
    });
    if (e.target.checked == true) {
      this.DefaultComponent.loaderShow();
      var parameter = { 'news_ids': news_ids, 'table_name': 'tbl_international_news', "prefix": "international", "status": 'yes' };
      this.CommonService.ActiveDeactiveNews(parameter).subscribe((data) => {
        if (data.status) {
          this.toastr.success(data.message);
          $("#mainCheckbox").prop("checked", false)
          $('.newsStatus').each(function () {
            $(this).prop("checked", true);
          });
        } else {
          this.toastr.error(data.message);
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  innerPreview() {
    window.open(environment.AppUrl + "inner-news-preview/international", "_blank", "resizable=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,fullscreen=no,dependent=no,width=700,height=670");
  }
  homePreview() {
    window.open(environment.AppUrl + "home-news-preview/international", "_blank", "resizable=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,fullscreen=no,dependent=no,width=600,height=670");
  }
  get_filtered_news() {
    var search_type = $("#search_type").val();
    var search_title = $("#search_title").val();
    var datepicker1 = $("#datepicker1").val();
    if (search_title || datepicker1) {
      this.DefaultComponent.loaderShow();
      var parameter = { 'search_type': search_type, 'search_title': search_title, 'search_date': datepicker1, 'table_name': 'tbl_international_news', "prefix": "international" };
      this.CommonService.GetFilteredNewsList(parameter).subscribe((data) => {
        if (data.status) {
          this.internationalList = data.news_list;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    } else {
      this.get_international_news_list();
    }
  }
  get_international_news_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetInternationalNewsList(parameter).subscribe((data) => {
      if (data.status) {
        this.internationalList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  showFilterDiv(e) {
    var th = this;
    if (e.target.value == 'title') {
      th.show_filter_text = true;
      th.show_filter_date = false;
    } else {
      th.show_filter_text = false;
      th.show_filter_date = true;
      th.DefaultComponent.loaderShow();
      setTimeout(function () {
        $('#datepicker1').datepicker({
          format: "dd-mm-yyyy",
          autoclose: true,
          orientation: "top",
          todayHighlight: true,
        })
        th.DefaultComponent.loaderHide();
      }, 500);
    }
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    Swal.fire({
      title: 'Are you sure to delete this news?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true,
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        var th = this;
        var routers = this.router;
        this.DefaultComponent.loaderShow();
        var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate') };
        this.CommonService.DeleteInternationalNews(parameter).subscribe((data) => {
          if (data.status) {
            th.toastr.success(data.message);
            this.news_delete_checkbox = [];
            th.get_international_news_list();
          } else {
            if (data.token_expire) {
              localStorage.removeItem('login_token');
              routers.navigate(['/']);
            } else {
              th.toastr.error(data.message);
            }
          }
          this.DefaultComponent.loaderHide();
        });
      }
    });
  }
  update_news_position(e, id) {
    console.log(id);
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'new_position': e.target.value, 'id': id, 'table_name': 'tbl_international_news', 'prefix': 'international' };
    this.CommonService.UpdateNewsPosition(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_display_home(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'display_home': status, 'id': id, 'table_name': 'tbl_international_news', 'prefix': 'international' };
    this.CommonService.UpdateNewsDisplayHome(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_international_news', 'prefix': 'international' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_display_orders(e, id, ori_ord) {
    if (parseInt(ori_ord) != parseInt(e.target.value)) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'display_order': e.target.value, 'id': id, 'ori_display_order': ori_ord, 'news_date': localStorage.getItem('EntryDate') };
      this.CommonService.UpdateInternationalNewsDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_international_news_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  update_repeat_news(e, id) {
    if (parseInt(e.target.value) > 0) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'repeat_days': e.target.value, 'id': id, 'news_date': localStorage.getItem('EntryDate') };
      this.CommonService.UpdateInternationalNewsRepeat(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_international_news_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  ngOnInit(): void {
  }
}
