<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <div class="btn-group pull-right">
                        <ol class="breadcrumb hide-phone p-0 m-0">
                            <a href="javascript:void(0)" routerLink="/admin-group-add">
                                <li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
                            </a>
                        </ol>
                    </div>
                    <h4 class="page-title">Admin Group</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tr *ngFor="let mn of cmsList">
                                    <td>
                                        <a routerLink="/admin-group-edit/{{mn._id}}">{{mn.title}}</a>
                                    </td>
                                    <td>
                                        <button class="btn btn-outline-danger waves-effect waves-light"
                                            (click)="delete_news(mn._id)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>