import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-notice-add',
  templateUrl: './notice-add.component.html',
  styleUrls: ['./notice-add.component.css']
})
export class NoticeAddComponent implements OnInit {
  imageURL1: string;
  news_date = localStorage.getItem('EntryDate');
  NewsData: any = [];
  news_id: any = 0;
  NewsImageURL = environment.baseUrl;
  imageURL: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.route.params.subscribe(params => this.news_id = params.news_id);
    var param = { news_id: this.news_id };
    if (this.news_id) {
      this.CommonService.getNoticeDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['update_data']
          if (this.NewsData.image != '') {
            this.imageURL = this.NewsImageURL + '/notice/' + this.NewsData.image;
          }
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      });
    }
  }
  imageValidation(event, id) {
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      this.toastr.error("Only image are supported.");
      return;
    }
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        //$('.btn-success').attr('disabled', true);
        var form = $("#notice_form");
        var fd = new FormData(form[0]);
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.NoticeBaseUrl + 'add_notice',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/notice-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  ngOnInit(): void {
  }
}
