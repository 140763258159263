import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { NotificationAddComponent } from './notification-add/notification-add.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'notification-add', component: NotificationAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationRoutingModule { }
