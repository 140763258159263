import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loginIsAdmin: any = localStorage.getItem('login_is_admin');
  permissionModuleArray: any = localStorage.getItem('login_module_array');
  constructor(private router: Router,
    private route: ActivatedRoute
  ) {
    if (!localStorage.getItem('login_token')) {
      this.router.navigate(['/']);
    }
  }
  ngOnInit(): void {
  }
  logout() {
    localStorage.removeItem('login_token');
    localStorage.removeItem('login_name');
    localStorage.removeItem('login_id');
    localStorage.removeItem('login_is_admin');
    localStorage.removeItem('login_module_array');
    localStorage.removeItem('EntryDate');
    this.router.navigate(['/']);
  }
  checkPermissionModule(data) {
    if (this.loginIsAdmin != 'y') {
      if (this.permissionModuleArray.indexOf(data) !== -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
