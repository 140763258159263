<section class="main">
    <div class="main_area_news" id="main_area_news">
        <div class="container">
            <div class="row">
                <div class="col-sm-5" *ngIf="section=='main'">
                    <div class="main_news_left">
                        <div class="main-title-outer">
                            <a routerLink="/main-news">
                                <div class="main-title"><img src="assets/webPreview/images/news.png" /><span
                                        class="title_head_text">મુખ્ય સમાચાર</span></div>
                            </a>
                        </div>
                        <div id="main_news_area">
                            <div class="news_center_box" *ngFor="let fp of festivalPhotos;">
                                <div class="news_center_thumb">
                                    <img class="img-fluid" src="{{festivalPhotosPath+fp.image}}">
                                </div>
                            </div>
                            <div *ngFor="let fl of main_new_list; let i = index">
                                <div
                                    [ngClass]="{'news_right_box': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_box':fl.main_image_position_home=='left' ,'news_center_box':fl.main_image_position_home=='center','news-center':fl.main_use_br=='yes'}">
                                    <div *ngIf="fl.main_heading_image_1"
                                        [ngClass]="{'news_right_thumb': fl.main_image_position_home=='right','news_left_thumb':(fl.main_image_position_home=='left' || fl.main_image_position_home=='left_right'),'news_center_thumb':fl.main_image_position_home=='center'}">
                                        <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_1}}" /></a>
                                    </div>
                                    <div *ngIf="fl.main_heading_image_2" class="news_right_thumb">
                                        <a routerLink="/main-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                src="{{news_image_url}}{{fl.main_image_source}}/{{fl.main_heading_image_2}}" /></a>
                                    </div>
                                    <div class="news_left_content"
                                        [ngClass]="{'news_right_content': (fl.main_image_position_home=='right' || fl.main_image_position_home=='left_right'),'news_left_content':fl.main_image_position_home=='left' ,'news_center_content':fl.main_image_position_home=='center'}">
                                        <p align="left">
                                            <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                                *ngIf="fl.main_gred_above">{{fl.main_gred_above}}<br /></a>
                                            <a routerLink="/main-news-detail/{{fl._id}}" id="title"><i
                                                    *ngIf="fl.video_available" style="color:#ed1b24"
                                                    class="fa fa-video">&nbsp;</i><strong
                                                    [innerHTML]="fl.main_guj_title | safeHtml"></strong></a>
                                            <a routerLink="/main-news-detail/{{fl._id}}" id="gred_below"
                                                *ngIf="fl.main_gred_below"><strong> :
                                                </strong>{{fl.main_gred_below}}</a>
                                            <span class="date-info"><i
                                                    class="fa fa-clock-o mr-1 ml-1"></i>{{fl.main_update_time}}
                                                IST</span>
                                            <span class="order-info">&nbsp;- {{fl.main_display_order}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div *ngIf="(i % 5 === 4 && i!=0 && mainNewsAds.length>0)"
                                    class="main-news-pannel-banner-1">
                                    <div class="main-news-pannel-banner-1-thumb main_news_ads"
                                        id="main_news_panel_banner{{i}}">
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 float-left">
                                <div class="more-news text-right">
                                    <a routerLink="/main-news">વધુ સમાચાર</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12"
                    *ngIf="(section=='fatafat' || section=='gujarat' || section=='rajkot'  || section=='saurashtra'  || section=='sports'  || section=='international'  || section=='bollywood'  || section=='birthday'  || section=='marriage')">
                    <div class="main_news_left">
                        <div id="fatafat_news_area" *ngIf="section=='fatafat'">
                            <div class="main-title-outer">
                                <a href="#">
                                    <div class="main-title"><img src="assets/webPreview/images/fatafat.png" /><span
                                            class="title_head_text">સમાચાર ફટાફટ</span></div>
                                </a>
                            </div>
                            <div class="samachar_gujarat">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of fatafat_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.fatafat_image_position_home=='right' || fl.fatafat_image_position_home=='left_right'),'news_left_box':fl.fatafat_image_position_home=='left','news_center_box':fl.fatafat_image_position_home=='center','news-center':fl.fatafat_use_br=='yes'}">
                                            <div *ngIf="fl.fatafat_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.fatafat_image_position_home=='right','news_left_thumb':(fl.fatafat_image_position_home=='left' || fl.fatafat_image_position_home=='left_right'),'news_center_thumb':fl.fatafat_image_position_home=='center'}">
                                                <a routerLink="/fatafat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.fatafat_image_source}}/{{fl.fatafat_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.fatafat_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/fatafat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.fatafat_image_source}}/{{fl.fatafat_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.fatafat_image_position_home=='right' || fl.fatafat_image_position_home=='left_right'),'news_left_content':fl.fatafat_image_position_home=='left','news_center_content':fl.fatafat_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.fatafat_gred_above">{{fl.fatafat_gred_above}}<br /></a>
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.fatafat_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.fatafat_gred_below"><strong> :
                                                        </strong>{{fl.fatafat_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.fatafat_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.fatafat_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(fatafatAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb fatafat_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of fatafat_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.fatafat_image_position_home=='right' || fl.fatafat_image_position_home=='left_right'),'news_left_box':fl.fatafat_image_position_home=='left','news_center_box':fl.fatafat_image_position_home=='center','news-center':fl.fatafat_use_br=='yes'}">
                                            <div *ngIf="fl.fatafat_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.fatafat_image_position_home=='right','news_left_thumb':(fl.fatafat_image_position_home=='left' || fl.fatafat_image_position_home=='left_right'),'news_center_thumb':fl.fatafat_image_position_home=='center'}">
                                                <a routerLink="/fatafat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.fatafat_image_source}}/{{fl.fatafat_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.fatafat_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/fatafat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.fatafat_image_source}}/{{fl.fatafat_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.fatafat_image_position_home=='right' || fl.fatafat_image_position_home=='left_right'),'news_left_content':fl.fatafat_image_position_home=='left','news_center_content':fl.fatafat_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.fatafat_gred_above">{{fl.fatafat_gred_above}}<br /></a>
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.fatafat_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/fatafat-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.fatafat_gred_below"><strong> :
                                                        </strong>{{fl.fatafat_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.fatafat_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.fatafat_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="main-title-outer" *ngIf="section=='gujarat'">
                            <a routerLink="/gujarat-news">
                                <div class="main-title"><img src="assets/webPreview/images/gujrat.png" /><span
                                        class="title_head_text">સમાચાર ગુજરાત</span></div>
                            </a>
                        </div>
                        <div class="samachar_gujarat" id="gujarat_news_area" *ngIf="section=='gujarat'">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div *ngFor="let fl of gujarat_new_list_left"
                                        [ngClass]="{'news_right_box': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_box':fl.gujarat_image_position_home=='left','news_center_box':fl.gujarat_image_position_home=='center','news-center':fl.gujarat_use_br=='yes'}">
                                        <div *ngIf="fl.gujarat_heading_image_1"
                                            [ngClass]="{'news_right_thumb': fl.gujarat_image_position_home=='right','news_left_thumb':(fl.gujarat_image_position_home=='left' || fl.gujarat_image_position_home=='left_right'),'news_center_thumb':fl.gujarat_image_position_home=='center'}">
                                            <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                    src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_1}}" /></a>
                                        </div>
                                        <div *ngIf="fl.gujarat_heading_image_2" class="news_right_thumb">
                                            <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                    src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_2}}" /></a>
                                        </div>
                                        <div class="news_left_content"
                                            [ngClass]="{'news_right_content': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_content':fl.gujarat_image_position_home=='left','news_center_content':fl.gujarat_image_position_home=='center'}">
                                            <p align="left">
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                                    *ngIf="fl.gujarat_gred_above">{{fl.gujarat_gred_above}}<br /></a>
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="title"><i
                                                        *ngIf="fl.video_available" style="color:#ed1b24"
                                                        class="fa fa-video">&nbsp;</i><strong
                                                        [innerHTML]="fl.gujarat_guj_title | safeHtml"></strong></a>
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                                    *ngIf="fl.gujarat_gred_below"><strong> :
                                                    </strong>{{fl.gujarat_gred_below}}</a>
                                                <span class="date-info"><i
                                                        class="fa fa-clock-o mr-1 ml-1"></i>{{fl.gujarat_update_time}}
                                                    IST</span>
                                                <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div *ngIf="(gujaratAds.length>0)" class="main-news-pannel-banner-1">
                                        <div class="main-news-pannel-banner-1-thumb">
                                            <div class="main-news-pannel-banner-1-thumb gujarat_news_ads">
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngFor="let fl of gujarat_new_list_right"
                                        [ngClass]="{'news_right_box': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_box':fl.gujarat_image_position_home=='left','news_center_box':fl.gujarat_image_position_home=='center','news-center':fl.gujarat_use_br=='yes'}">
                                        <div *ngIf="fl.gujarat_heading_image_1"
                                            [ngClass]="{'news_right_thumb': fl.gujarat_image_position_home=='right','news_left_thumb':(fl.gujarat_image_position_home=='left' || fl.gujarat_image_position_home=='left_right'),'news_center_thumb':fl.gujarat_image_position_home=='center'}">
                                            <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                    src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_1}}" /></a>
                                        </div>
                                        <div *ngIf="fl.gujarat_heading_image_2" class="news_right_thumb">
                                            <a routerLink="/gujarat-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                    src="{{news_image_url}}{{fl.gujarat_image_source}}/{{fl.gujarat_heading_image_2}}" /></a>
                                        </div>
                                        <div class="news_left_content"
                                            [ngClass]="{'news_right_content': (fl.gujarat_image_position_home=='right' || fl.gujarat_image_position_home=='left_right'),'news_left_content':fl.gujarat_image_position_home=='left','news_center_content':fl.gujarat_image_position_home=='center'}">
                                            <p align="left">
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                                    *ngIf="fl.gujarat_gred_above">{{fl.gujarat_gred_above}}<br /></a>
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="title"><i
                                                        *ngIf="fl.video_available" style="color:#ed1b24"
                                                        class="fa fa-video">&nbsp;</i><strong
                                                        [innerHTML]="fl.gujarat_guj_title | safeHtml"></strong></a>
                                                <a routerLink="/gujarat-news-detail/{{fl._id}}" id="gred_below"
                                                    *ngIf="fl.gujarat_gred_below"><strong> :
                                                    </strong>{{fl.gujarat_gred_below}}</a>
                                                <span class="date-info"><i
                                                        class="fa fa-clock-o mr-1 ml-1"></i>{{fl.gujarat_update_time}}
                                                    IST</span>
                                                <span class="order-info">&nbsp;- {{fl.gujarat_display_order}}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="more-news text-right">
                                        <a routerLink="/gujarat-news">વધુ સમાચાર</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="samachar_rajkot" *ngIf="section=='rajkot'">
                            <div class="main-title-outer">
                                <a routerLink="/rajkot-news">
                                    <div class="main-title"><img src="assets/webPreview/images/rajkot.png" /><span
                                            class="title_head_text">સમાચાર રાજકોટ</span></div>
                                </a>
                            </div>
                            <div class="inner_samachar_rajkot" id="rajkot_news_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of rajkot_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_box':fl.rajkot_image_position_home=='left','news_center_box':fl.rajkot_image_position_home=='center','news-center':fl.rajkot_use_br=='yes'}">
                                            <div *ngIf="fl.rajkot_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.rajkot_image_position_home=='right','news_left_thumb':(fl.rajkot_image_position_home=='left' || fl.rajkot_image_position_home=='left_right'),'news_center_thumb':fl.rajkot_image_position_home=='center'}">
                                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.rajkot_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_content':fl.rajkot_image_position_home=='left','news_center_content':fl.rajkot_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.rajkot_gred_above">{{fl.rajkot_gred_above}}<br /></a>
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.rajkot_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.rajkot_gred_below"><strong> :
                                                        </strong>{{fl.rajkot_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.rajkot_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(rajkotAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb rajkot_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of rajkot_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_box':fl.rajkot_image_position_home=='left','news_center_box':fl.rajkot_image_position_home=='center','news-center':fl.rajkot_use_br=='yes'}">
                                            <div *ngIf="fl.rajkot_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.rajkot_image_position_home=='right','news_left_thumb':(fl.rajkot_image_position_home=='left' || fl.rajkot_image_position_home=='left_right'),'news_center_thumb':fl.rajkot_image_position_home=='center'}">
                                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.rajkot_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/rajkot-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.rajkot_image_source}}/{{fl.rajkot_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.rajkot_image_position_home=='right' || fl.rajkot_image_position_home=='left_right'),'news_left_content':fl.rajkot_image_position_home=='left','news_center_content':fl.rajkot_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.rajkot_gred_above">{{fl.rajkot_gred_above}}<br /></a>
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.rajkot_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/rajkot-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.rajkot_gred_below"><strong> :
                                                        </strong>{{fl.rajkot_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.rajkot_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.rajkot_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/rajkot-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="samachar_rajkot" *ngIf="section=='saurashtra'">
                            <div class="main-title-outer">
                                <a routerLink="/saurashtra-news">
                                    <div class="main-title"><img src="assets/webPreview/images/kutch.png" /><span
                                            class="title_head_text">સૌરાષ્ટ્ર કચ્છ</span></div>
                                </a>
                            </div>
                            <div class="inner_samachar_rajkot" id="rajkot_news_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of saurashtra_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_box':fl.saurashtra_image_position_home=='left','news_center_box':fl.saurashtra_image_position_home=='center','news-center':fl.saurashtra_use_br=='yes'}">
                                            <div *ngIf="fl.saurashtra_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.saurashtra_image_position_home=='right','news_left_thumb':(fl.saurashtra_image_position_home=='left' || fl.saurashtra_image_position_home=='left_right'),'news_center_thumb':fl.saurashtra_image_position_home=='center'}">
                                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.saurashtra_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_content':fl.saurashtra_image_position_home=='left','news_center_content':fl.saurashtra_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.saurashtra_gred_above">{{fl.saurashtra_gred_above}}<br /></a>
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.saurashtra_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.saurashtra_gred_below"><strong> :
                                                        </strong>{{fl.saurashtra_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.saurashtra_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.saurashtra_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(saurashtraAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb saurashtra_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of saurashtra_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_box':fl.saurashtra_image_position_home=='left','news_center_box':fl.saurashtra_image_position_home=='center','news-center':fl.saurashtra_use_br=='yes'}">
                                            <div *ngIf="fl.saurashtra_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.saurashtra_image_position_home=='right','news_left_thumb':(fl.saurashtra_image_position_home=='left' || fl.saurashtra_image_position_home=='left_right'),'news_center_thumb':fl.saurashtra_image_position_home=='center'}">
                                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.saurashtra_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/saurashtra-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.saurashtra_image_source}}/{{fl.saurashtra_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.saurashtra_image_position_home=='right' || fl.saurashtra_image_position_home=='left_right'),'news_left_content':fl.saurashtra_image_position_home=='left','news_center_content':fl.saurashtra_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.saurashtra_gred_above">{{fl.saurashtra_gred_above}}<br /></a>
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.saurashtra_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/saurashtra-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.saurashtra_gred_below"><strong> :
                                                        </strong>{{fl.saurashtra_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.saurashtra_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.saurashtra_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/saurashtra-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="samachar_desh_videsh" *ngIf="section=='international'">
                            <div class="main-title-outer">
                                <a routerLink="/international-news">
                                    <div class="main-title"><img src="assets/webPreview/images/world.png" />
                                        <span class="title_head_text">દેશ-વિદેશ</span>
                                    </div>
                                </a>
                            </div>
                            <div class="inner_samachar_desh_videsh blue-border" id="international_news_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of international_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_box':fl.international_image_position_home=='left','news_center_box':fl.international_image_position_home=='center','news-center':fl.international_use_br=='yes'}">
                                            <div *ngIf="fl.international_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.international_image_position_home=='right','news_left_thumb':(fl.international_image_position_home=='left' || fl.international_image_position_home=='left_right'),'news_center_thumb':fl.international_image_position_home=='center'}">
                                                <a routerLink="/international-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.international_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/international-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_content':fl.international_image_position_home=='left','news_center_content':fl.international_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/international-news-detail/{{fl._id}}"
                                                        id="gred_below"
                                                        *ngIf="fl.international_gred_above">{{fl.international_gred_above}}<br /></a>
                                                    <a routerLink="/international-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.international_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/international-news-detail/{{fl._id}}"
                                                        id="gred_below" *ngIf="fl.international_gred_below"><strong> :
                                                        </strong>{{fl.international_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.international_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.international_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(internationalAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb international_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of international_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_box':fl.international_image_position_home=='left','news_center_box':fl.international_image_position_home=='center','news-center':fl.international_use_br=='yes'}">
                                            <div *ngIf="fl.international_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.international_image_position_home=='right','news_left_thumb':(fl.international_image_position_home=='left' || fl.international_image_position_home=='left_right'),'news_center_thumb':fl.international_image_position_home=='center'}">
                                                <a routerLink="/international-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.international_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/international-news-detail/{{fl._id}}">
                                                    <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.international_image_source}}/{{fl.international_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.international_image_position_home=='right' || fl.international_image_position_home=='left_right'),'news_left_content':fl.international_image_position_home=='left','news_center_content':fl.international_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/international-news-detail/{{fl._id}}"
                                                        id="gred_below"
                                                        *ngIf="fl.international_gred_above">{{fl.international_gred_above}}<br /></a>
                                                    <a routerLink="/international-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.international_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/international-news-detail/{{fl._id}}"
                                                        id="gred_below" *ngIf="fl.international_gred_below"><strong> :
                                                        </strong>{{fl.international_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.international_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.international_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/international-news-detail">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="samachar_khel_jagat" *ngIf="section=='sports'">
                            <div class="main-title-outer">
                                <a routerLink="/sports-news-detail">
                                    <div class="main-title">
                                        <img src="assets/webPreview/images/game.png" />
                                        <span class="title_head_text">ખેલ-જગત</span>
                                    </div>
                                </a>
                            </div>
                            <div class="inner_samachar_khel_jagat blue-border" id="sports_news_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of sports_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_box':fl.sports_image_position_home=='left','news_center_box':fl.sports_image_position_home=='center','news-center':fl.sports_use_br=='yes'}">
                                            <div *ngIf="fl.sports_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.sports_image_position_home=='right','news_left_thumb':(fl.sports_image_position_home=='left' || fl.sports_image_position_home=='left_right'),'news_center_thumb':fl.sports_image_position_home=='center'}">
                                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.sports_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_content':fl.sports_image_position_home=='left','news_center_content':fl.sports_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.sports_gred_above">{{fl.sports_gred_above}}<br /></a>
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.sports_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.sports_gred_below"><strong> :
                                                        </strong>{{fl.sports_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.sports_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(sportsAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb sports_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of sports_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_box':fl.sports_image_position_home=='left','news_center_box':fl.sports_image_position_home=='center','news-center':fl.sports_use_br=='yes'}">
                                            <div *ngIf="fl.sports_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.sports_image_position_home=='right','news_left_thumb':(fl.sports_image_position_home=='left' || fl.sports_image_position_home=='left_right'),'news_center_thumb':fl.sports_image_position_home=='center'}">
                                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.sports_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/sports-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.sports_image_source}}/{{fl.sports_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.sports_image_position_home=='right' || fl.sports_image_position_home=='left_right'),'news_left_content':fl.sports_image_position_home=='left','news_center_content':fl.sports_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.sports_gred_above">{{fl.sports_gred_above}}<br /></a>
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.sports_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/sports-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.sports_gred_below"><strong> :
                                                        </strong>{{fl.sports_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.sports_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;- {{fl.sports_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/sports-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="samachar_film_jagat" *ngIf="section=='bollywood'">
                            <div class="main-title-outer">
                                <a routerLink="/bollywood-news">
                                    <div class="main-title"><img src="assets/webPreview/images/moive.png" />
                                        <span class="title_head_text">ફિલ્મ જગત</span>
                                    </div>
                                </a>
                            </div>
                            <div class="inner_samachar_film_jagat blue-border" id="bollywood_news_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of bollywood_new_list_left"
                                            [ngClass]="{'news_right_box': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_box':fl.bollywood_image_position_home=='left','news_center_box':fl.bollywood_image_position_home=='center','news-center':fl.bollywood_use_br=='yes'}">
                                            <div *ngIf="fl.bollywood_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.bollywood_image_position_home=='right','news_left_thumb':(fl.bollywood_image_position_home=='left' || fl.bollywood_image_position_home=='left_right'),'news_center_thumb':fl.bollywood_image_position_home=='center'}">
                                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img
                                                        class="img-fluid"
                                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.bollywood_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img
                                                        class="img-fluid"
                                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_content':fl.bollywood_image_position_home=='left','news_center_content':fl.bollywood_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.bollywood_gred_above">{{fl.bollywood_gred_above}}<br /></a>
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.bollywood_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.bollywood_gred_below"><strong> :
                                                        </strong>{{fl.bollywood_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.bollywood_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.bollywood_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngIf="(bollywoodAds.length>0)" class="main-news-pannel-banner-1">
                                            <div class="main-news-pannel-banner-1-thumb">
                                                <div class="main-news-pannel-banner-1-thumb bollywood_news_ads">
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let fl of bollywood_new_list_right"
                                            [ngClass]="{'news_right_box': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_box':fl.bollywood_image_position_home=='left','news_center_box':fl.bollywood_image_position_home=='center','news-center':fl.bollywood_use_br=='yes'}">
                                            <div *ngIf="fl.bollywood_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.bollywood_image_position_home=='right','news_left_thumb':(fl.bollywood_image_position_home=='left' || fl.bollywood_image_position_home=='left_right'),'news_center_thumb':fl.bollywood_image_position_home=='center'}">
                                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img
                                                        class="img-fluid"
                                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.bollywood_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/bollywood-news-detail/{{fl._id}}"> <img
                                                        class="img-fluid"
                                                        src="{{news_image_url}}{{fl.bollywood_image_source}}/{{fl.bollywood_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.bollywood_image_position_home=='right' || fl.bollywood_image_position_home=='left_right'),'news_left_content':fl.bollywood_image_position_home=='left','news_center_content':fl.bollywood_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.bollywood_gred_above">{{fl.bollywood_gred_above}}<br /></a>
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="title"><i
                                                            *ngIf="fl.video_available" style="color:#ed1b24"
                                                            class="fa fa-video">&nbsp;</i><strong
                                                            [innerHTML]="fl.bollywood_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/bollywood-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.bollywood_gred_below"><strong> :
                                                        </strong>{{fl.bollywood_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.bollywood_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.bollywood_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/bollywood-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="akila_janamdivas" *ngIf="(section=='birthday')">
                            <div class="main-title-outer">
                                <a routerLink="/birthday-news">
                                    <div class="main-title"><img src="assets/webPreview/images/birthday.png" /><span
                                            class="title_head_text">જન્મદિવસ શુભેચ્છા</span></div>
                                </a>
                            </div>
                            <div class="inner_akila_janamdivas blue-border" id="birthday_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of birthday_list_left"
                                            [ngClass]="{'news_right_box': (fl.birthday_image_position_home=='right' || fl.birthday_image_position_home=='left_right'),'news_left_box':fl.birthday_image_position_home=='left','news_center_box':fl.birthday_image_position_home=='center','news-center':fl.birthday_use_br=='yes'}">
                                            <div *ngIf="fl.birthday_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.birthday_image_position_home=='right','news_left_thumb':(fl.birthday_image_position_home=='left' || fl.birthday_image_position_home=='left_right'),'news_center_thumb':fl.birthday_image_position_home=='center'}">
                                                <a routerLink="/birthday-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.birthday_image_source}}/{{fl.birthday_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.birthday_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/birthday-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.birthday_image_source}}/{{fl.birthday_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.birthday_image_position_home=='right' || fl.birthday_image_position_home=='left_right'),'news_left_content':fl.birthday_image_position_home=='left','news_center_content':fl.birthday_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/birthday-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.birthday_gred_above">{{fl.birthday_gred_above}}<br /></a>
                                                    <a routerLink="/birthday-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.birthday_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/birthday-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.birthday_gred_below"><strong> :
                                                        </strong>{{fl.birthday_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.birthday_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.birthday_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of birthday_list_right"
                                            [ngClass]="{'news_right_box': (fl.birthday_image_position_home=='right' || fl.birthday_image_position_home=='left_right'),'news_left_box':fl.birthday_image_position_home=='left','news_center_box':fl.birthday_image_position_home=='center','news-center':fl.birthday_use_br=='yes'}">
                                            <div *ngIf="fl.birthday_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.birthday_image_position_home=='right','news_left_thumb':(fl.birthday_image_position_home=='left' || fl.birthday_image_position_home=='left_right'),'news_center_thumb':fl.birthday_image_position_home=='center'}">
                                                <a routerLink="/birthday-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.birthday_image_source}}/{{fl.birthday_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.birthday_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/birthday-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.birthday_image_source}}/{{fl.birthday_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.birthday_image_position_home=='right' || fl.birthday_image_position_home=='left_right'),'news_left_content':fl.birthday_image_position_home=='left','news_center_content':fl.birthday_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/birthday-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.birthday_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/birthday-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.birthday_gred_below"><strong> :
                                                        </strong>{{fl.birthday_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.birthday_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.birthday_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/birthday-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="akila_janamdivas" *ngIf="(section=='marriage')">
                            <div class="main-title-outer">
                                <a routerLink="/marriage-news">
                                    <div class="main-title"><img src="assets/webPreview/images/Marriage.png" /><span
                                            class="title_head_text">શુભ લગ્નોસ્તવ</span></div>
                                </a>
                            </div>
                            <div class="inner_akila_janamdivas blue-border" id="marriage_area">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of marriage_list_left"
                                            [ngClass]="{'news_right_box': (fl.marriage_image_position_home=='right' || fl.marriage_image_position_home=='left_right'),'news_left_box':fl.marriage_image_position_home=='left','news_center_box':fl.marriage_image_position_home=='center','news-center':fl.marriage_use_br=='yes'}">
                                            <div *ngIf="fl.marriage_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.marriage_image_position_home=='right','news_left_thumb':(fl.marriage_image_position_home=='left' || fl.marriage_image_position_home=='left_right'),'news_center_thumb':fl.marriage_image_position_home=='center'}">
                                                <a routerLink="/marriage-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.marriage_image_source}}/{{fl.marriage_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.marriage_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/marriage-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.marriage_image_source}}/{{fl.marriage_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.marriage_image_position_home=='right' || fl.marriage_image_position_home=='left_right'),'news_left_content':fl.marriage_image_position_home=='left','news_center_content':fl.marriage_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/marriage-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.marriage_gred_above">{{fl.marriage_gred_above}}<br /></a>
                                                    <a routerLink="/marriage-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.marriage_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/marriage-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.marriage_gred_below"><strong> :
                                                        </strong>{{fl.marriage_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.marriage_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.marriage_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div *ngFor="let fl of marriage_list_right"
                                            [ngClass]="{'news_right_box': (fl.marriage_image_position_home=='right' || fl.marriage_image_position_home=='left_right'),'news_left_box':fl.marriage_image_position_home=='left','news_center_box':fl.marriage_image_position_home=='center','news-center':fl.marriage_use_br=='yes'}">
                                            <div *ngIf="fl.marriage_heading_image_1"
                                                [ngClass]="{'news_right_thumb': fl.marriage_image_position_home=='right','news_left_thumb':(fl.marriage_image_position_home=='left' || fl.marriage_image_position_home=='left_right'),'news_center_thumb':fl.marriage_image_position_home=='center'}">
                                                <a routerLink="/marriage-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.marriage_image_source}}/{{fl.marriage_heading_image_1}}" /></a>
                                            </div>
                                            <div *ngIf="fl.marriage_heading_image_2" class="news_right_thumb">
                                                <a routerLink="/marriage-news-detail/{{fl._id}}"> <img class="img-fluid"
                                                        src="{{news_image_url}}{{fl.marriage_image_source}}/{{fl.marriage_heading_image_2}}" /></a>
                                            </div>
                                            <div class="news_left_content"
                                                [ngClass]="{'news_right_content': (fl.marriage_image_position_home=='right' || fl.marriage_image_position_home=='left_right'),'news_left_content':fl.marriage_image_position_home=='left','news_center_content':fl.marriage_image_position_home=='center'}">
                                                <p align="left">
                                                    <a routerLink="/marriage-news-detail/{{fl._id}}" id="title"><strong
                                                            [innerHTML]="fl.marriage_guj_title | safeHtml"></strong></a>
                                                    <a routerLink="/marriage-news-detail/{{fl._id}}" id="gred_below"
                                                        *ngIf="fl.marriage_gred_below"><strong> :
                                                        </strong>{{fl.marriage_gred_below}}</a>
                                                    <span class="date-info"><i
                                                            class="fa fa-clock-o mr-1 ml-1"></i>{{fl.marriage_update_time}}
                                                        IST</span>
                                                    <span class="order-info">&nbsp;-
                                                        {{fl.marriage_display_order}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="more-news text-right">
                                            <a routerLink="/marriage-news">વધુ સમાચાર</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>