<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/bollywood-news-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Bollywood News</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<select class="form-control" id="search_type" (change)="showFilterDiv($event)">
										<option selected value="title">Gujarati Title</option>
										<option value="date">Date</option>
									</select>
								</div>
								<div class="col-md-3" *ngIf="show_filter_text">
									<input type="text" id="search_title" placeholder="Enter Title" class="form-control">
								</div>
								<div class="col-md-3" *ngIf="show_filter_date">
									<input type="text" name="sdate2" id="datepicker1" placeholder="Select Date"
										class="form-control">
								</div>
								<div class="col-md-1">
									<button class="btn btn-warning" (click)="get_filtered_news()" title="Serach"><i
											class="fa fa-search"></i></button>
								</div>
							</div>
							<div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<button class="btn btn-primary" type="button" (click)="innerPreview()"
										title="View inner page preview"><i class="fa fa-eye"></i> Inner Page
										Preview</button>
								</div>
								<div class="col-md-3">
									<button class="btn btn-primary" type="button" (click)="homePreview()"
										title="View home page preview"><i class="fa fa-eye"></i> Preview</button>
								</div>
							</div>
							<table class="table">
								<thead>
									<tr>
										<th><button *ngIf="show_multiple_delete_btn"
												class="btn btn-outline-danger waves-effect waves-light"
												(click)="delete_multiple_news()"><i class="fa fa-trash"></i></button>
										</th>
										<th>Gujarati Title</th>
										<th>Date</th>
										<th>Position</th>
										<th>Display in<br>Homepage?</th>
										<th>Display<br>Order</th>
										<th>Repeat</th>
										<th>Active<br>
											<input type="checkbox" id="mainCheckbox"
												(change)="activeDeactiveAll($event)">
										</th>
										<th>Share</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of bollywoodList">
										<input type="hidden" value="{{mn._id}}" class="news_ids">
										<td class="text-center"><input type="checkbox"
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td>
											<a routerLink="/bollywood-news-edit/{{mn._id}}"
												[innerHTML]="mn.bollywood_guj_title"></a>
										</td>
										<td>{{mn.bollywood_news_date}}</td>
										<td>
											<select class="form-control news_list"
												(change)="update_news_position($event,mn._id)">
												<option [selected]="mn.bollywood_news_position == 'right'"
													value="right">Right</option>
												<option [selected]="mn.bollywood_news_position == 'left'" value="left">
													Left</option>
											</select>
										</td>
										<td class="text-center">
											<input type="checkbox" [checked]="mn.bollywood_display_home == 'yes'"
												(change)="update_news_display_home($event,mn._id)" value="yes">
										</td>
										<td>
											<input ng-model="display_order"
												(blur)="update_display_orders($event,mn._id,mn.bollywood_display_order)"
												(keyup.enter)="update_display_orders($event,mn._id,mn.bollywood_display_order)"
												class="form-control" type="text" value="{{mn.bollywood_display_order}}">
										</td>
										<td>
											<input ng-model="repeat_news" (blur)="update_repeat_news($event,mn._id)"
												(keyup.enter)="update_repeat_news($event,mn._id)" class="form-control"
												type="text" value="{{mn.bollywood_repeat_days}}">
										</td>
										<td class="text-center">
											<input type="checkbox" class="newsStatus"
												[checked]="mn.bollywood_status == 'yes'"
												(change)="update_news_status($event,mn._id)" value="no">
										</td>
										<td class="text-center">
											<a href="javascript:void(0)"
												(click)="shareOnWhatsApp(mn._id,mn.bollywood_guj_title,mn.bollywood_gred_below)">
												<img src="assets/images/wp.png">
											</a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>