import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoticeRoutingModule } from './notice-routing.module';
import { NoticeAddComponent } from './notice-add/notice-add.component';
import { NoticeListComponent } from './notice-list/notice-list.component';
@NgModule({
  declarations: [NoticeAddComponent, NoticeListComponent],
  imports: [
    CommonModule,
    NoticeRoutingModule
  ]
})
export class NoticeModule { }
