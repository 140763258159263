import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-country-add',
  templateUrl: './country-add.component.html',
  styleUrls: ['./country-add.component.css']
})
export class CountryAddComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
