import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { ObituaryAddComponent } from './obituary-add/obituary-add.component';
import { ObituaryListComponent } from './obituary-list/obituary-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'obituary-add', component: ObituaryAddComponent },
  { path: 'obituary-list', component: ObituaryListComponent },
  { path: 'obituary-edit/:news_id', component: ObituaryAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObituaryRoutingModule { }
