import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { FullComponent } from '../../layout/full/full.component';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private CommonService: CommonService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public FullComponent: FullComponent
  ) {
    if (localStorage.getItem('login_token')) {
      this.router.navigate(['/dashboard']);
    }
  }
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: '',
      password: ''
    });
  }
  submit_form() {
    var th = this;
    var routers = this.router;
    $.validate({
      onSuccess: function () {
        th.FullComponent.loaderShow();
        $('.submit_btn').attr('disabled', true);
        var parameter = th.loginForm.value;
        th.CommonService.AdminLogin(parameter).subscribe((data) => {
          if (data.status) {
            th.toastr.success(data.message)
            localStorage.setItem('login_name', data.users.user_name);
            localStorage.setItem('login_id', data.users._id);
            localStorage.setItem('login_token', data.users.token);
            localStorage.setItem('login_is_admin', data.users.is_admin);
            localStorage.setItem('login_module_array', data.users.admin_modules);
            localStorage.setItem('EntryDate', data.users.entry_date);
            routers.navigate(['/dashboard']);
          } else {
            th.toastr.error(data.message);
          }
          $('.submit_btn').attr('disabled', false);
          th.FullComponent.loaderHide();
        });
        return false;
      }
    });
  }
}
