import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from 'ckeditor4-angular';
import { CmsRoutingModule } from './cms-routing.module';
import { CmsAddComponent } from './cms-add/cms-add.component';
import { CmsListComponent } from './cms-list/cms-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [CmsAddComponent, CmsListComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    CmsRoutingModule
  ]
})
export class CmsModule { }
