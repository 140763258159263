import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-paper-list',
  templateUrl: './paper-list.component.html',
  styleUrls: ['./paper-list.component.css']
})
export class PaperListComponent implements OnInit {
  EpaperList = [];
  show_multiple_delete_btn = false;
  NewsDeleteCheckboxList = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent) {
    this.DefaultComponent.loaderShow();
    this.get_epaper_list();
  }
  get_epaper_list() {
    var parameters = { 'date': localStorage.getItem('EntryDate') };
    this.CommonService.GetEpaperList(parameters).subscribe((data) => {
      if (data.status) {
        this.EpaperList = data.epaper_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    if (this.NewsDeleteCheckboxList.includes(id)) {
      var index = this.NewsDeleteCheckboxList.indexOf(id);
      this.NewsDeleteCheckboxList.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.NewsDeleteCheckboxList.push(id);
    }
    if (this.NewsDeleteCheckboxList.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    var th = this;
    var delete_checkbox = this.NewsDeleteCheckboxList;
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    $.ajax({
      url: environment.EpaperBaseUrl + 'delete_epaper',
      method: 'POST',
      data: { delete_checkbox: delete_checkbox },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.NewsDeleteCheckboxList = [];
          th.toastr.success(res.message)
          th.get_epaper_list();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    this.DefaultComponent.loaderShow();
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    $.ajax({
      url: environment.EpaperBaseUrl + 'epaper_status_change',
      method: 'POST',
      data: { id: id, status: status },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.toastr.success(res.message)
          th.get_epaper_list();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
        $('.btn-success').attr('disabled', false);
      }
    });
  }
  ngOnInit(): void {
  }
}
