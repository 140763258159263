<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="card-body">
                            <form action="" method="post" id="dynamic_section_form" enctype="multipart/form-data">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="card m-b-10">
                                            <div class="page-title-box row">
                                                <div class="col-md-6">
                                                    <div class="btn-group ">
                                                        <h4 class="page-title">Dynamic section</h4>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="btn-group pull-right">
                                                        <div>
                                                            <button type="submit"
                                                                class="btn btn-success waves-effect waves-light m-r-5">Save
                                                                Page</button>
                                                            <a href="javascript:void(0);"
                                                                routerLink="/dynamic-section-list" type="button"
                                                                class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label for="section_type" class="col-sm-4 col-form-label">Section Type:
                                        </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" id="section_type" name="section_type">
                                                <option value="news">News</option>
                                                <option value="epaper">Epaper</option>
                                                <option value="birthday">Birthday</option>
                                                <option value="kasumbo">Kasumbo</option>
                                                <option value="obituary">Obituary</option>
                                                <option value="photo_gallery">Photo Gallery</option>
                                                <option value="ahatsapp_aavruti">Whatsapp Aavruti</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">App Title: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="app_title" value="" id="app_title">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Web Title: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="web_title" value="" id="web_title">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Display Order App: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="display_order_app" value="" id="display_order_app">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Display Order Web: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" data-validation="required" type="text"
                                                name="display_order_web" value="" id="display_order_web">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label for="display_in" class="col-sm-4 col-form-label">Display In:
                                        </label>
                                        <div class="col-sm-8">
                                            <select class="form-control" id="display_in" name="display_in">
                                                <option value="app">App</option>
                                                <option value="web">Web</option>
                                                <option value="both">Both</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">List API: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" name="list_api" value=""
                                                id="list_api">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Detail API: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" name="detail_api" value=""
                                                id="detail_api">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6 row">
                                        <label class="col-sm-4 col-form-label">Front Detail URL: </label>
                                        <div class="col-sm-8">
                                            <input class="form-control" type="text" name="front_detail_url" value=""
                                                id="front_detail_url">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div>
                                        <button (click)="submit_form()"
                                            class="btn btn-success waves-effect waves-light m-r-5">Submit</button>
                                        <a href="javascript:void(0);" routerLink="/dynamic-section-list" type="button"
                                            class="btn btn-secondary waves-effect m-l-5">Cancel</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>