import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { CmsListComponent } from './cms-list/cms-list.component';
import { CmsAddComponent } from './cms-add/cms-add.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [
    { path: 'cms-list', component: CmsListComponent },
    { path: 'cms-edit/:news_id', component: CmsAddComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CmsRoutingModule { }
