<div class="accountbg"></div>
<div class="wrapper-page p-3">
   <div class="container">
      <div class="card">
         <h3 class="text-center mt-0 m-b-15"><a href="index.html" class="logo logo-admin"><img src="assets/img/logo.png"
                  alt="akila"></a></h3>
         <h1 class="bg_title">Akila News <span><a href="http://www.akilanews.com/"> http://www.akilanews.com/</a></span>
         </h1>

         <div class="middle_login">
            <div class="p-3 row">
               <div class="col-md-6"><img src="assets/img/lock.jpg" alt="akila"></div>
               <div class="col-md-6">
                  <form name="form" [formGroup]="loginForm" class="form-horizontal m-t-20">
                     <h4>Administration Login</h4>
                     <div class="form-group row">
                        <label for="example-text-input" class="col-sm-3 col-form-label">Username :</label>
                        <div class="col-sm-9">
                           <input data-validation="required" formControlName="username" name="username"
                              class="form-control" type="text" placeholder="Username">
                        </div>
                     </div>
                     <div class="form-group row">
                        <label for="example-text-input" class="col-sm-3 col-form-label">Password :</label>
                        <div class="col-sm-9">
                           <input data-validation="required" type="password" formControlName="password" name="password"
                              class="form-control" placeholder="Password">
                        </div>
                     </div>
                     <div class="form-group text-center row m-t-20">
                        <div class="col-12">
                           <button class="btn btn-danger btn-block waves-effect waves-light"
                              (click)="submit_form()">Login</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <footer class="footr text-center m-t-15">
            <p>
               Copyright © 2017-18 akilanews.com, All rights reserved.
               <br> Developed by:
               <a href="https://www.gteches.com/" target="_blank">Gtech</a>
            </p>
         </footer>
      </div>
   </div>
</div>