import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { CKEditorModule } from 'ng2-ckeditor';
import { CKEditorModule } from 'ckeditor4-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObituaryRoutingModule } from './obituary-routing.module';
import { ObituaryAddComponent } from './obituary-add/obituary-add.component';
import { ObituaryListComponent } from './obituary-list/obituary-list.component';
@NgModule({
  declarations: [ObituaryAddComponent, ObituaryListComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    ObituaryRoutingModule
  ]
})
export class ObituaryModule { }
