import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { DefaultComponent } from '../../layout/default/default.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-short-news-list',
  templateUrl: './short-news-list.component.html',
  styleUrls: ['./short-news-list.component.css']
})
export class ShortNewsListComponent implements OnInit {
  shortList = [];
  news_delete_checkbox = [];
  NewsImageURL: string;
  show_multiple_delete_btn = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_short_news_list();
    this.NewsImageURL = environment.baseUrl;
  }
  ngOnInit(): void {
  }
  get_short_news_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetShortNewsList(parameter).subscribe((data) => {
      if (data.status) {
        this.shortList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  delete_multiple_news() {
    Swal.fire({
      title: 'Are you sure to delete this news?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      confirmButtonText: "Yes, delete it!",
      showCancelButton: true,
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        var th = this;
        var routers = this.router;
        this.DefaultComponent.loaderShow();
        var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate') };
        this.CommonService.DeleteShortNews(parameter).subscribe((data) => {
          if (data.status) {
            th.toastr.success(data.message);
            this.news_delete_checkbox = [];
            th.get_short_news_list();
          } else {
            if (data.token_expire) {
              localStorage.removeItem('login_token');
              routers.navigate(['/']);
            } else {
              th.toastr.error(data.message);
            }
          }
          this.DefaultComponent.loaderHide();
        });
      }
    });
  }
}
