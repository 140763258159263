<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="opinion_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Opinion Poll Add - {{poll_number}}</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																Opinion Poll</button>
															<a href="javascript:void(0);"
																routerLink="/opinion-poll-list" type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Question: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="question" value="{{NewsData?.question}}" id="question">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Option 1: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="option_1" value="{{NewsData?.option_1}}" id="option_1">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Option 2: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="option_2" value="{{NewsData?.option_2}}" id="option_2">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Option 3: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="option_3" value="{{NewsData?.option_3}}" id="option_3">
										</div>
									</div>
								</div>
								<input type="hidden" name="newsID" value="{{NewsData?._id}}">
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Date: </label>
										<div class="col-sm-8">
											<input class="form-control" readonly data-validation="required" type="text"
												name="news_date" value="{{news_date}}" id="news_date">
										</div>
									</div>
								</div>
								<input class="form-control-auto" type="hidden" name="poll_number"
									value="{{poll_number}}">
								<div class="form-group">
									<div>
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save
											Opinion
											Poll</button>
										<a href="javascript:void(0);" routerLink="/opinion-poll-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>