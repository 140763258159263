import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EncryptionService } from './encryption.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  serverUrl = environment;
  constructor(private http: HttpClient,
    private EncryptionService: EncryptionService) { }
  public getMainNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getMainNewsList', payload);
  }
  public getRajkotNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getRajkotNewsList', payload);
  }
  public getSaurashtraNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getSaurashtraNewsList', payload);
  }
  public getGujaratNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getGujaratNewsList', payload);
  }
  public getBollywoodNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getBollywoodNewsList', payload);
  }
  public getSportsNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getSportsNewsList', payload);
  }
  public getInternationalNewsList(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getInternationalNewsList', payload);
  }
  public getBirthDayNews(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getBirthdayNews', payload);
  }
  public getMarriageNews(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getMarriageNews', payload);
  }
  public getAdvertisement(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getHomeAdvertise', payload);
  }
  public getFestivalPhotos(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getFestivalPhotos', payload);
  }
  public getMainNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getMainNewsHome', payload);
  }
  public getFatafatNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getFatafatNewsHome', payload);
  }
  public getGujaratNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getGujaratNewsHome', payload);
  }
  public getRajkotNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getRajkotNewsHome', payload);
  }
  public getSaurashtraNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getSaurashtraNewsHome', payload);
  }
  public getSportsNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getSportsNewsHome', payload);
  }
  public getBollywoodNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getBollywoodNewsHome', payload);
  }
  public getInternationalNewsHome(param) {
    const payload = { data: this.EncryptionService.convert_parameter(param) }
    return this.http.post(this.serverUrl.FrontApiBaseUrl + 'getInternationalNewsHome', payload);
  }
  AdminLogin(parameter) {
    return this.http.post<any>(`${this.serverUrl.AdminApiBaseUrl}admin_login`, parameter);
  }
  getDynamicSectionList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.DynamicSectionBaseUrl}get_dynamic_section_list`, '', requestOptions);
  }
  GetNewsEntryDate() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_entry_date`, '', requestOptions);
  }
  GetLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}get_last_display_order`, parameter, requestOptions);
  }
  GetNriLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}get_nri_last_display_order`, parameter, requestOptions);
  }
  GetPaperPageLastDisplayOrder() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_paper_page_last_display_order`, '', requestOptions);
  }
  GetEcategoryLastDisplayOrder() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_ecategory_last_display_order`, '', requestOptions);
  }
  GetAdvertise() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}get_advertise`, '', requestOptions);
  }
  GetTodayEdition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_edition`, parameter, requestOptions);
  }
  DeleteFatafatNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}delete_fatafat_news`, parameter, requestOptions);
  }
  DeleteNriNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}delete_nri_news`, parameter, requestOptions);
  }
  UpdateNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_news_position`, parameter, requestOptions);
  }
  UpdateAdvertiseDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}update_advertise_display_order`, parameter, requestOptions);
  }
  UpdateFatafatNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}update_fatafat_news_display_order`, parameter, requestOptions);
  }
  UpdateNriNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}update_nri_news_display_order`, parameter, requestOptions);
  }
  UpdateEpaperCategoryDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}update_epaper_category_display_order`, parameter, requestOptions);
  }
  UpdateSectionDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.DynamicSectionBaseUrl}update_section_display_order`, parameter, requestOptions);
  }
  UpdateFatafatNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}update_fatafat_news_repeat`, parameter, requestOptions);
  }
  UpdateNriNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}update_nri_news_repeat`, parameter, requestOptions);
  }
  UpdateNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_news_display_home`, parameter, requestOptions);
  }
  UpdateNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_news_display_app_home`, parameter, requestOptions);
  }
  UpdateNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_news_status`, parameter, requestOptions);
  }
  UpdateEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_entry_date`, parameter, requestOptions);
  }
  UpdateTodayEdition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}update_edition`, parameter, requestOptions);
  }
  getNriNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}get_nri_news_detail`, parameter, requestOptions);
  }
  getFatafatNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}get_fatafat_news_detail`, parameter, requestOptions);
  }
  GetVividhNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}get_vividh_news_list`, parameter, requestOptions);
  }
  GetFatafatNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FatafatBaseUrl}get_fatafat_news_list`, parameter, requestOptions);
  }
  GetNriNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NriBaseUrl}get_nri_news_list`, parameter, requestOptions);
  }
  GetVividhTitleList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}get_vividh_title_list_table`, parameter, requestOptions);
  }
  GetCategoryList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_epaper_category_list`, '', requestOptions);
  }
  GetEpaperList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_epaper_list`, parameter, requestOptions);
  }
  GetAdvertiserList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}get_advertiser_list`, '', requestOptions);
  }
  getAdvertiseDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}get_advertise_detail`, parameter, requestOptions);
  }
  getPaperDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_epaper_detail`, parameter, requestOptions);
  }
  getAdvertiserDetil(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}get_advertiser_detail`, parameter, requestOptions);
  }
  GetPageList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_epaper_page_list`, '', requestOptions);
  }
  getPageDetil(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_page_detail`, parameter, requestOptions);
  }
  getCategoryDetil(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_category_detail`, parameter, requestOptions);
  }
  GetEpaperCategoryList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.EpaperBaseUrl}get_epaper_category_list`, null, requestOptions);
  }
  GetActiveAdvertiserList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}get_active_advertiser_list`, null, requestOptions);
  }
  GetVividhVibhagTitleList() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_vividh_title_list`, null, requestOptions);
  }
  GetMainNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}get_main_news_list`, parameter, requestOptions);
  }
  DeleteAdvertise(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AdvertiseBaseUrl}delete_advertise`, parameter, requestOptions);
  }
  DeleteMainNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}delete_main_news`, parameter, requestOptions);
  }
  UpdateMainNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}update_main_news_display_order`, parameter, requestOptions);
  }
  UpdateMainNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}update_main_news_repeat`, parameter, requestOptions);
  }
  getMainNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}get_main_news_detail`, parameter, requestOptions);
  }
  GetMainLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MainBaseUrl}get_main_last_display_order`, parameter, requestOptions);
  }
  GetSportsNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}get_sports_news_list`, parameter, requestOptions);
  }
  DeleteSportsNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}delete_sports_news`, parameter, requestOptions);
  }
  UpdateSportsNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_display_order`, parameter, requestOptions);
  }
  UpdateSportsNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_repeat`, parameter, requestOptions);
  }
  getSportsNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}get_sports_news_detail`, parameter, requestOptions);
  }
  GetSportsLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}get_sports_last_display_order`, parameter, requestOptions);
  }
  UpdateSportsNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_display_app_home`, parameter, requestOptions);
  }
  UpdateSportsNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_status`, parameter, requestOptions);
  }
  UpdateSportsEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_entry_date`, parameter, requestOptions);
  }
  UpdateSportsNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_display_home`, parameter, requestOptions);
  }
  UpdateSportsNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SportsBaseUrl}update_sports_news_position`, parameter, requestOptions);
  }
  GetShortNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ShortBaseUrl}get_short_news_list`, parameter, requestOptions);
  }
  GetBollywoodNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}get_bollywood_news_list`, parameter, requestOptions);
  }
  DeleteShortNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ShortBaseUrl}delete_short_news`, parameter, requestOptions);
  }
  DeleteBollywoodNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}delete_bollywood_news`, parameter, requestOptions);
  }
  UpdateBollywoodNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_display_order`, parameter, requestOptions);
  }
  UpdateBollywoodNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_repeat`, parameter, requestOptions);
  }
  getBollywoodNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}get_bollywood_news_detail`, parameter, requestOptions);
  }
  getShortNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ShortBaseUrl}get_short_news_detail`, parameter, requestOptions);
  }
  GetBollywoodLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}get_bollywood_last_display_order`, parameter, requestOptions);
  }
  UpdateBollywoodNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_display_app_home`, parameter, requestOptions);
  }
  UpdateBollywoodNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_status`, parameter, requestOptions);
  }
  UpdateBollywoodEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_entry_date`, parameter, requestOptions);
  }
  UpdateBollywoodNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_display_home`, parameter, requestOptions);
  }
  UpdateBollywoodNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BollywoodBaseUrl}update_bollywood_news_position`, parameter, requestOptions);
  }
  GetInternationalNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}get_international_news_list`, parameter, requestOptions);
  }
  DeleteInternationalNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}delete_international_news`, parameter, requestOptions);
  }
  UpdateInternationalNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_display_order`, parameter, requestOptions);
  }
  UpdateInternationalNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_repeat`, parameter, requestOptions);
  }
  getInternationalNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}get_international_news_detail`, parameter, requestOptions);
  }
  GetInternationalLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}get_international_last_display_order`, parameter, requestOptions);
  }
  UpdateInternationalNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_display_app_home`, parameter, requestOptions);
  }
  UpdateInternationalNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_status`, parameter, requestOptions);
  }
  UpdateInternationalEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_entry_date`, parameter, requestOptions);
  }
  UpdateInternationalNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_display_home`, parameter, requestOptions);
  }
  UpdateInternationalNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.InternationalBaseUrl}update_international_news_position`, parameter, requestOptions);
  }
  GetSaurashtraNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}get_saurashtra_news_list`, parameter, requestOptions);
  }
  DeleteSaurashtraNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}delete_saurashtra_news`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_display_order`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_repeat`, parameter, requestOptions);
  }
  getSaurashtraNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}get_saurashtra_news_detail`, parameter, requestOptions);
  }
  GetSaurashtraLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}get_saurashtra_last_display_order`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_display_app_home`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_status`, parameter, requestOptions);
  }
  UpdateSaurashtraEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_entry_date`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_display_home`, parameter, requestOptions);
  }
  UpdateSaurashtraNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.SaurashtraBaseUrl}update_saurashtra_news_position`, parameter, requestOptions);
  }
  GetGujaratNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}get_gujarat_news_list`, parameter, requestOptions);
  }
  DeleteGujaratNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}delete_gujarat_news`, parameter, requestOptions);
  }
  UpdateGujaratNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_display_order`, parameter, requestOptions);
  }
  UpdateGujaratNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_repeat`, parameter, requestOptions);
  }
  getGujaratNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}get_gujarat_news_detail`, parameter, requestOptions);
  }
  GetGujaratLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}get_gujarat_last_display_order`, parameter, requestOptions);
  }
  UpdateGujaratNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_display_app_home`, parameter, requestOptions);
  }
  UpdateGujaratNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_status`, parameter, requestOptions);
  }
  UpdateGujaratEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_entry_date`, parameter, requestOptions);
  }
  UpdateGujaratNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_display_home`, parameter, requestOptions);
  }
  UpdateGujaratNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GujaratBaseUrl}update_gujarat_news_position`, parameter, requestOptions);
  }
  GetRajkotNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}get_rajkot_news_list`, parameter, requestOptions);
  }
  DeleteRajkotNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}delete_rajkot_news`, parameter, requestOptions);
  }
  UpdateRajkotNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_display_order`, parameter, requestOptions);
  }
  UpdateRajkotNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_repeat`, parameter, requestOptions);
  }
  getRajkotNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}get_rajkot_news_detail`, parameter, requestOptions);
  }
  GetRajkotLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}get_rajkot_last_display_order`, parameter, requestOptions);
  }
  UpdateRajkotNewsDisplayAppHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_display_app_home`, parameter, requestOptions);
  }
  UpdateRajkotNewsStatus(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_status`, parameter, requestOptions);
  }
  UpdateRajkotEntryDate(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_entry_date`, parameter, requestOptions);
  }
  UpdateRajkotNewsDisplayHome(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_display_home`, parameter, requestOptions);
  }
  UpdateRajkotNewsPosition(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.RajkotBaseUrl}update_rajkot_news_position`, parameter, requestOptions);
  }
  GetFilteredNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_filtered_news`, parameter, requestOptions);
  }
  ActiveDeactiveNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}active_deactive_news`, parameter, requestOptions);
  }
  GetPhotoGalleryLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.PhotoGalleryBaseUrl}get_photo_gallery_last_display_order`, parameter, requestOptions);
  }
  GetPhotoGalleryList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.PhotoGalleryBaseUrl}get_photo_gallery_list`, parameter, requestOptions);
  }
  DeletePhotoGalleryNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.PhotoGalleryBaseUrl}delete_photo_gallery`, parameter, requestOptions);
  }
  UpdatePhotoGalleryDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.PhotoGalleryBaseUrl}update_photo_gallery_display_order`, parameter, requestOptions);
  }
  GetKasumboList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.KasumboBaseUrl}get_kasumbo_list`, parameter, requestOptions);
  }
  DeleteKasumboNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.KasumboBaseUrl}delete_kasumbo`, parameter, requestOptions);
  }
  GetAajNaDivseLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AajNaDivseBaseUrl}get_aaj_na_divse_last_display_order`, parameter, requestOptions);
  }
  GetAajNaDivseList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AajNaDivseBaseUrl}get_aaj_na_divse_list`, parameter, requestOptions);
  }
  DeleteAajNaDivseNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AajNaDivseBaseUrl}delete_aaj_na_divse`, parameter, requestOptions);
  }
  UpdateAajNaDivseDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AajNaDivseBaseUrl}update_aaj_na_divse_display_order`, parameter, requestOptions);
  }
  DeleteTithi(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_tithi`, parameter, requestOptions);
  }
  DeleteTithiDay(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_tithi_day`, parameter, requestOptions);
  }
  GetTithiList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_tithi_list`, parameter, requestOptions);
  }
  GetTithiDayList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_tithi_day_list`, parameter, requestOptions);
  }
  GetTithiDayDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_tithi_day_detail`, parameter, requestOptions);
  }
  getVividhNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}get_vividh_news_detail`, parameter, requestOptions);
  }
  getVividhTitleDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}get_vividh_title_detail`, parameter, requestOptions);
  }
  GetVividhLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}get_vividh_last_display_order`, parameter, requestOptions);
  }
  DeleteVividhNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}delete_vividh_news`, parameter, requestOptions);
  }
  UpdateVividhNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}update_vividh_news_display_order`, parameter, requestOptions);
  }
  UpdateVividhNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}update_vividh_news_repeat`, parameter, requestOptions);
  }
  UpdateVividhVibhagTitleDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.VividhBaseUrl}update_vivdh_title_display_order`, parameter, requestOptions);
  }
  getBirthdayNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}get_birthday_news_detail`, parameter, requestOptions);
  }
  GetBirthdayLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}get_birthday_last_display_order`, parameter, requestOptions);
  }
  GetBirthdayNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}get_birthday_news_list`, parameter, requestOptions);
  }
  DeleteBirthdayNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}delete_birthday_news`, parameter, requestOptions);
  }
  UpdateBirthdayNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}update_birthday_news_display_order`, parameter, requestOptions);
  }
  UpdateBirthdayNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.BirthdayBaseUrl}update_birthday_news_repeat`, parameter, requestOptions);
  }
  getMarriageNewsDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}get_marriage_news_detail`, parameter, requestOptions);
  }
  GetMarriageLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}get_marriage_last_display_order`, parameter, requestOptions);
  }
  GetMarriageNewsList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}get_marriage_news_list`, parameter, requestOptions);
  }
  DeleteMarriageNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}delete_marriage_news`, parameter, requestOptions);
  }
  UpdateMarriageNewsDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}update_marriage_news_display_order`, parameter, requestOptions);
  }
  UpdateMarriageNewsRepeat(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.MarriageBaseUrl}update_marriage_news_repeat`, parameter, requestOptions);
  }
  GetObituaryList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ObituaryBaseUrl}get_obituary_list`, parameter, requestOptions);
  }
  DeleteObituaryNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ObituaryBaseUrl}delete_obituary`, parameter, requestOptions);
  }
  GetObituaryLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ObituaryBaseUrl}get_obituary_last_display_order`, parameter, requestOptions);
  }
  GetPollLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.OpinionBaseUrl}get_opinion_last_display_order`, parameter, requestOptions);
  }
  GetOpinionList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.OpinionBaseUrl}get_opinion_list`, parameter, requestOptions);
  }
  DeleteOpinionNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.OpinionBaseUrl}delete_opinion`, parameter, requestOptions);
  }
  DeletePanchang(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_panchang`, parameter, requestOptions);
  }
  DeletePanchangDay(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_panchang_day`, parameter, requestOptions);
  }
  GetPanchangList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_panchang_list`, parameter, requestOptions);
  }
  GetPanchangDayList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_panchang_day_list`, parameter, requestOptions);
  }
  GetPanchangDayDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_panchang_day_detail`, parameter, requestOptions);
  }
  GetTantriLastDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.TantriBaseUrl}get_tantri_last_display_order`, parameter, requestOptions);
  }
  GetTantriList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.TantriBaseUrl}get_tantri_list`, parameter, requestOptions);
  }
  DeleteTantriNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.TantriBaseUrl}delete_tantri`, parameter, requestOptions);
  }
  UpdateTantriDisplayOrder(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.TantriBaseUrl}update_tantri_display_order`, parameter, requestOptions);
  }
  GetFestivalPhotoList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FestivalPhotoBaseUrl}get_festival_photo_list`, parameter, requestOptions);
  }
  DeleteFestivalPhotoNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FestivalPhotoBaseUrl}delete_festival_photo`, parameter, requestOptions);
  }
  getKasumboDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.KasumboBaseUrl}get_kasumbo_detail`, parameter, requestOptions);
  }
  getAajNaDivseDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.AajNaDivseBaseUrl}get_aaj_na_divse_detail`, parameter, requestOptions);
  }
  getFestivalPhotoDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.FestivalPhotoBaseUrl}get_festival_photo_detail`, parameter, requestOptions);
  }
  getNoticeDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NoticeBaseUrl}get_notice_detail`, parameter, requestOptions);
  }
  GetNoticeList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NoticeBaseUrl}get_notice_list`, parameter, requestOptions);
  }
  GetCMSList(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.CmsBaseUrl}get_cms_list`, parameter, requestOptions);
  }
  getCMSDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.CmsBaseUrl}get_cms_detail`, parameter, requestOptions);
  }
  getAdminGroup() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_admin_group_list`, {}, requestOptions);
  }
  getAdminUser() {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_admin_user_list`, {}, requestOptions);
  }
  getAdminGroupDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_admin_group_detail`, parameter, requestOptions);
  }
  deleteAdminGroup(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_admin_group`, parameter, requestOptions);
  }
  getAdminUserDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}get_admin_user_detail`, parameter, requestOptions);
  }
  deleteAdminUser(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.GeneralBaseUrl}delete_admin_user`, parameter, requestOptions);
  }
  DeleteNoticeNews(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.NoticeBaseUrl}delete_notice_photo`, parameter, requestOptions);
  }
  getObituaryDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.ObituaryBaseUrl}get_obituary_detail`, parameter, requestOptions);
  }
  getOpinionDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.OpinionBaseUrl}get_opinion_detail`, parameter, requestOptions);
  }
  getPhotoGalleryDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.PhotoGalleryBaseUrl}get_photo_gallery_detail`, parameter, requestOptions);
  }
  getTantriDetail(parameter) {
    const requestOptions = {
      headers: {
        'Authorization': localStorage.getItem('login_token'),
      },
    };
    return this.http.post<any>(`${this.serverUrl.TantriBaseUrl}get_tantri_detail`, parameter, requestOptions);
  }
}
