<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/dynamic-section-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Dynamic Section</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<table class="table">
								<thead>
									<tr>
										<th>Type</th>
										<th>Title App</th>
										<th>Title Web</th>
										<th>Display App</th>
										<th>Display Web</th>
										<th>Status App</th>
										<th>Status Web</th>
										<th>Display At</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of List">
										<td>{{mn.section_type}}</td>
										<td>{{mn.title}}</td>
										<td>{{mn.web_title}}</td>
										<td><input type="text" class="form-control" name="display_order"
												(blur)="update_display_orders($event,mn._id,mn.display_order_app,'app')"
												(keyup.enter)="update_display_orders($event,mn._id,mn.display_order_app,'app')"
												value={{mn.display_order_app}}></td>
										<td><input type="text" class="form-control" name="display_order"
												(blur)="update_display_orders($event,mn._id,mn.display_order_web,'web')"
												(keyup.enter)="update_display_orders($event,mn._id,mn.display_order_web,'web')"
												value={{mn.display_order_web}}></td>
										<td *ngIf="mn.status=='yes'"><input type="checkbox" checked name="cat_id1"
												value={{mn._id}} (change)="toggleVisibility($event,'app')"></td>
										<td *ngIf="mn.status=='no'"><input type="checkbox" name="cat_id1"
												value={{mn._id}} (change)="toggleVisibility($event,'app')"></td>
										<td *ngIf="mn.web_status=='yes'"><input type="checkbox" checked name="cat_id"
												value={{mn._id}} (change)="toggleVisibility($event,'web')"></td>
										<td *ngIf="mn.web_status=='no'"><input type="checkbox" name="cat_id"
												value={{mn._id}} (change)="toggleVisibility($event,'web')"></td>
										<td>{{mn.section_display_in}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>