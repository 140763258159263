import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateEditionRoutingModule } from './update-edition-routing.module';
import { UpdateEditionComponent } from './update-edition/update-edition.component';
@NgModule({
  declarations: [UpdateEditionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    UpdateEditionRoutingModule
  ]
})
export class UpdateEditionModule { }
