<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/short-news-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Short News</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<!-- <div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<select class="form-control">
										<option value="date">Date(dd-mm-yyyy)</option>
									</select>
								</div>
								<div class="col-md-3">
									<input type="date" ng-model="FilterDate"
										class="form-control">
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Search</button>
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Reset</button>
								</div>
							</div> -->
							<table class="table">
								<thead>
									<tr>
										<th><button *ngIf="show_multiple_delete_btn"
												class="btn btn-outline-danger waves-effect waves-light"
												(click)="delete_multiple_news()"><i class="fa fa-trash"></i></button>
										</th>
										<th>News</th>
										<th>Date</th>

									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of shortList">
										<td class="text-center"><input type="checkbox" ng-model="short_news_id"
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td>

											<i *ngIf="mn.short_video" style="color:#ed1b24"
												class="fa fa-video">&nbsp;</i>
											<a routerLink="/short-news-edit/{{mn._id}}">
												<img src="{{NewsImageURL}}short_news/{{mn.short_image}}" height="70">
											</a>
										</td>
										<td>{{mn.short_news_date}}</td>

									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>