import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, NgModel, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-update-edition',
  templateUrl: './update-edition.component.html',
  styleUrls: ['./update-edition.component.css']
})
export class UpdateEditionComponent implements OnInit {
  editionForm: FormGroup;
  update_edition: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.editionForm = this.fb.group({
      edition_type: 'no',
    });
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetTodayEdition(parameter).subscribe((data) => {
      if (data.status) {
        this.editionForm = this.fb.group({
          edition_type: ['yes'],
        });
        this.update_edition = data.date;
      } else {
        this.editionForm = this.fb.group({
          edition_type: ['no'],
        });
        this.update_edition = 'no';
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_edition_form() {
    var th = this;
    var routers = this.router;
    th.DefaultComponent.loaderShow();
    $('.submit_btn').attr('disabled', true);
    var parameter = { 'edition_type': th.editionForm.value.edition_type, 'entry_date': localStorage.getItem('EntryDate') };
    th.CommonService.UpdateTodayEdition(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        th.toastr.error(data.message);
      }
      $('.submit_btn').attr('disabled', false);
      th.DefaultComponent.loaderHide();
    });

    return false;
  }
  ngOnInit(): void {
  }
}
