import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-vividh-vibhag-page-list',
  templateUrl: './vividh-vibhag-page-list.component.html',
  styleUrls: ['./vividh-vibhag-page-list.component.css']
})
export class VividhVibhagPageListComponent implements OnInit {
  filter_div = false;
  show_filter_text = true;
  show_multiple_delete_btn = false;
  show_filter_date = false;
  VividhList = [];
  display_order_currrent = [];
  display_order_val = [];
  display_order_ids = [];
  news_delete_checkbox = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.get_vividh_news_list();
  }
  get_vividh_news_list() {
    this.DefaultComponent.loaderShow();
    var parameter = { 'entry_date': localStorage.getItem('EntryDate') };
    this.CommonService.GetVividhNewsList(parameter).subscribe((data) => {
      if (data.status) {
        this.VividhList = data.news_list;
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          this.router.navigate(['/']);
        }
      }
      this.DefaultComponent.loaderHide();
    });
    console.log(this.VividhList)
  }
  showFilterDiv(e) {
    var th = this;
    if (e.target.value == 'title') {
      th.show_filter_text = true;
      th.show_filter_date = false;
    } else {
      th.show_filter_text = false;
      th.show_filter_date = true;
      th.DefaultComponent.loaderShow();
      setTimeout(function () {
        $('#datepicker1').datepicker({
          format: "dd-mm-yyyy",
          autoclose: true,
          orientation: "top",
          todayHighlight: true,
        })
        th.DefaultComponent.loaderHide();
      }, 500);
    }
  }
  add_delete_checkbox(e, id) {
    if (this.news_delete_checkbox.includes(id)) {
      var index = this.news_delete_checkbox.indexOf(id);
      this.news_delete_checkbox.splice(index, 1);
    }
    if (e.target.checked == true) {
      this.news_delete_checkbox.push(id);
    }
    if (this.news_delete_checkbox.length > 0) {
      this.show_multiple_delete_btn = true;
    } else {
      this.show_multiple_delete_btn = false;
    }
  }
  get_filtered_news() {
    var search_type = $("#search_type").val();
    var search_title = $("#search_title").val();
    var datepicker1 = $("#datepicker1").val();
    if (search_title || datepicker1) {
      this.DefaultComponent.loaderShow();
      var parameter = { 'search_type': search_type, 'search_title': search_title, 'search_date': datepicker1, 'table_name': 'tbl_vividh_news', "prefix": "vividh" };
      this.CommonService.GetFilteredNewsList(parameter).subscribe((data) => {
        if (data.status) {
          this.VividhList = data.news_list;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    } else {
      this.DefaultComponent.loaderShow();
      var parameters = { 'entry_date': localStorage.getItem('EntryDate') };
      this.CommonService.GetVividhNewsList(parameters).subscribe((data) => {
        if (data.status) {
          this.VividhList = data.news_list;
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  delete_multiple_news() {
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'del_ids': th.news_delete_checkbox, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_vividh_news', "prefix": "vividh" };
    this.CommonService.DeleteVividhNews(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message);
        this.news_delete_checkbox = [];
        th.get_vividh_news_list();
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_position(e, id) {
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'new_position': e.target.value, 'id': id, 'table_name': 'tbl_vividh_news', 'prefix': 'vividh' };
    this.CommonService.UpdateNewsPosition(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_display_home(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'display_home': status, 'id': id, 'table_name': 'tbl_vividh_news', 'prefix': 'vividh' };
    this.CommonService.UpdateNewsDisplayHome(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_news_status(e, id) {
    var th = this;
    if (e.target.checked == true) {
      var status = 'yes';
    } else {
      var status = 'no';
    }
    var th = this;
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    var parameter = { 'status': status, 'id': id, 'table_name': 'tbl_vividh_news', 'prefix': 'vividh' };
    this.CommonService.UpdateNewsStatus(parameter).subscribe((data) => {
      if (data.status) {
        th.toastr.success(data.message)
      } else {
        if (data.token_expire) {
          localStorage.removeItem('login_token');
          routers.navigate(['/']);
        } else {
          th.toastr.error(data.message);
        }
      }
      this.DefaultComponent.loaderHide();
    });
  }
  update_display_orders(e, id, ori_ord) {
    if (parseInt(ori_ord) != parseInt(e.target.value)) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'display_order': e.target.value, 'id': id, 'ori_display_order': ori_ord, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_vividh_news', 'prefix': 'vividh' };
      this.CommonService.UpdateVividhNewsDisplayOrder(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_vividh_news_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  update_repeat_news(e, id) {
    if (parseInt(e.target.value) > 0) {
      var th = this;
      var routers = this.router;
      this.DefaultComponent.loaderShow();
      var parameter = { 'repeat_days': e.target.value, 'id': id, 'news_date': localStorage.getItem('EntryDate'), 'table_name': 'tbl_vividh_news', 'prefix': 'vividh' };
      this.CommonService.UpdateVividhNewsRepeat(parameter).subscribe((data) => {
        if (data.status) {
          th.toastr.success(data.message);
          th.get_vividh_news_list();
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          } else {
            th.toastr.error(data.message);
          }
        }
        this.DefaultComponent.loaderHide();
      });
    }
  }
  ngOnInit(): void {
    setTimeout(function () {
      $('#datepicker').datepicker({
        format: "dd-mm-yyyy",
        autoclose: true,
        orientation: "top",
        todayHighlight: true,
      }).datepicker("update", localStorage.getItem('EntryDate'));
    }, 500);
  }
}
