<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="col-sm-12">
				<div class="page-title-box">
					<div class="btn-group pull-right">
						<ol class="breadcrumb hide-phone p-0 m-0">
							<a href="javascript:void(0)" routerLink="/advertise-add">
								<li class="breadcrumb-item btn btn-info btn-lg">Add New</li>
							</a>
						</ol>
					</div>
					<h4 class="page-title">Advertise</h4>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<div class="col-md-12 row m-b-10">
								<div class="col-md-3">
									<select class="form-control">
										<option value="firstname">Advertiser First Name</option>
										<option value="lastname">Advertiser Last Name</option>
										<option value="email">Type</option>
										<option value="city">Link</option>
										<option value="state">Location</option>
										<option value="country">Display Order</option>
										<option value="zipcode">Expiry Date</option>
									</select>
								</div>
								<div class="col-md-3">
									<input type="text" ng-model="FilterTitle" placeholder="Enter Title"
										class="form-control">
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Search</button>
								</div>
								<div class="col-md-1">
									<button class="btn btn-primary" title="Update news entry date">Reset</button>
								</div>
							</div>
							<table class="table">
								<thead>
									<tr>
										<th><button *ngIf="show_multiple_delete_btn"
												class="btn btn-outline-danger waves-effect waves-light"
												(click)="delete_multiple_news()"><i class="fa fa-trash"></i></button>
										</th>
										<th>Advertiser</th>
										<th>Type</th>
										<th>Location</th>
										<th>Start Date<br>
											Exp Date
										</th>
										<th>Display</th>
										<th>Active?</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let mn of AdvertiseList">
										<td class="text-center"><input type="checkbox" ng-model="advertise_news_id"
												(change)="add_delete_checkbox($event,mn._id)"></td>
										<td>
											<a routerLink="/advertise-edit/{{mn._id}}">{{mn.advertisers}}</a>
										</td>
										<td>{{mn.type}}</td>
										<td>{{mn.location}}</td>
										<td>{{mn.start_date}}<br>{{mn.end_date}}</td>
										<td>
											<input ng-model="display_order"
												(blur)="update_display_orders($event,mn._id,mn.display_order)"
												(keyup.enter)="update_display_orders($event,mn._id,mn.display_order)"
												class="form-control" type="text" value="{{mn.display_order}}">
										</td>
										<td class="text-center">
											<input type="checkbox" [checked]="mn.advertise_status == 'yes'"
												(change)="update_news_status($event,mn._id)" value="no">
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>