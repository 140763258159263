import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
declare var $: any;
@Component({
  selector: 'app-epaper-merge',
  templateUrl: './epaper-merge.component.html',
  styleUrls: ['./epaper-merge.component.css']
})
export class EpaperMergeComponent implements OnInit {
  CategoryList = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent) {
    this.DefaultComponent.loaderShow();
    this.get_epaper();
  }
  merge_pdf(id) {
    var th = this;
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    $.ajax({
      url: environment.EpaperBaseUrl + 'epaper_merge_pdf',
      method: 'POST',
      data: { date: localStorage.getItem('EntryDate'), category_id: id },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.toastr.success(res.message)
          th.get_epaper();
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
      }
    });
  }
  get_epaper() {
    var th = this;
    var token = localStorage.getItem('login_token');
    var routers = this.router;
    this.DefaultComponent.loaderShow();
    $.ajax({
      url: environment.EpaperBaseUrl + 'get_epaper',
      method: 'POST',
      data: { date: localStorage.getItem('EntryDate') },
      headers: {
        'Authorization': token
      },
      dataType: 'json',
      success: function (res) {
        th.DefaultComponent.loaderHide();
        if (res.status) {
          th.CategoryList = res.epaper_list;
        } else {
          if (res.token_expire) {
            localStorage.removeItem('login_token');
            routers.navigate(['/']);
          }
          else {
            th.toastr.error(res.message)
          }
        }
      }
    });
  }
  ngOnInit(): void {
  }
}
