import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../common.service';
import { ToastrService } from 'ngx-toastr';
import { DefaultComponent } from '../../layout/default/default.component';
import { News } from './news';
declare var $: any;
@Component({
  selector: 'app-short-news-add',
  templateUrl: './short-news-add.component.html',
  styleUrls: ['./short-news-add.component.css']
})
export class ShortNewsAddComponent implements OnInit, AfterViewInit {
  NewsData: News;
  imageURL1: string;
  result_guj_title1 = false;
  result_guj_video = false;
  result_guj_image = false;
  displayImage = true;
  displayVideo = false;
  name = 'ng2-ckeditor';
  videoThumb: any;
  NewsImageURL: string;
  ckeConfig: any;
  log: string = '';
  VividhVibhagTitleList: [];
  news_date: string;
  news_id: any = 0;
  newsType: any = '';
  @ViewChild("myckeditor") ckeditor: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService,
    public CommonService: CommonService,
    public DefaultComponent: DefaultComponent
  ) {
    this.DefaultComponent.loaderShow();
    this.route.params.subscribe(params => this.news_id = params.news_id);
    this.NewsImageURL = environment.baseUrl;
    if (this.news_id) {
      var param = { news_id: this.news_id };
      this.CommonService.getShortNewsDetail(param).subscribe((data) => {
        if (data['status']) {
          this.NewsData = data['news_data'];
        } else {
          if (data.token_expire) {
            localStorage.removeItem('login_token');
            this.router.navigate(['/']);
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
        this.DefaultComponent.loaderHide();
        this.news_date = this.NewsData.short_news_date;
        if (this.NewsData.short_image != '') {
          this.displayVideo = false;
          this.displayImage = true;
          this.imageURL1 = this.NewsImageURL + '/short_news/' + this.NewsData.short_image;
        }
        this.newsType = 'Image';
        if (this.NewsData.short_video != '') {
          this.newsType = 'Video';
          this.displayImage = false;
          this.displayVideo = true;
          setTimeout(() => {
            var videoURL = this.NewsImageURL + '/short_news/' + this.NewsData.short_video;
            var video = document.getElementById('video_player');
            var source = document.createElement('source');
            source.setAttribute('src', videoURL);
            source.setAttribute('type', 'video/mp4');
            video.appendChild(source);
            $("#thumb").attr("src", this.NewsImageURL + '/short_news/' + this.NewsData.short_image);
          }, 2000);
        }

      });
    } else {
      this.news_date = localStorage.getItem('EntryDate');
    }
  }
  ngAfterViewInit(): void {

  }
  showImagePreview(event, id) {
    this.videoThumb = '';
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      $("#image" + id).val('');
      if (id == 1) {
        this.imageURL1 = '';
      }
      this.toastr.error("Only images are supported.");
      return;
    }
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      if (id == 1) {
        this.imageURL1 = reader.result as string;
      }
    }
    reader.readAsDataURL(file)
  }
  ngOnInit(): void {
  }
  setVideoInput(e) {
    $('#image1').val('');
    $('#video_news').val('');
    $('#video_player').html('');
    if (e.target.value == 'Image') {
      this.displayVideo = false;
      this.displayImage = true;
    } else if (e.target.value == 'Video') {
      this.displayImage = false;
      this.displayVideo = true;
    } else {
      this.displayVideo = false;
      this.displayImage = false;
    }
  }
  async chechVideoExtension(event) {
    this.videoThumb = '';
    const file = (event.target as HTMLInputElement).files[0];
    var mimeType = file.type;
    if (mimeType.match(/video\/*/) == null) {
      $("#video_news").val('');
      this.toastr.error("Only video supported.");
      return;
    }
    const thumbnail: any = await this.generateVideoThumb(file);
    $("#thumb").attr("src", thumbnail);
    var block = thumbnail.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

    // Convert it to a blob to upload
    var blob = this.b64toBlob(realData, contentType);
    this.videoThumb = blob;
    const reader = new FileReader();

    reader.onload = () => {
      $('#video_player').html('');
      var video = <HTMLVideoElement>document.getElementById('video_player');
      var source = document.createElement('source');
      source.setAttribute('src', reader.result as string);
      source.setAttribute('type', 'video/mp4');
      video.appendChild(source);
    }
    reader.readAsDataURL(file)
  }
  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  generateVideoThumb(file) {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  }
  submit_form() {
    var routers = this.router;
    var th = this;
    $.validate({
      onSuccess: function () {
        th.DefaultComponent.loaderShow();
        $('.btn-success').attr('disabled', true);
        var form = $("#short_news_form");
        var fd = new FormData(form[0]);
        if (th.videoThumb) {
          fd.append("videothumb", th.videoThumb, Math.floor(Math.random() * 100000) + Date.now() + ".png");
        }
        var token = localStorage.getItem('login_token');
        $.ajax({
          url: environment.ShortBaseUrl + 'add_short_news',
          method: 'POST',
          data: fd,
          headers: {
            'Authorization': token
          },
          contentType: false,
          processData: false,
          dataType: 'json',
          success: function (res) {
            th.DefaultComponent.loaderHide();
            if (res.status) {
              th.toastr.success(res.message)
              routers.navigate(['/short-news-list']);
            } else {
              if (res.token_expire) {
                localStorage.removeItem('login_token');
                routers.navigate(['/']);
              }
              else {
                th.toastr.error(res.message)
              }
            }
            $('.btn-success').attr('disabled', false);
          }
        });
        return false;
      }
    });
  }
  onChange($event: any): void {
    console.log("onChange");
    //this.log += new Date() + "<br />";
  }
  onPaste($event: any): void {
    console.log("onPaste");
    //this.log += new Date() + "<br />";
  }
}
