import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TantriRoutingModule } from './tantri-routing.module';
import { TantriAddComponent } from './tantri-add/tantri-add.component';
import { TantriListComponent } from './tantri-list/tantri-list.component';
import { CKEditorModule } from 'ckeditor4-angular';
@NgModule({
  declarations: [TantriAddComponent, TantriListComponent],
  imports: [
    CommonModule,
    CKEditorModule,
    ReactiveFormsModule,
    FormsModule,
    TantriRoutingModule
  ]
})
export class TantriModule { }
