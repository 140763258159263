<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="main_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Main News Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																News</button>
															<a routerLink="/main-news-list" type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-8 row">
										<label class="col-sm-3 col-form-label">Display Order: </label>
										<div class="col-sm-2">
											<input type="hidden" name="newsID" value="{{NewsData?._id}}">
											<input type="hidden" name="oriDisplayOrder" id="oriDisplayOrder"
												value="{{NewsData?.main_display_order}}">
											<input class="form-control" data-validation="required number" type="text"
												value="{{NewsData?.main_display_order}}" name="display_order"
												id="display_order">
										</div>
										<label class="col-sm-3 col-form-label">Display in home page?: </label>
										<div class="col-sm-4">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" type="radio" [checked]="display_in_home"
														id="customRadio4" name="display_home"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio4">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="no" type="radio" name="display_home" id="customRadio5"
														[checked]="!display_in_home" class="custom-control-input">
													<label class="custom-control-label" for="customRadio5">No</label>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="col-md-6 row">
										<label class="col-sm-6 col-form-label">Display in home page?: </label>
										<div class="col-sm-6">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" type="radio" [checked]="display_in_home"
														id="customRadio4" name="display_home"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio4">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="no" type="radio" name="display_home" id="customRadio5"
														[checked]="!display_in_home" class="custom-control-input">
													<label class="custom-control-label" for="customRadio5">No</label>
												</div>
											</div>
										</div>
									</div> -->
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Gred Line Above: </label>
										<div class="col-sm-8">
											<input class="form-control" type="text"
												value="{{NewsData?.main_gred_above}}" name="gred_above" id="gred_above">
										</div>
									</div>
									<div class="col-md-6 row">
										<label class="col-sm-6"><input type="checkbox" name="send_notification"
												id="customCheck3"> Send Notification?:
										</label>
										<div class="col-sm-5 ">
											<label class="col-form-label">Br: </label>
											<select class="form-control-auto" name="use_br">
												<option [selected]="NewsData?.main_use_br == 'no'" value="no">No
												</option>
												<option [selected]="NewsData?.main_use_br == 'yes'" value="yes">Yes
												</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row" *ngIf="!result_guj_title">
										<label for="guj_title" class="col-sm-4 col-form-label">Gujarati Title: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required"
												value="{{NewsData?.main_guj_title}}" type="text" name="guj_title"
												value="" id="guj_title">
											<!--	ng-blur="translate_guj_eng(formdata.guj_title)"-->
										</div>
									</div>
									<div class="col-md-6 row">
										<label class="col-sm-6 col-form-label">Settings: </label>
										<div class="col-sm-6">
											<div class="checkbox my-2">
												<div class="custom-control custom-checkbox">
													<input type="checkbox" class="custom-control-input"
														[checked]="NewsData?.main_title_change" name="title_setting"
														(change)="toggleVisibility($event)" id="customCheck2">
													<label class="custom-control-label" for="customCheck2">Change Text
														box </label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!--<div class="form-group row" *ngIf="result_guj_title1">
									<label class="col-form-label"> Gujarati Title:   </label>
									<div class="">
											<ng-ckeditor id="test" name="guj_title1" name="guj_title1" ng-config="config"></ng-ckeditor>
									</div>
								</div>-->
								<div class="form-group row" *ngIf="result_guj_title">
									<div class="col-md-12 row">
										<label for="guj_title" class="col-sm-2 col-form-label">Gujarati Title: </label>
										<div class="col-sm-10">
											<ckeditor [(ngModel)]="guj_title1" name="guj_title1" [config]="ckeConfig"
												debounce="500">
											</ckeditor>
										</div>
									</div>
								</div>
								<input readonly class="form-control" type="hidden" value="" name="eng_title"
									id="eng_title">
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="gred_below" class="col-sm-4 col-form-label">Gred Line Below:
										</label>
										<div class="col-sm-8">
											<input class="form-control" type="text"
												value="{{NewsData?.main_gred_below}}" name="gred_below" id="gred_below">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="news_position" class="col-sm-4 col-form-label">News Position:
										</label>
										<div class="col-sm-8">
											<select class="form-control" id="news_position" name="news_position">
												<option [selected]="NewsData?.main_news_position == 'right'"
													value="right">Right</option>
												<option [selected]="NewsData?.main_news_position == 'left'"
													value="left">Left</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label for="image1" class="col-sm-4 col-form-label">Image Position:
										</label>
										<div class="col-sm-8">
											<select (change)="setHeadingImage($event)" class="form-control"
												id="image_position_home" name="image_position_home">
												<option [selected]="NewsData?.main_image_position_home == 'left'"
													value="left">Left</option>
												<option [selected]="NewsData?.main_image_position_home == 'right'"
													value="right">Right</option>
												<option [selected]="NewsData?.main_image_position_home == 'center'"
													value="center">Center</option>
												<option [selected]="NewsData?.main_image_position_home == 'left_right'"
													value="left_right">Left / Right (Both)</option>
											</select>
											<select style="display:none" class="form-control" id="image_size_home"
												name="image_size_home">
												<option value="0">0</option>
												<option value="85">85</option>
												<option value="200">200</option>
											</select>
										</div>
									</div>
									<div class="col-md-6 row">
										<label for="image2" class="col-sm-6 col-form-label">Image Position (Matter):
										</label>
										<div class="col-sm-6">
											<select class="form-control" id="image_position_matter"
												name="image_position_matter">
												<option selected value="center">Center</option>
											</select>
											<select style="display:none" class="form-control" name="image_size_matter">
												<option value="0">0</option>
												<option value="85">85</option>
												<option value="200">200</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image1" class="col-form-label">Heading image 1: </label>
											<div class="image_checkbox" *ngIf="imageURL1">
												<input type="checkbox" name="img1_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image_1"
												value="{{NewsData?.main_heading_image_1}}">
											<input id="image1" type="file" class="form-control" name="file_upload1"
												type="file" accept='image/*' (change)="showImagePreview($event,1)">
											<img [src]="imageURL1" *ngIf="imageURL1"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image2" class="col-form-label">Matter image 1: </label>
											<div class="image_checkbox" *ngIf="imageURL2">
												<input type="checkbox" name="img2_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-6">
											<input type="hidden" name="matter_image_1_1"
												value="{{NewsData?.main_matter_image_1_1}}">
											<input type="file" id="image2" class="form-control" name="file_upload2"
												accept='image/*' (change)="showImagePreview($event,2)">
											<img [src]="imageURL2" *ngIf="imageURL2"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image3" class="col-form-label">Heading image2: </label>
											<div class="image_checkbox" *ngIf="imageURL3">
												<input type="checkbox" name="img3_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image_2"
												value="{{NewsData?.main_heading_image_2}}">
											<input disabled type="file" id="image3" class="form-control"
												name="file_upload3" accept="image/*"
												(change)="showImagePreview($event,3)">
											<img [src]="imageURL3" *ngIf="imageURL3"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image4" class="col-form-label">Matter image 2: </label>
											<div class="image_checkbox" *ngIf="imageURL4">
												<input type="checkbox" name="img4_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-6">
											<input type="hidden" name="matter_image_1_2"
												value="{{NewsData?.main_matter_image_1_2}}">
											<input disabled type="file" id="image4" class="form-control"
												name="file_upload4" accept="image/*"
												(change)="showImagePreview($event,4)">
											<img [src]="imageURL4" *ngIf="imageURL4"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<!-- <div *ngFor="let video of video_div_1_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="video_1" class="col-sm-2 col-form-label">Videos: </label>
										<div class="col-sm-4">
											<textarea class="form-control" type="text" name="video_values1[]"
												id="video_1">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types1[]">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-info" *ngIf="i==0"
												(click)=add_video(1)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,1)>-</button>
										</div>
									</div>
								</div> -->
								<div *ngFor="let video of video_div_1_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="video_1" class="col-sm-2 col-form-label">Videos: </label>
										<div class="col-sm-4" *ngIf="video.types!=5">
											<textarea class="form-control" type="text" name="video_values1[]"
												id="video_1">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4" *ngIf="video.types==5">
											<textarea style="display: none;"
												name="video_values1[]">{{video.values}}</textarea>
											<input type="file" id="video_news1_{{i}}" class="form-control"
												name="video_news_file1_{{i}}"
												(change)="chechVideoExtension($event,i,1)">
											<video *ngIf="(news_id==0 || news_id==undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player1_{{i}}">
											</video>
											<video *ngIf="(news_id!=0 && news_id!=undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player1_{{i}}">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/mp4">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/ogg">
											</video>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types1[]"
												(change)="setVideoInput($event,i,1)">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
												<option [selected]="video.types=='5'" value="5">Breaking News</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-success" *ngIf="i==0"
												(click)=add_video(1)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,1)>-</button>
										</div>
									</div>
								</div>
								<div *ngFor="let image of image_div_1_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="image_1" class="col-sm-2 col-form-label">Images 1: </label>
										<div class="col-sm-4">
											<input type="hidden" name="image_value1" value="{{image}}">
											<input class="form-control" type="file" name="image_values1" id="image_1"
												value="{{image}}">
											<img [src]="NewsImageURL+NewsData.main_image_source+'/'+image" *ngIf="image"
												style="max-width:300px;margin-top:15px">
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-warning" *ngIf="i==0"
												(click)=add_image(1)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_image(i,1)>-</button>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-12 row">
										<label for="guj_title" class="col-sm-2 col-form-label">Content: </label>
										<div class="col-sm-10">
											<ckeditor name="content1" [(ngModel)]="content1" [config]="ckeConfig"
												debounce="500" (paste)="onPaste($event)" (change)="onChange($event)">
											</ckeditor>
										</div>
									</div>
								</div>
								<div *ngFor="let image of image_div_2_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="image_2" class="col-sm-2 col-form-label">Images 2: </label>
										<div class="col-sm-4">
											<input type="hidden" name="image_value2" value="{{image}}">
											<input class="form-control" type="file" name="image_values2" id="image_2"
												value="{{image}}">
											<img [src]="NewsImageURL+NewsData.main_image_source+'/'+image" *ngIf="image"
												style="max-width:300px;margin-top:15px">
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-warning" *ngIf="i==0"
												(click)=add_image(2)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_image(i,2)>-</button>
										</div>
									</div>
								</div>
								<!-- <div class="form-group row" *ngFor="let video of video_div_2_count; let i = index">
									<div class="col-md-12 row">
										<label for="video_2" class="col-sm-2 col-form-label">Videos 2: </label>
										<div class="col-sm-4">
											<textarea class="form-control" type="text" value="" name="video_values2[]"
												id="video_2">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types2[]">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-info" *ngIf="i==0"
												(click)=add_video(2)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,2)>-</button>
										</div>
									</div>
								</div> -->
								<div *ngFor="let video of video_div_2_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="video_2" class="col-sm-2 col-form-label">Videos: </label>
										<div class="col-sm-4" *ngIf="video.types!=5">
											<textarea class="form-control" type="text" name="video_values2[]"
												id="video_2">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4" *ngIf="video.types==5">
											<textarea style="display: none;"
												name="video_values2[]">{{video.values}}</textarea>
											<input type="file" id="video_news2_{{i}}" class="form-control"
												name="video_news_file2_{{i}}"
												(change)="chechVideoExtension($event,i,2)">
											<video *ngIf="(news_id==0 || news_id==undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player2_{{i}}">
											</video>
											<video *ngIf="(news_id!=0 && news_id!=undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player2_{{i}}">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/mp4">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/ogg">
											</video>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types2[]"
												(change)="setVideoInput($event,i,2)">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
												<option [selected]="video.types=='5'" value="5">Breaking News</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-success" *ngIf="i==0"
												(click)=add_video(2)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,2)>-</button>
										</div>
									</div>
								</div>
								<div *ngFor="let image of image_div_3_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="image_3" class="col-sm-2 col-form-label">Images 3: </label>
										<div class="col-sm-4">
											<input type="hidden" name="image_value3" value="{{image}}">
											<input class="form-control" type="file" name="image_values3" id="image_3"
												value="{{image}}">
											<img [src]="NewsImageURL+NewsData.main_image_source+'/'+image" *ngIf="image"
												style="max-width:300px;margin-top:15px">
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-warning" *ngIf="i==0"
												(click)=add_image(3)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_image(i,3)>-</button>
										</div>
									</div>
								</div>
								<!-- <div class="form-group row">
									<div class="col-md-6 row">
										<label for="image_position_matter_2" class="col-sm-4 col-form-label">Image
											Position (Matter-2): </label>
										<div class="col-sm-8">
											<select class="form-control" style="width: 100%;"
												id="image_position_matter_2" name="image_position_matter_2">
												<option [selected]="NewsData?.main_image_position_matter_2 == 'left'"
													value="left">Left</option>
												<option
													[selected]="NewsData?.main_image_position_matter_2 == 'right'"
													value="right">Right</option>
												<option
													[selected]="NewsData?.main_image_position_matter_2 == 'center'"
													value="center">Center</option>
												<option
													[selected]="NewsData?.main_image_position_matter_2 == 'left_right'"
													value="left_right">Left / Right (Both)</option>
											</select>
										</div>
										<div class="col-sm-2" style="display:none">
											<select class="form-control" name="image_size_matter_2">
												<option value="0">0</option>
												<option value="85">85</option>
												<option value="200">200</option>
											</select>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image5" class="col-form-label">Matter-2 image 1: </label>
											<div class="image_checkbox" *ngIf="imageURL5">
												<input type="checkbox" name="img5_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-8">
											<input type="hidden" name="matter_image_2_1"
												value="{{NewsData?.main_matter_image_2_1}}">
											<input type="file" id="image5" class="form-control" name="file_upload5"
												accept="image/*" (change)="showImagePreview($event,5)">
											<img [src]="imageURL5" *ngIf="imageURL5"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="image6" class="col-form-label">Matter-2 image 2: </label>
											<div class="image_checkbox" *ngIf="imageURL6">
												<input type="checkbox" name="img6_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-8">
											<input type="hidden" name="matter_image_2_2"
												value="{{NewsData?.main_matter_image_2_2}}">
											<input type="file" id="image6" class="form-control" name="file_upload6"
												accept="image/*" (change)="showImagePreview($event,6)">
											<img [src]="imageURL6" *ngIf="imageURL6"
												style="max-width:300px;margin-top:15px">
										</div>
									</div>
								</div> -->
								<!-- <div class="form-group row" *ngFor="let video of video_div_3_count; let i = index">
									<div class="col-md-12 row">
										<label for="example-text-input" class="col-sm-2 col-form-label">Videos 3:
										</label>
										<div class="col-sm-4">
											<textarea class="form-control" type="text" value="" name="video_values3[]"
												id="example-text-input">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types3[]">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-info" *ngIf="i==0"
												(click)=add_video(3)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,3)>-</button>
										</div>
									</div>
								</div> -->
								<div *ngFor="let video of video_div_3_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="video_3" class="col-sm-2 col-form-label">Videos: </label>
										<div class="col-sm-4" *ngIf="video.types!=5">
											<textarea class="form-control" type="text" name="video_values3[]"
												id="video_3">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4" *ngIf="video.types==5">
											<textarea style="display: none;"
												name="video_values3[]">{{video.values}}</textarea>
											<input type="file" id="video_news3_{{i}}" class="form-control"
												name="video_news_file3_{{i}}"
												(change)="chechVideoExtension($event,i,3)">
											<video *ngIf="(news_id==0 || news_id==undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player3_{{i}}">
											</video>
											<video *ngIf="(news_id!=0 && news_id!=undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player3_{{i}}">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/mp4">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/ogg">
											</video>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types3[]"
												(change)="setVideoInput($event,i,3)">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
												<option [selected]="video.types=='5'" value="5">Breaking News</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-success" *ngIf="i==0"
												(click)=add_video(3)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,3)>-</button>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-12 row">
										<label for="guj_title" class="col-sm-2 col-form-label">Content 2: </label>
										<div class="col-sm-10">
											<ckeditor name="content2" [(ngModel)]="content2" [config]="ckeConfig"
												debounce="500" (paste)="onPaste($event)" (change)="onChange($event)">
											</ckeditor>
										</div>
									</div>
								</div>
								<div *ngFor="let image of image_div_4_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="image_4" class="col-sm-2 col-form-label">Images 4: </label>
										<div class="col-sm-4">
											<input type="hidden" name="image_value4" value="{{image}}">
											<input class="form-control" type="file" name="image_values4" id="image_4"
												value="{{image}}">
											<img [src]="NewsImageURL+NewsData.main_image_source+'/'+image" *ngIf="image"
												style="max-width:300px;margin-top:15px">
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-warning" *ngIf="i==0"
												(click)=add_image(4)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_image(i,4)>-</button>
										</div>
									</div>
								</div>
								<!-- <div class="form-group row" *ngFor="let video of video_div_4_count; let i = index">
									<div class="col-md-12 row">
										<label class="col-sm-2 col-form-label">Videos 4: </label>
										<div class="col-sm-4">
											<textarea class="form-control" type="text" value="" name="video_values4[]"
												id="example-text-input">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types4[]">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-info" *ngIf="i==0"
												(click)=add_video(4)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,4)>-</button>
										</div>
									</div>
								</div> -->
								<div *ngFor="let video of video_div_4_count; let i = index" class="form-group row">
									<div class="col-md-12 row">
										<label for="video_4" class="col-sm-2 col-form-label">Videos: </label>
										<div class="col-sm-4" *ngIf="video.types!=5">
											<textarea class="form-control" type="text" name="video_values4[]"
												id="video_4">{{video.values}}</textarea>
										</div>
										<div class="col-sm-4" *ngIf="video.types==5">
											<textarea style="display: none;"
												name="video_values4[]">{{video.values}}</textarea>
											<input type="file" id="video_news4_{{i}}" class="form-control"
												name="video_news_file4_{{i}}"
												(change)="chechVideoExtension($event,i,4)">
											<video *ngIf="(news_id==0 || news_id==undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player4_{{i}}">
											</video>
											<video *ngIf="(news_id!=0 && news_id!=undefined)"
												style="max-width:300px;margin-top:15px" controls
												id="video_player4_{{i}}">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/mp4">
												<source
													src="{{NewsImageURL+NewsData.main_image_source+'/'+video.values}}"
													type="video/ogg">
											</video>
										</div>
										<div class="col-sm-4">
											<select class="form-control" name="video_types4[]"
												(change)="setVideoInput($event,i,4)">
												<option value="">Select Video Type</option>
												<option [selected]="video.types=='1'" value="1">Youtube</option>
												<option [selected]="video.types=='2'" value="2">Facebook</option>
												<option [selected]="video.types=='3'" value="3">Instagram</option>
												<option [selected]="video.types=='4'" value="4">Twitter</option>
												<option [selected]="video.types=='5'" value="5">Breaking News</option>
											</select>
										</div>
										<div class="col-sm-2">
											<button type="button" class="btn btn-success" *ngIf="i==0"
												(click)=add_video(4)>+</button>
											<button type="button" class="btn btn-danger" *ngIf="!i==0"
												(click)=remove_video(i,4)>-</button>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<div class="col-sm-4">
											<label for="pdf_file" class="col-form-label">PDF File: </label>
											<div class="image_checkbox" *ngIf="pdfURL">
												<input type="checkbox" name="pdf_chk"> Delete?
											</div>
										</div>
										<div class="col-sm-8">
											<input type="hidden" name="pdf_files" value="{{NewsData?.main_pdf_files}}">
											<input class="form-control" name="pdf_file" type="file"
												(change)="pdfValidation($event,1)" accept="application/pdf"
												id="pdf_file" />
											<a target="_blank" [href]="pdfURL" *ngIf="pdfURL"
												style="max-width:300px;margin-top:15px">View</a>
										</div>
									</div>
									<div class="col-md-6 row">
										<label class="col-sm-6 col-form-label">NRI News?: </label>
										<div class="col-sm-6">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" [checked]="nri_news" type="radio"
														id="customRadio44" name="nri_news" class="custom-control-input">
													<label class="custom-control-label" for="customRadio44">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input [checked]="!nri_news" value="no" type="radio"
														id="customRadio55" name="nri_news" class="custom-control-input">
													<label class="custom-control-label" for="customRadio55">No</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-group row">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Vividh Vibhag?: </label>
										<div class="col-sm-8">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="yes" type="radio" id="customRadio444"
														(change)="show_vividh_title_div(true)" name="vividh_vibhag"
														[checked]="vividh_vibhag" class="custom-control-input">
													<label class="custom-control-label" for="customRadio444">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input value="no" type="radio" id="customRadio555"
														(change)="show_vividh_title_div(false)"
														[checked]="!vividh_vibhag" name="vividh_vibhag"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio555">No</label>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6 row">
										<label class="col-sm-6 col-form-label">Repeat Days: </label>
										<div class="col-sm-6">
											<input class="form-control" type="text" name="repeat_days" value="0"
												id="repeat_days">
										</div>
									</div>
								</div>
								<div class="form-group row" *ngIf="show_vividh_title">
									<div class="col-md-6 row">
										<label class="col-sm-4 col-form-label">Vividh Vibhag Title: </label>
										<div class="col-sm-8">
											<select class="form-control" name="vividh_vibhag_page">
												<option selected value="">Select Vividh Vibhag Title</option>
												<option *ngFor="let vvtl of VividhVibhagTitleList"
													[selected]="NewsData?.main_vividh_vibhag_page == vvtl._id"
													value="{{vvtl._id}}">{{vvtl.vividh_page_guj_title}}</option>
											</select>
										</div>
									</div>
								</div>
								<input class="form-control-auto" type="hidden" name="news_date" value="{{news_date}}">
								<div class="form-group">
									<div>
										<button (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save News</button>
										<a href="javascript:void(0);" routerLink="/main-news-list" type="button"
											class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>