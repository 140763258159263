<div class="wrapper">
    <div class="container">
        <div class="brd_bx">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card m-b-30">
                        <div class="dashbox">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-md-3" *ngIf="(checkPermissionModule('Cms Page'))">
                                        <div class="menubox">
                                            <a routerLink="/cms-list">
                                                <img src="assets/images/cms.png" alt="">
                                                <p> CMS Contents</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="(checkPermissionModule('Advertise'))">
                                        <div class="menubox">
                                            <a routerLink="/advertise-list">
                                                <img src="assets/images/advertise-icon-small.png" alt="">
                                                <p> Expire Advertise</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="(checkPermissionModule('Admin'))">
                                        <div class="menubox">
                                            <a routerLink="/admin-group-list">
                                                <img src="assets/images/admin-users-group.png" alt="">
                                                <p> Admin Group</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="(checkPermissionModule('Cms Page'))">
                                        <div class="menubox">
                                            <a routerLink="/admin-user-list">
                                                <img src="assets/images/admin-users.png" alt="">
                                                <p> Admin Users</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-3" *ngIf="(checkPermissionModule('Admin'))">
                                        <div class="menubox">
                                            <a routerLink="/change-password">
                                                <img src="assets/images/change-password.png" alt="">
                                                <p> Change Password</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="menubox">
                                            <a href="javascript:void(0);" (click)="logout()">
                                                <img src="assets/images/logout.png" alt="">
                                                <p> Logout</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>