<div class="wrapper">
	<div class="container">
		<div class="brd_bx">
			<div class="row">
				<div class="col-lg-12">
					<div class="card m-b-30">
						<div class="card-body">
							<form action="" method="post" id="vividh_form" enctype="multipart/form-data">
								<div class="row">
									<div class="col-lg-12">
										<div class="card m-b-10">
											<div class="page-title-box row">
												<div class="col-md-6">
													<div class="btn-group ">
														<h4 class="page-title">Vividh Vibhag Title Add</h4>
													</div>
												</div>
												<div class="col-md-6">
													<div class="btn-group pull-right">
														<div>
															<button type="submit" (click)="submit_form()"
																class="btn btn-success waves-effect waves-light m-r-5">Save
																News</button>
															<a href="javascript:void(0);"
																routerLink="/vividh-vibhag-title-list" type="button"
																class="btn btn-secondary waves-effect m-l-5">Cancel</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class=" row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Gujarati Title: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="guj_title" value="{{NewsData?.vividh_page_guj_title}}"
												id="guj_title">
										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Author Name: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required" type="text"
												name="author_name" value="{{NewsData?.vividh_page_author_name}}"
												id="author_name">
										</div>
									</div>

								</div>



								<div class="row">
									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Page Url: </label>
										<div class="col-sm-8">
											<input class="form-control" type="text" name="page_url"
												value="{{NewsData?.vividh_page_url}}" id="page_url">
										</div>
									</div>

									<div class="form-group col-md-6 row">
										<label class="col-sm-4 col-form-label">Display Order: </label>
										<div class="col-sm-8">
											<input class="form-control" data-validation="required number" type="text"
												name="display_order" value="{{NewsData?.vividh_page_display_order}}"
												id="display_order">
										</div>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-md-6 row">
										<label for="image1" class="col-sm-4 col-form-label">Upload: </label>
										<div class="col-sm-8">
											<input type="hidden" name="heading_image"
												value="{{NewsData?.vividh_page_image}}">
											<input id="image1" type="file" class="form-control" name="file_upload1"
												type="file" accept='image/*' (change)="showImagePreview($event,1)">
											<img [src]="imageURL1" *ngIf="imageURL1"
												style="max-width:300px;margin-top:15px">

										</div>
									</div>
									<div class="form-group col-md-6 row">
										<label class="col-sm-6 col-form-label">Display In Dropdown?: </label>
										<div class="col-sm-6">
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input [checked]="NewsData?.vividh_page_display_in_dd == 'yes'"
														value="yes" type="radio" id="customRadio444" name="active"
														class="custom-control-input">
													<label class="custom-control-label" for="customRadio444">Yes</label>
												</div>
											</div>
											<div class="form-check-inline my-1">
												<div class="custom-control custom-radio">
													<input [checked]="NewsData?.vividh_page_display_in_dd == 'no'"
														value="no" type="radio" id="customRadio555" checked
														name="active" class="custom-control-input">
													<label class="custom-control-label" for="customRadio555">No</label>
												</div>
											</div>
										</div>
									</div>
								</div>


								<input type="hidden" name="newsID" value="{{NewsData?._id}}">
								<div class="form-group">
									<div>
										<button type="submit" (click)="submit_form()"
											class="btn btn-success waves-effect waves-light m-r-5">Save News</button>
										<a href="javascript:void(0);" routerLink="/vividh-vibhag-title-list"
											type="button" class="btn btn-secondary waves-effect m-l-5">Cancel</a>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>