import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShubhDivasRoutingModule } from './shubh-divas-routing.module';
import { ShubhDivasAddComponent } from './shubh-divas-add/shubh-divas-add.component';
import { ShubhDivasListComponent } from './shubh-divas-list/shubh-divas-list.component';
import { CKEditorModule } from 'ckeditor4-angular';
@NgModule({
  declarations: [ShubhDivasAddComponent, ShubhDivasListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    ShubhDivasRoutingModule
  ]
})
export class ShubhDivasModule { }
