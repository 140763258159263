import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './../layout/default/default.component';
import { PhotoGalleryAddComponent } from './photo-gallery-add/photo-gallery-add.component';
import { PhotoGalleryListComponent } from './photo-gallery-list/photo-gallery-list.component';
const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  children: [{ path: 'photo-gallery-add', component: PhotoGalleryAddComponent },
  { path: 'photo-gallery-edit/:news_id', component: PhotoGalleryAddComponent },
  { path: 'photo-gallery-list', component: PhotoGalleryListComponent }
  ]
}];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PhotoGalleryRoutingModule { }
