// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var url = "https://api.akilanews.com/";

//var url = "http://192.168.1.212:3000/";
export const environment = {
  production: false,
  AppUrl: 'https://manage.akilanews.com/#/',
  FrontWebUrl: 'https://akilanews.com/',
  AppDownloadLink: 'https://app.akilanews.com',
  whatsAppChannel: 'https://whatsapp.com/channel/0029VaAWB2B0wajsMIQZhn2n',
  baseUrl: url,
  FrontApiBaseUrl: url + 'front/',
  AdminApiBaseUrl: url + 'admin/',
  AppApiBaseUrl: url + 'app/',
  GeneralBaseUrl: url + 'general/',
  GujaratBaseUrl: url + 'gujarat/',
  InternationalBaseUrl: url + 'international/',
  KasumboBaseUrl: url + 'kasumbo/',
  MainBaseUrl: url + 'main/',
  MarriageBaseUrl: url + 'marriage/',
  NoticeBaseUrl: url + 'notice/',
  NriBaseUrl: url + 'nri/',
  ObituaryBaseUrl: url + 'obituary/',
  OpinionBaseUrl: url + 'opinion/',
  PhotoGalleryBaseUrl: url + 'photogallery/',
  RajkotBaseUrl: url + 'rajkot/',
  SaurashtraBaseUrl: url + 'saurashtra/',
  ShortBaseUrl: url + 'short/',
  SportsBaseUrl: url + 'sports/',
  TantriBaseUrl: url + 'tantri/',
  VividhBaseUrl: url + 'vividh/',
  AajNaDivseBaseUrl: url + 'aajnadivse/',
  AdvertiseBaseUrl: url + 'advertise/',
  BirthdayBaseUrl: url + 'birthday/',
  BollywoodBaseUrl: url + 'bollywood/',
  CmsBaseUrl: url + 'cms/',
  EpaperBaseUrl: url + 'epaper/',
  DynamicSectionBaseUrl: url + 'dynamicsection/',
  FatafatBaseUrl: url + 'fatafat/',
  FestivalPhotoBaseUrl: url + 'festivalphoto/',
  // baseUrl: 'http://api.akilanews.com/',
  // AppUrl: 'http://manage.akilanews.com/#/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
