import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  key: any = 'q4t7w!z%C*F-JaNdRgUkXp2r5u8x/A?D';
  iv: any = 'u7x!A%D*G-KaNdRg';
  constructor() { }
  convert_parameter(param) {
    var dataObj = {};
    $.each(param, function (idx, obj) {
      dataObj[idx] = obj;
    });
    return this.encryptUsingAES256(JSON.stringify(dataObj), this.key, this.iv)
  }
  convert_form_serialize_array(form) {
    var dataObj = {};
    $(form).each(function (i, field) {
      dataObj[field.name] = field.value;
    });
    // console.log(dataObj)
    return this.encryptUsingAES256(JSON.stringify(dataObj), this.key, this.iv)
  }
  convert_form_data(form) {
    var dataObj = {};
    for (var pair of form.entries()) {
      dataObj[pair[0]] = pair[1];
    }
    // console.log(dataObj)
    return this.encryptUsingAES256(JSON.stringify(dataObj), this.key, this.iv)
  }
  encrypt_data(data) {
    return this.encryptUsingAES256(data, this.key, this.iv)
  }
  decrypt_data(data) {
    return this.decryptUsingAES256(data, this.key, this.iv)
  }
  convert_form_image(form) {
    var enc = this.encryptUsingAES256(form, this.key, this.iv)
    var dec = this.decryptUsingAES256(enc, this.key, this.iv)
    console.log(enc)
    console.log(dec)
  }
  encryptUsingAES256(password, key, iv) {
    let _key = CryptoJS.enc.Utf8.parse(key);
    let _iv = CryptoJS.enc.Utf8.parse(iv);
    try {
      let encrypted = CryptoJS.AES.encrypt(
        password, _key, {
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      return encrypted;
    } catch (error) {
      return false;
    }
  }
  decryptUsingAES256(password, key, iv) {
    let _key = CryptoJS.enc.Utf8.parse(key);
    let _iv = CryptoJS.enc.Utf8.parse(iv);
    try {
      let decrypted = CryptoJS.AES.decrypt(
        password, _key, {
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (error) {
      return false;
    }
  }
}
